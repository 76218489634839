/* eslint-disable react/display-name */
import React from 'react';
import { MdOutlineFolderCopy } from 'react-icons/md';

import CreateReplicatedEnvironment from '~/pages/CreateReplicatedEnvironment';
import CreateReplicatedCourse from '~/pages/CreateReplicatedCourse';
import CreateReplicatedSpace from '~/pages/CreateReplicatedSpace';
import CreateReplicatedSubject from '~/pages/CreateReplicatedSubject';

import Description from '../Description';
import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import FilterNoneIcon from '@material-ui/icons/FilterNone';

export default function StructureSelection({
  texts,
  structure,
  type,
  setFormConfig,
  setSubmission
}) {
  const classes = useStyles();

  const isMirroring = Boolean(type === 'mirroring');
  const componentMapping = {
    environment: CreateReplicatedEnvironment,
    course: CreateReplicatedCourse,
    space: CreateReplicatedSpace,
    subject: CreateReplicatedSubject
  };
  const ComponentToRender = componentMapping[structure.name];

  return (
    <Box className={classes.box}>
      <Box style={{ marginBottom: 15 }}>
        <Description
          label={isMirroring ? 'Espelhamento' : 'Duplicação'}
          text={
            isMirroring
              ? texts.mirroring.description
              : texts.duplication.description
          }
          Icon={isMirroring ? MdOutlineFolderCopy : FilterNoneIcon}
          isStructureComponent
        />
      </Box>
      <ComponentToRender
        isMirroring={isMirroring}
        texts={texts}
        setFormConfig={setFormConfig}
        setSubmission={setSubmission}
      />
    </Box>
  );
}
