import React from 'react';

import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100vh',
    overflowX: 'hidden',
    paddingTop: '76px',
    paddingBottom: '8px',
    '& ::-webkit-scrollbar': {
      width: '5px',
      height: '5px'
    },
    '& ::-webkit-scrollbar-track': {
      background: 'none'
    },
    '& ::-webkit-scrollbar-thumb': {
      background: (props) => `${props.theme.palette.secondary.main}`,
      bordeRadius: '2px'
    }
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    // minHeight: '100vh',
    width: '100vw',
    overflowY: 'auto',
    overflowX: 'hidden',
    '@media (max-width: 800px)': {}
  },
  pageTitle: {
    overflow: 'hidden',
    width: '100%',
    maxWidth: '100%',
    '& p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%'
    }
  },
  customTooltip: {
    fontSize: '14px !important',
    width: '100% !important',
    lineHeight: '24px !important',
    maxWidth: '700px !important',
    backgroundColor: '#676D82 !important'
  },
  mainArea: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    '@media (max-width: 1400px)': {
      width: '76%'
    },
    '@media (max-width: 1024px)': {
      width: '75%',
      padding: '36px 0 90px 0'
    },
    '@media (max-width: 800px)': {
      paddingBottom: 0
    }
  },
  webMuralArea: {
    paddingTop: 30,
    paddingBottom: 60,
    position: 'relative',
    width: '21.3%',
    borderLeft: (props) =>
      `1px solid ${props.theme.palette.textActiveElement.main}`,
    borderRight: (props) =>
      `1px solid ${props.theme.palette.textActiveElement.main}`,
    '@media (max-width: 1400px)': {
      width: '17.3%'
    },
    '@media (max-width: 1024px)': {
      width: '23.3%',
      paddingBottom: 90
    },
    '@media (max-width: 800px)': {
      display: 'none'
    }
  },
  materialsDrawer: {
    height: 90
  },
  materialsToolbar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  materialsButton: {
    marginTop: 5,
    width: 30,
    height: 30,
    minWidth: 30
  },
  materialsIcon: {
    fontSize: 30,
    color: (props) => props.theme.palette.textActiveElement.main,
    opacity: 0.5
  },
  materialsTitle: {
    paddingTop: 15,
    paddingBottom: 20
  },
  lectureDataContainer: {
    padding: '20px 15px 0px 15px',
    flexGrow: 1,
    '@media (max-width: 1024px)': {
      padding: '20px 30px 0px 30px'
    },
    '@media (max-width: 800px)': {
      padding: '20px 10px 0px 10px'
    }
  },
  tabletNextLectures: {
    display: 'none',
    '@media (max-width: 1024px)': {
      display: 'block'
    }
  },
  infos: {
    display: 'flex',
    paddingTop: '40px',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    padding: '0 64px',
    '@media (max-width: 1024px)': {
      padding: '0 30px'
    },
    '@media (max-width: 800px)': {
      padding: '0 10px'
    }
  },
  mobileMuralArea: {
    display: 'none',
    '@media (max-width: 800px)': {
      display: 'block',
      marginTop: 30
    }
  },
  accordionNext: {
    padding: 15,
    borderBottom: '1px solid #F2F2F6',
    boxShadow: 'none',
    '&:hover': {
      background: '#F2F2F61A'
    },
    marginTop: 20
  }
});

export const NavigationButtonsShimmer = () => (
  <div
    style={{
      display: 'flex',
      columnGap: 15,
      padding: '15px 0 15px 0',
      '@media (max-width: 767px)': {
        width: '100%',
        justifyContent: 'center'
      }
    }}
  >
    <Skeleton
      style={{ padding: '20px 15px 20px 15px' }}
      variant="rect"
      width={150}
      height={32}
    />
    <Skeleton
      style={{ padding: '20px 15px 20px 15px' }}
      variant="rect"
      width={150}
      height={32}
    />
  </div>
);
