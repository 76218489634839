import React from 'react';

import LecturesReport from './LecturesReport';
import PerformanceReport from './PerformanceReport';
import ReportCollapsable from './ReportCollapsable';
import SimplifiedSubjectsReport from './SimplifiedSubjectsReport';
import InformationListReport from './InformationListReport';
import UsersRolesReport from './UsersRolesReport';
import { useStyles } from './styles';
import UsersReport from './UsersReport';
import PropTypes from 'prop-types';

export default function EnvironmentReports({ id }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ReportCollapsable
        title="Relatório simplificado de conclusão de módulos"
        report={SimplifiedSubjectsReport}
        id={id}
      />
      <ReportCollapsable
        title="Relatório de aulas por módulo"
        report={LecturesReport}
        id={id}
      />
      <ReportCollapsable
        title="Relatório de desempenho"
        report={PerformanceReport}
        id={id}
      />
      <ReportCollapsable
        title="Lista de usuários do ambiente"
        report={UsersReport}
        id={id}
      />
      <ReportCollapsable
        title="Lista de informações das estruturas no ambiente"
        report={InformationListReport}
        id={id}
      />
      <ReportCollapsable
        title="Lista de usuários e seus papéis nos cursos e disciplinas"
        report={UsersRolesReport}
        id={id}
      />
    </div>
  );
}

EnvironmentReports.propTypes = {
  id: PropTypes.string
};

EnvironmentReports.defaultProps = {
  id: ''
};
