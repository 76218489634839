import api from '~/services/api';

export const getMirrorOptions = async (params = {}, page = 1) => {
  const { courseID, spaceID, environmentID, subjectID } = params;
  let url = `/api/mirroring/lectures/mirror_options?page=${page}`;
  if (environmentID) {
    url += `&environment_id=${environmentID}`;
  } else if (courseID) {
    url += `&course_id=${courseID}`;
  } else if (spaceID) {
    url += `&space_id=${spaceID}`;
  } else if (subjectID) {
    url += `&subject_id=${subjectID}`;
  }
  const { data } = await api.get(url);
  if (data.length == 0) return null;
  return data;
};