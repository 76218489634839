import { makeStyles } from '@material-ui/core';

const rowHeight = '55px';
const numRows = 10;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    maxHeight: `calc(${rowHeight} * ${numRows})`,
    overflowY: 'auto'
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '10% 60% 30%',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '10% 45% 45%'
    },
    height: rowHeight,
    alignItems: 'center',
    justifyItems: 'center',
    backgroundColor: '#F5F5F5'
  },
  titleRow: {
    zIndex: 1,
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px'
  },
  rowText: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'black',
    fontSize: '20px',
    textAlign: 'center'
  }
}));
