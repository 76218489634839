import React from 'react';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';
import i18n from '~/I18n';

import { useStyles } from './styles';
import { Box, Dialog, useTheme, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function ConfirmModal({
  show,
  text,
  handleConfirm,
  handleClose,
  maxWidth,
  loading,
  showButtons
}) {
  const theme = useTheme();
  const classes = useStyles({ maxWidth });

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      PaperProps={{
        style: {
          boxShadow: 'none'
        }
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.1)'
        }
      }}
    >
      <Box className={classes.root}>
        <Text variant="lg" color="primary">
          {text}
        </Text>
        {loading ? (
          <Box className={classes.buttonArea}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          showButtons && (
            <Box className={classes.buttonArea}>
              <ButtonSquare
                border={`1px solid ${theme.palette.primary.main}`}
                width={100}
                height={50}
                onClick={handleClose}
                type="button"
              >
                <Text variant="largeText" color="primary">
                  {i18n.t('Cancel')}
                </Text>
              </ButtonSquare>
              <ButtonSquare
                backgroundColor={theme.palette.secondary.main}
                width={100}
                height={50}
                onClick={handleConfirm}
                marginLeft={25}
                type="button"
              >
                <Text variant="largeText" color="white">
                  {i18n.t('Confirm')}
                </Text>
              </ButtonSquare>
            </Box>
          )
        )}
      </Box>
    </Dialog>
  );
}

ConfirmModal.propTypes = {
  show: PropTypes.bool,
  text: PropTypes.string,
  handleConfirm: PropTypes.func,
  handleClose: PropTypes.func,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool,
  showButtons: PropTypes.bool
};

ConfirmModal.defaultProps = {
  show: false,
  text: '',
  handleConfirm: null,
  handleClose: null,
  maxWidth: '100%',
  loading: false,
  showButtons: true
};
