import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  lectureContainer: {
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    justifyContent: 'space-beetwen',

    marginBottom: 12,
    borderRadius: 5,
    height: 120,
    width: '100%',
    position: 'relative',

    backgroundColor: theme.palette.primary.main
  },
  emptyLectures: {
    padding: '30px 0'
  },
  shimmerItem: {
    width: '100%',
    height: '8vw',
    marginBottom: 24,
    borderRadius: 5,
    '@media (max-width: 1024px)': {
      height: '10vw'
    },
    '@media (max-width: 800px)': {
      height: '22vw'
    }
  },

  active: {
    backgroundColor: theme.palette.accent.main
  },
  inactive: {
    backgroundColor: '#B4BDD3'
  },
  helperArea: {
    width: '18%',
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '30px 0',
    boxShadow: '0px 3px 6px #00000029',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxHeight: '380px',
      marginBottom: '16px'
    }
  },
  accordion: {
    padding: 15,
    borderBottom: '1px solid #F2F2F6',
    boxShadow: 'none',
    '&:hover': {
      background: '#F2F2F61A'
    }
  },
  listStyle: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gap: '8px',
      gridTemplateColumns: 'repeat(2, 1fr)'
    }
  }
}));
