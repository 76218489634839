/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Text from '~/components/atoms/Text';
import DocumentMiniCard from '~/components/molecules/LectureTypesMiniCard/DocumentMiniCard';
import ExerciseMiniCard from '~/components/molecules/LectureTypesMiniCard/ExerciseMiniCard';
import LiveStreamMiniCard from '~/components/molecules/LectureTypesMiniCard/LiveStreamMiniCard';
import MultimediaMiniCard from '~/components/molecules/LectureTypesMiniCard/MultimediaMiniCard';
import QuestionaryMiniCard from '~/components/molecules/LectureTypesMiniCard/QuestionaryMiniCard';
import VideoCallMiniCard from '~/components/molecules/LectureTypesMiniCard/VideoCallMiniCard';
import VideoMiniCard from '~/components/molecules/LectureTypesMiniCard/VideoMiniCard';
import YoutubeMiniCard from '~/components/molecules/LectureTypesMiniCard/YoutubeMiniCard';
import PopUpMenu from '~/components/molecules/PopUpMenu';
import ShareContent from '~/components/organisms/ShareContents/ShareContentLectures';
import i18n from '~/I18n';
import EditLectureModal from '~/pages/Space/Content/Lecture/EditLectureModal';

import { deleteLecture } from './fetch';
import { useStyles } from './styles';
import { Grid, Modal } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ShareIcon from '@material-ui/icons/Share';

export default function Lecture({ index, id, name, type, ...rest }) {
  const classes = useStyles();
  const { setValue, watch } = useFormContext();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const linkList = [
    {
      action: (e) => {
        e.preventDefault();
        handleDelete();
      },
      Icon: DeleteIcon,
      text: 'Excluir',
      disabled: false
    },
    {
      action: (e) => {
        e.preventDefault();
        setOpenEdit(true);
      },
      Icon: CreateIcon,
      text: 'Editar',
      disabled: false
    },
    {
      action: (e) => {
        e.preventDefault();
        setOpen(true);
      },
      Icon: ShareIcon,
      text: 'Compartilhar',
      disabled: false
    }
  ];

  const updateLectures = (updatedLecture) => {
    setValue(
      'lectures',
      watch('lectures').map((e) =>
        e.id !== updatedLecture.id ? e : { ...e, name: updatedLecture.name }
      )
    );
  };

  const handleSuccess = (id) => {
    setValue(
      'lectures',
      watch('lectures').filter((elem) => elem.id != id)
    );
  };

  const handleDelete = () => {
    var result = window.confirm(
      `Tem certeza que deseja excluir a ${i18n.t(
        'StructuresName.Lectures.SingularLower'
      )} "${index + 1}. ${name}"`
    );
    if (result)
      deleteLecture(id)
        .then(handleSuccess(id))
        .catch(() =>
          window.alert(
            `Não foi possível excluir a ${i18n.t(
              'StructuresName.Lectures.SingularLower'
            )}`
          )
        );
  };

  const setSeminarMiniCard = () => {
    if (rest.resource_type) {
      switch (rest.resource_type) {
        case 'call':
          return VideoCallMiniCard;
        case 'google_meet':
          return VideoCallMiniCard;
        case 'upload':
          return VideoMiniCard;
        case 'youtube':
          return YoutubeMiniCard;
        case 'youtube_livestream':
          return LiveStreamMiniCard;
        case 'zoom':
          return VideoCallMiniCard;
        default:
          return null;
      }
    }
  };

  const setLectureMiniCard = () => {
    if (type) {
      switch (type) {
        case 'Page':
          return MultimediaMiniCard;
        case 'Document':
          return DocumentMiniCard;
        case 'Exercise':
          return ExerciseMiniCard;
        case 'Form':
          return QuestionaryMiniCard;
        case 'Seminar':
          return setSeminarMiniCard();
        default:
          return null;
      }
    }
  };

  const renderMiniCard = (component) => {
    const Component = component;
    return component && <Component />;
  };

  return (
    <Grid
      container
      className={classes.root}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <Grid container item spacing={2} alignItems="center">
          <Grid item>
            <Text color="black" className={classes.textField}>
              {`${index + 1}. `}
            </Text>
          </Grid>

          <Grid item>
            <Text color="black" className={classes.textField}>
              {name}
            </Text>
          </Grid>

          <Grid item>{renderMiniCard(setLectureMiniCard())}</Grid>
        </Grid>
      </Grid>

      <Grid item>
        <PopUpMenu Icon={MoreHorizIcon} linkList={linkList} />
      </Grid>

      <Modal open={open && id} onClose={handleClose} className={classes.modal}>
        <div>
          <ShareContent lectureID={id} />
        </div>
      </Modal>

      {openEdit && id && (
        <EditLectureModal
          handleClose={() => setOpenEdit(false)}
          open={openEdit}
          lectureID={id}
          updateLectures={updateLectures}
        />
      )}
    </Grid>
  );
}
