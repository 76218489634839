/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ReplicatedCourseForm from '~/components/organisms/ReplicatedCourseForm';
import { useCustomSnackbar } from '~/contexts/customSnackBar';
import {
  createMirroredCourse,
  createClonedCourse
} from '~/services/replications/courses';
import { handleErrors } from '~/utils/auxiliaryFunctions';
import { replicatedCourseValidations } from '~/utils/CourseValidation/replicatedCourse';

import { useSnackbar } from 'notistack';

export default function CreateReplicatedCourse({
  setFormConfig,
  isMirroring,
  setSubmission,
  texts
}) {
  const { id: environmentID } = useParams();
  const history = useHistory();
  const [, setSubmissionStatus] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { showSnackbar } = useCustomSnackbar();

  const defaultValues = {
    courseID: ''
  };

  useEffect(() => {
    setFormConfig(defaultValues, replicatedCourseValidations);
    setSubmission(() => submitForm);
  }, []);

  const finalizeSuccess = (response) => {
    const createdCourseID = isMirroring
      ? response.data.parent.id
      : response.data.id;

    setSubmissionStatus('success');
    showSnackbar(
      `${isMirroring ? texts.mirroring.success : texts.duplication.success}!`,
      {
        buttonText: 'Ver andamento',
        buttonAction: () =>
          history.push(
            `/dashboard/environments/${environmentID}/courses/${createdCourseID}`
          )
      }
    );
  };

  const finalizeFailure = (error) => {
    handleErrors(error, enqueueSnackbar);
    setSubmissionStatus('error');
  };

  function submitForm(fields) {
    const createReplicatedCourse = isMirroring
      ? createMirroredCourse
      : createClonedCourse;

    let body = {};

    if (isMirroring) {
      body = {
        mirrored_course: { original_id: fields.courseID }
      };
    } else {
      body = fields.courseID;
    }

    createReplicatedCourse(environmentID, body)
      .then((response) => finalizeSuccess(response))
      .catch(finalizeFailure);
  }

  return <ReplicatedCourseForm isMirroring={isMirroring} />;
}
