import React, { useEffect, useState, useCallback } from 'react';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import AvatarItem from '~/components/atoms/Avatar';
import LeftDrawer from '~/components/Badges/Drawer/LeftDrawer';
import RightDrawer from '~/components/Badges/Drawer/RightDrawer';
import AppBar from '~/components/molecules/AppBar';
import HeaderSideBar from '~/components/molecules/HeaderSideBar';
import MainMenu from '~/components/molecules/MainMenu';
import ToolbarLeft from '~/components/molecules/Toolbar';
import PrivacyTermsModal from '~/components/organisms/PrivacyTerms';
import SideNavItem from '~/components/organisms/SideNavItem';
import Tour from '~/components/organisms/Tour';
import SideNav from '~/components/templates/SideNav';
import { sideBarAvatarSize } from '~/constants/styles';
import i18n from '~/I18n';
import DashboardRoutes from '~/routes/dashboard-routes';
import { socket } from '~/socket';
import { Creators as UserActions } from '~/store/ducks/user';

import SebraeError from './SebraeError';
import { Container, Logo, useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

export default function Dashboard() {
  const theme = useTheme();
  const { remember } = useSelector((state) => state.auth);
  const { getProfileRequest, getMyContactsRequest } = UserActions;
  const { profile, mycontacts } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const classes = useStyles();

  const contacts = !profile ? Array(10).fill(null) : mycontacts;

  const [open, setOpen] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  function removePersist() {
    if (!remember) {
      localStorage.removeItem('persist:redu');
    }
  }

  const fetchContacts = useCallback(() => {
    if (profile && !contacts) {
      dispatch(getMyContactsRequest(profile?.id));
    }
  }, [dispatch, getMyContactsRequest, profile]);

  useEffect(() => {
    function dispatchContact() {
      fetchContacts();
    }
    dispatchContact();
  }, [fetchContacts]);

  useEffect(() => {
    if (profile?.privacy_and_tos === false) {
      setShowTerms(true);
    }
  }, [profile?.privacy_and_tos]);

  useEffect(() => {
    document.title = `${theme.title} | Início`;
    socket.connect();
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    dispatch(getProfileRequest());
    window.addEventListener('beforeunload', () => {
      removePersist();
    });
  }, [dispatch, getProfileRequest]);

  function toggleDrawer() {
    setOpen(!open);
  }

  return (
    <>
      {!profile ? (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            alignItems: 'center'
          }}
        >
          <ReactLoading type="bars" color={theme.palette.primary.main} />
        </Box>
      ) : (
        <ThemeProvider theme={theme}>
          {showTerms === true ? (
            <PrivacyTermsModal profileID={profile?.id} />
          ) : null}
          <Container className={classes.Container}>
            <Tour />
            <LeftDrawer open={open} setOpen={toggleDrawer} />
            <Box className={classes.appBarContainer}>
              <AppBar profile={profile} setOpen={toggleDrawer} />
            </Box>

            <SideNav color={theme.palette.primary.main}>
              <ToolbarLeft setOpen={toggleDrawer} />
              <HeaderSideBar profile={profile ? profile : null} />
              <MainMenu />
              <Box className={classes.sideNavItem}>
                <SideNavItem
                  title={i18n.t('MyContacts')}
                  buttonTitle={i18n.t('ShowAll')}
                  targetRoute={{
                    pathname: profile ? `/profile/${profile.id}` : '#',
                    state: {
                      targetSection: 'contacts'
                    }
                  }}
                >
                  <Box
                    style={{
                      display: 'grid',
                      gridTemplateColumns: `repeat(5,${sideBarAvatarSize +
                        1}px)`,
                      justifyContent: 'space-between',
                      rowGap: 12
                    }}
                  >
                    {contacts &&
                      contacts.slice(0, 10).map((item, index) => (
                        <Box key={index} item={item}>
                          <AvatarItem size={sideBarAvatarSize} profile={item} />
                        </Box>
                      ))}
                  </Box>
                </SideNavItem>
              </Box>
              <Box className={classes.logoContainer}>
                <Link to="/dashboard">
                  <Logo src={theme.logo} />
                </Link>
              </Box>
            </SideNav>

            <DashboardRoutes />

            <Box className={classes.rightDrawer}>
              <RightDrawer />
            </Box>

            <SebraeError />
          </Container>
        </ThemeProvider>
      )}
    </>
  );
}
