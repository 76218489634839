import React from 'react';
import { Link } from 'react-router-dom';

import settings from '~settings';

import { Button, Avatar } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import styled from 'styled-components';

const backgroundSkeleton = 'rgba(87, 154, 255, 0.43)';

export const ShimmerCircle = styled(({ color, size, ...otherProps }) => (
  <Skeleton
    {...otherProps}
    variant="circle"
    width={size}
    height={size}
    animation="pulse"
    style={{ backgroundColor: backgroundSkeleton, minWidth: size }}
  />
))``;

export const CustomAvatar = styled(({ size, profile, ...otherProps }) => {
  return (
    <Avatar
      {...otherProps}
      style={{ width: size, height: size }}
      alt={profile ? profile.first_name : ''}
      src={
        profile && profile.thumbnails && profile.thumbnails.length > 5
          ? `${settings.baseUrl}${profile.thumbnails[5].href}`
          : ''
      }
    />
  );
})``;

export const Container = styled(({ color, profile, size, ...otherProps }) => (
  <Link to={profile && profile.id ? `/profile/${profile.id}` : '#'}>
    <Button
      {...otherProps}
      display="flex"
      // flexDirection="column"
      // justifyContent="center"
      // alignItems="center"
      style={{
        minWidth: size,
        width: size,
        height: size,
        borderRadius: size / 2
      }}
    />
  </Link>
))``;
