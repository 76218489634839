import {
  hasCustomAccessTo,
  hasManageAccessTo,
  hasReadAccessTo
} from './accessControl';

const MIRRORED_SPACE_URL = 'mirrored_spaces/';

export const hasManageAccessToMirroredSpace = async (mirroredSpaceID) =>
  await hasManageAccessTo(`${MIRRORED_SPACE_URL}${mirroredSpaceID}`);
export const hasReadAccessToMirroredSpace = async (mirroredSpaceID) =>
  await hasReadAccessTo(`${MIRRORED_SPACE_URL}${mirroredSpaceID}`);
export const hasUpdateAccessToMirroredSpace = async (mirroredSpaceID) =>
  await hasCustomAccessTo(`${MIRRORED_SPACE_URL}${mirroredSpaceID}`, 'update');
