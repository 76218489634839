import { makeStyles } from '@material-ui/core';

const itemWidth = '100%';
const itemHeight = '8vw';
const tabletHeight = '10vw';
const mobileHeight = '22vw';

export const useStyles = makeStyles({
  lectureContainer: {
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    justifyContent: 'space-beetwen',
    // width: itemWidth,
    // height: itemHeight,
    marginBottom: 12,
    borderRadius: 5,
    height: 120,
    width: '100%',
    position: 'relative',
    // '@media (max-width: 1024px)': {
    //   height: tabletHeight,
    //   marginBottom: 0
    // },
    // '@media (max-width: 800px)': {
    //   height: mobileHeight
    // },
    backgroundColor: (props) => props.theme.palette.primary.main
  },
  iconContainer: {
    // width: itemWidth,
    // height: itemHeight,
    borderRadius: 5,
    display: 'flex',
    alignSelf: 'center',
    zIndex: 1
    // '@media (max-width: 1024px)': {
    //   height: tabletHeight
    // },
    // '@media (max-width: 800px)': {
    //   height: mobileHeight
    // }
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    flex: 1
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'space-between',
    width: '100%'
  },
  lectureInfo: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    bottom: itemHeight,
    width: itemWidth,
    height: itemHeight,
    zIndex: 10,
    '@media (max-width: 1024px)': {
      height: tabletHeight,
      bottom: tabletHeight
    },
    '@media (max-width: 800px)': {
      height: mobileHeight,
      bottom: mobileHeight
    }
  },
  iconsContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    top: 10,
    width: `calc(${itemWidth} - 20px)`
  },
  seenIcon: {
    position: 'absolute',
    width: 20,
    height: 20,
    '& svg': {
      color: 'white',
      fontSize: 20
    },
    left: 5,
    top: 5
  },
  lockIcon: {
    color: '#676D82',
    fontSize: 20
  },
  bottomRow: {
    position: 'relative',
    top: `calc(${itemHeight} - 50px)`,
    width: '100%',
    display: 'flex',
    padding: '0 5px',
    '@media (max-width: 1300px)': {
      top: `calc(${itemHeight} - 40px)`
    },
    '@media (max-width: 1024px)': {
      top: `calc(${tabletHeight} - 40px)`
    },
    '@media (max-width: 800px)': {
      top: `calc(${mobileHeight} - 40px)`
    }
  },
  titleContainer: {
    flex: 1,
    overflow: 'hidden',
    '& p': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    }
  },
  lgTitle: {
    '@media (max-width: 1300px)': {
      display: 'none'
    }
  },
  smTitle: {
    display: 'none',
    '@media (max-width: 1300px)': {
      display: 'block'
    }
  },
  hide: {
    '@media (max-width: 800px)': {
      display: 'none !important'
    }
  },
  duration: {
    padding: '0 5px',
    borderRadius: 2,
    backgroundColor: '#70707074',
    position: 'relative',
    marginLeft: 10,
    display: 'flex',
    alignItems: 'center',
    height: 22
    // '@media (max-width: 1300px)': {
    //   height: 20
    // }
  },
  active: {
    backgroundColor: (props) => props.theme.palette.accent.main
  }
});
