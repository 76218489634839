import React from 'react';

import { getUsersReport } from '~/services/reports/environments';

import DownloadReportButton from './../DownloadReportButton';
import { useStyles } from './styles';
import PropTypes from 'prop-types';

export default function UserReport({ id }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DownloadReportButton
        getReport={getUsersReport}
        baseName="lista_de_usuarios_ambiente_"
        id={id}
      />
    </div>
  );
}

UserReport.propTypes = {
  id: PropTypes.string
};

UserReport.defaultProps = {
  id: ''
};
