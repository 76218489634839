import React from 'react';
import { Link } from 'react-router-dom';

import settings from '~settings';

import {
  AvatarCustom,
  Container,
  ContainerInfoContact,
  ContactName,
  ContactSocialNetwork
} from './styles';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';

export default function MyProfileContacts({ item }) {
  const { contact } = item || {};
  const {
    first_name: firstName,
    last_name: lastName,
    login,
    thumbnails,
    id: contactId
  } = contact || {};
  const theme = useTheme();

  return (
    <Grid item xs={12} sm={6}>
      <Container>
        <AvatarCustom
          size={50}
          id={contactId}
          source={
            thumbnails && thumbnails.length > 5
              ? `${settings.baseUrl}${thumbnails[5].href}`
              : ''
          }
        />
        <ContainerInfoContact>
          <Link
            style={{ width: '100%', overflow: 'hidden' }}
            to={`/profile/${contactId}`}
          >
            <ContactName color={theme.palette.accent.main}>
              {`${firstName} ${lastName}`}
            </ContactName>
          </Link>
          <ContactSocialNetwork color={theme.palette.textActiveElement.main}>
            {login ? `@${login}` : '-'}
          </ContactSocialNetwork>
        </ContainerInfoContact>
      </Container>
    </Grid>
  );
}
