import api from '~/services/api';
import { readFile } from '~/utils/imageUtils';

export const createEnvironment = async (fields) => {
  delete fields.enable_zoom;
  delete fields.enable_youtube;
  delete fields.enable_pagseguro;

  fields.tag_list = formatTagList(fields.keywords);
  delete fields.keywords;

  if (fields.new_avatar_attributes) {
    fields.new_avatar_attributes = await handleAvatar(
      fields.new_avatar_attributes
    );
  } else {
    delete fields.new_avatar_attributes;
  }

  const body = { environment: { ...fields, published: true } };

  return api.post(`/api/environments`, body);
};

const formatTagList = (keywords) => {
  if (keywords.length > 0) {
    return keywords.map((key) => key.name).join(',');
  }

  return null;
};

const handleAvatar = async (file) => {
  if (file) {
    var dataUrl = await readFile(file);
    return { image: dataUrl, image_file_name: file.name };
  }

  return null;
};
