import React from 'react';

import PropTypes from 'prop-types';

import { useTheme, Box, Button } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import EditIcon from '@material-ui/icons/Create';
import Text from '~/components/atoms/Text';
import EditUsernameModal from '~/components/molecules/EditUsernameModal';

import { useStyles } from './styles';

export default function EditUsername({
  profile,
  submitUsername,
  showUsernameModal,
  setShowUsernameModal,
  handleShowModal,
  errors,
  clearErrors
}) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <Box style={{width: '100%'}}>
        {profile ? 
          <Box>
            <Box className={classes.usernameArea}>
              <Box className={classes.nameEditorBox}>
                <Text variant='lg' color='primary'>{profile?.first_name + " " + profile?.last_name}
                  <Button className={classes.editButton} onClick={handleShowModal}>
                    <EditIcon className={classes.editIcon} />
                  </Button>
                </Text>
              </Box>
              <Text variant='text' color='gray'>{`@${profile?.login}`}</Text>
              {showUsernameModal && <EditUsernameModal 
                setShow={setShowUsernameModal} 
                initialName={profile?.first_name + " " + profile?.last_name} 
                initialLogin={profile?.login} 
                handleSubmit={submitUsername}
                errors={errors}
                clearErrors={clearErrors}
              />}
            </Box>
          </Box>
        : (
          <Box className={classes.usernameArea}>
            <Skeleton variant='rect' width='60%' height={20} style={{marginBottom: 10}} />
            <Skeleton variant='rect' width='40%' height={15} />
          </Box>
        )}
    </Box>
  );
}

EditUsername.propTypes = ({
  profile: PropTypes.shape(),
  submitUsername: PropTypes.func,
  showUsernameModal: PropTypes.bool,
  setShowUsernameModal: PropTypes.func,
  handleShowModal: PropTypes.func,
  errors: PropTypes.shape(),
  clearErrors: PropTypes.func
});

EditUsername.defaultProps = ({
  profile: null,
  submitUsername: null,
  showUsernameModal: false,
  setShowUsernameModal: null,
  handleShowModal: null,
  errors: {},
  clearErrors: null
});