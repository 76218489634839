import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Text from '~/components/atoms/Text';
import ComponentAccessControlWrapper from '~/components/molecules/ComponentAccessControlWrapper';
import DashboardBreadcrumbs from '~/components/molecules/DashboardBreadcrumbs';
import EnvironmentItem from '~/components/molecules/EnvironmentItem';
import StructureCreationPopUp from '~/components/molecules/StructureCreationPopUp';
import AppbarPages from '~/components/templates/AppbarPages';
import i18n from '~/I18n';
import { hasAdminAccess } from '~/services/AccessControl/accessControl';
import { getAll } from '~/services/environment';
import { Creators as UserActions } from '~/store/ducks/user';

import HeaderFilters from './HeaderFilters';
import LoadingMore from './Loading/loadingMore';
import { useStyles } from './styles';
import { Box, Grid, useTheme } from '@material-ui/core';

export default function Environments() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const { getProfileRequest } = UserActions;
  const dispatch = useDispatch();

  const { profile } = useSelector((state) => state.user);

  // State for pagination
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [environments, setEnvironments] = useState([]);
  const filters = useForm({
    defaultValues: {
      ord: '',
      direction: '',
      name_like: '',
      state: '',
      regionalGroup: '',
      city: ''
    }
  });
  const [hasMore, setHasMore] = useState(true);

  // Fetch user profile
  function fetchProfile() {
    dispatch(getProfileRequest());
  }

  // Fetch environments with pagination and filters
  const fetchEnvironments = (pageNumber, filterOptions) => {
    if (isLoading) return;

    setIsLoading(true);
    getAll(pageNumber, filterOptions)
      .then((data) => {
        if (data.length < 10) {
          setHasMore(false);
        }
        setEnvironments((prevEnvironments) => [...prevEnvironments, ...data]);
        setIsLoading(false);
        setPage(pageNumber + 1);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  // Load more items when scrolling
  const loadMoreItems = (event) => {
    const bottom =
      event.target.scrollHeight <=
      event.target.scrollTop + event.target.offsetHeight + 180;

    if (bottom && hasMore) {
      fetchEnvironments(page, filters.getValues());
    }
  };

  // Fetch user profile on component mount
  useEffect(() => {
    if (!profile) fetchProfile();
  }, []);

  // Fetch environments and set document title on component mount
  useEffect(() => {
    document.title = `${theme.title} | ${i18n.t(
      'StructuresName.Environment.PluralUpper'
    )}`;
  }, []);

  return (
    <AppbarPages>
      <Box className={classes.content}>
        <Box className={classes.header}>
          <Grid
            container
            justifyContent="space-between"
            style={{ width: '100%' }}
          >
            <Grid item>
              {/* Breadcrumbs */}
              <DashboardBreadcrumbs
                links={[
                  {
                    name: i18n.t('MyEnvironments'),
                    path: '/dashboard/environments'
                  }
                ]}
              />
              {/* Page Title */}
              <Text
                color="primary"
                variant="xl"
                style={{ letterSpacing: 1, fontWeight: 500 }}
              >
                {i18n.t('MyEnvironments')}
              </Text>
              {/* Component to create new environment */}
              <Box style={{ marginTop: 15, display: 'inline-flex' }}>
                <ComponentAccessControlWrapper
                  accessFunction={hasAdminAccess}
                  Component={StructureCreationPopUp}
                  text={i18n.t('CreateEnvironment')}
                  structure={{
                    name: 'environment'
                  }}
                  structureName={i18n.t(
                    'StructuresName.Environment.SingularLower'
                  )}
                  redirectLink={`/dashboard/environments/new`}
                />
              </Box>

              <Box className={classes.filter}>
                {/* Filters component */}
                <FormProvider {...filters}>
                  <HeaderFilters
                    fetchEnvironments={fetchEnvironments}
                    setEnvironments={setEnvironments}
                    setPage={setPage}
                    setHasMore={setHasMore}
                  />
                </FormProvider>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {profile && environments.length > 0 ? (
          // Render environments if there are environments
          <Box
            className={classes.environmentContainer}
            onScroll={loadMoreItems}
          >
            <Box className={classes.environmentsGrid}>
              {environments.map((environment, index) => (
                <EnvironmentItem key={index} environment={environment} />
              ))}
              {/* Loading indicator while loading more items */}
              {isLoading === true ? <LoadingMore /> : null}
            </Box>
          </Box>
        ) : isLoading ? (
          <Box display="flex" justifyContent="space-between" width="80%">
            <LoadingMore />
          </Box>
        ) : (
          // Display message if no environments found
          <Text color="primary">
            Não foi possível encontrar nenhum{' '}
            {`${i18n.t('StructuresName.Environment.SingularLower')}`}.
          </Text>
        )}
      </Box>
    </AppbarPages>
  );
}
