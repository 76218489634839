import React from 'react';

import { useStyles } from '../styles';
import { Box, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export default function LoadingMore() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return Array(2)
    .fill(null)
    .map((shimmer, index) => (
      <Box key={index} className={classes.loadingContainer}>
        <Skeleton className={classes.shimmerItem} variant="rect" />
        <Box className={classes.shimmerText}>
          <Skeleton
            variant="rect"
            height={20}
            width={120}
            style={{ marginBottom: 10 }}
          />
          <Skeleton variant="rect" height={18} width={100} />
        </Box>
      </Box>
    ));
}
