import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    marginTop: 8,
    color: theme.palette.primary.main
  },
  radioIcon: {
    fontSize: '2rem',
    marginRight: theme.spacing(1)
  },
  description: (props) => ({
    color: theme.palette.textActiveElement.main,
    marginTop: props.isStructureComponent ? 10 : 0,
    fontSize: '1.3rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem'
    }
  }),
  radioLabel: {
    fontSize: '1.4rem',
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem'
    }
  }
}));
