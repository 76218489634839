import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles({
  avatarContainer: {
    display: 'none',
    paddingTop: 24,
    '@media (max-width: 450px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }
  }
});

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  padding: 0 7em;
  background-color: white;
  ::-webkit-scrollbar {
    width: 5px;
  }

  @media (max-width: 1630px) {
    padding: 0 3.5em;
  }

  //Breakpoint intermediário pra dar mais espaço horizontal para os murais
  @media (max-width: 660px) {
    padding: 0 0 0 3.5em;
  }

  @media (max-width: 450px) {
    padding: 0 0 0 1em;
    margin-top: 76px;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.scollbarThumbCOlor};
    border-radius: 2px;
  }
`;

export const Engagement = styled.div`
  display: flex;
  flex-direction: row;
`;
