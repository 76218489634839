import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minWidth: '35vw',
    maxHeight: '70vh',
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      minWidth: '90vw',
      maxHeight: '100vh',
    },
  },
  outterBox: {
    padding: '2rem'
  },
  title: {
    fontSize: '2rem',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
  },
  button: {
    fontSize: '1.3rem',
  },
}))