import api from '~/services/api';

export const createMirroredSpace = async (courseID, body) => {
  return await api.post(`/api/courses/${courseID}/mirrored_spaces`, body);
};

export const createClonedSpace = async (courseID, originalID) => {
  return await api.post(`/api/cloning/spaces/`, {
    original_id: originalID,
    course_id: courseID
  });
};

export const getMirrorOptions = async (params = {}, page = 1) => {
  const { courseID, spaceID, environmentID } = params;
  let url = `/api/mirroring/spaces/mirror_options?page=${page}`;
  if (environmentID) {
    url += `&environment_id=${environmentID}`;
  } else if (courseID) {
    url += `&course_id=${courseID}`;
  } else if (spaceID) {
    url += `&space_id=${spaceID}`;
  }
  const { data } = await api.get(url);
  if (data.length == 0) return null;
  return data;
};

export const getCloneableOptions = async (params = {}, page = 1) => {
  const { courseID, spaceID, environmentID } = params;
  let url = `/api/cloning/spaces/cloning_options?page=${page}`;
  if (environmentID) {
    url += `&environment_id=${environmentID}`;
  } else if (courseID) {
    url += `&course_id=${courseID}`;
  } else if (spaceID) {
    url += `&space_id=${spaceID}`;
  }
  const { data } = await api.get(url);
  if (data.length == 0) return null;
  return data;
};