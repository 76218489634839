import {
  hasCustomAccessTo,
  hasManageAccessTo,
  hasReadAccessTo
} from './accessControl';

const MIRRORED_SUBJECT_URL = 'mirrored_subjects/';

export const hasManageAccessToMirroredSubject = async (mirroredSubjectID) =>
  await hasManageAccessTo(`${MIRRORED_SUBJECT_URL}${mirroredSubjectID}`);
export const hasReadAccessToMirroredSubject = async (mirroredSubjectID) =>
  await hasReadAccessTo(`${MIRRORED_SUBJECT_URL}${mirroredSubjectID}`);
export const hasUpdateAccessToMirroredSubject = async (mirroredSubjectID) =>
  await hasCustomAccessTo(
    `${MIRRORED_SUBJECT_URL}${mirroredSubjectID}`,
    'update'
  );
