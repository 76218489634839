// Import the API module to make HTTP requests
import api from '~/services/api';

// Define the base URL for the spaces API
const baseURL = '/api/lectures';

// Function to find a lecture by its ID
export const findByID = async (lectureID) => {
  // Make a GET request to retrieve lecture data by its ID
  const { data } = await api.get(baseURL + '/' + lectureID);

  // Return the response data from the API call
  return data;
};

// Function to update a lecture by its ID with the provided body
export const update = async (lectureID, body) => {
  // Make a PUT request to update the lecture with the given ID and provided body
  const { data } = await api.put(baseURL + `/${lectureID}`, body);

  // Return the response data from the API call
  return data;
};

// Function to remove a lecture by its ID
export const remove = async (lectureID) => {
  // Make a DELETE request to remove the lecture with the given ID
  const { data } = await api.delete(baseURL + `/${lectureID}`);

  // Return the response data from the API call
  return data;
};

export const getSummaryLectures = async () => {
  const { data } = await api.get(baseURL + '/summary_lectures');

  return data;
};

// Export the 'findByID,' 'update,' and 'remove' functions as part of an object containing all functions
export default {
  findByID,
  update,
  remove,
  getSummaryLectures
};
