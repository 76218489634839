/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import ReplicatedSpaceForm from '~/components/organisms/ReplicatedSpaceForm';
import { useCustomSnackbar } from '~/contexts/customSnackBar';
import {
  createMirroredSpace,
  createClonedSpace
} from '~/services/replications/spaces';
import { handleErrors } from '~/utils/auxiliaryFunctions';
import { replicatedSpaceValidations } from '~/utils/SpaceValidation/replicatedSpace';

import { useSnackbar } from 'notistack';

export default function CreateReplicatedSpace({
  setFormConfig,
  isMirroring,
  setSubmission,
  texts
}) {
  const { courseId: courseID, id: environmentID } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { showSnackbar } = useCustomSnackbar();
  const [, setSubmissionStatus] = useState('');

  const defaultValues = {
    spaceID: ''
  };

  useEffect(() => {
    setFormConfig(defaultValues, replicatedSpaceValidations);
    setSubmission(() => submitForm);
  }, []);

  const finalizeSuccess = (response) => {
    const createdSpaceID = isMirroring
      ? response.data.parent.id
      : response.data.id;

    setSubmissionStatus('success');
    showSnackbar(
      `${isMirroring ? texts.mirroring.success : texts.duplication.success}!`,
      {
        buttonText: 'Ver andamento',
        buttonAction: () => history.push(`/dashboard/spaces/${createdSpaceID}`)
      }
    );
  };

  const finalizeFailure = (error) => {
    handleErrors(error, enqueueSnackbar);
    setSubmissionStatus('error');
  };

  function submitForm(fields) {
    const createReplicatedSpace = isMirroring
      ? createMirroredSpace
      : createClonedSpace;

    let body = {};

    if (isMirroring) {
      body = {
        mirrored_space: { original_id: fields.spaceID }
      };
    } else {
      body = fields.spaceID;
    }

    createReplicatedSpace(courseID, body)
      .then((response) => finalizeSuccess(response))
      .catch(finalizeFailure);
  }

  return <ReplicatedSpaceForm isMirroring={isMirroring} />;
}
