import React from 'react';

import { useStyles } from './styles';
import { Box, Typography } from '@material-ui/core';

export default function Description({ Icon, label, text, isStructureComponent }) {
  const classes = useStyles({ isStructureComponent });

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        className={classes.formControlLabel}
      >
        <Icon className={classes.radioIcon} />
        <Typography className={classes.radioLabel}>{label}</Typography>
      </Box>
      <Typography
        variant="caption"
        className={classes.description}
        display="block"
        gutterBottom
      >
        {text}
      </Typography>
    </Box>
  );
}
