import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactLoading from 'react-loading';

import Text from '~/components/atoms/Text';
import PaginatableSelect from '~/components/molecules/PaginatableSelect';
import i18n from '~/I18n';
import {
  getMirrorOptions,
  getCloneableOptions
} from '~/services/replications/spaces';

import { useStyles } from './styles';
import { Collapse, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export default function ReplicatedSpaceForm({ isMirroring }) {
  const theme = useTheme();
  const classes = useStyles();

  const {
    setValue,
    formState: {
      errors: { spaceID }
    },
    watch
  } = useFormContext();

  const [isLoading, setIsLoading] = useState(false);
  const [environments, setEnvironments] = useState(null);
  const [courses, setCourses] = useState(null);
  const [spaces, setSpaces] = useState(null);

  const [selectedEnvironment, setSelectedEnvironment] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedSpace, setSelectedSpace] = useState(null);
  const fetchStructures = isMirroring ? getMirrorOptions : getCloneableOptions;

  const handleSuccess = (values, setValues) => {
    setValues(values);
    setIsLoading(false);
  };

  const handleFailure = () => {
    setIsLoading(false);
  };

  const handleEnvironments = (event) => {
    setIsLoading(true);
    setValue('spaceID', '');
    setCourses(null);
    setSpaces(null);

    const { target } = event;
    fetchStructures({ environmentID: target.value })
      .then((courses) => {
        setSelectedEnvironment(target.value);
        handleSuccess(courses, setCourses);
      })
      .catch(handleFailure);
  };

  const handleCourses = (event) => {
    setIsLoading(true);
    setValue('spaceID', '');
    setSpaces(null);

    const { target } = event;
    fetchStructures({ courseID: target.value })
      .then((spaces) => {
        setSelectedCourse(target.value);
        handleSuccess(spaces, setSpaces);
      })
      .catch(handleFailure);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchStructures({})
      .then((environments) => handleSuccess(environments, setEnvironments))
      .catch(handleFailure);
  }, []);

  useEffect(() => {
    if (watch('spaceID'))
      setSelectedSpace(spaces.find((x) => x.id == watch('spaceID')));
    else setSelectedSpace(null);
  }, [watch('spaceID')]);

  return (
    <div className={classes.content}>
      {isLoading && !environments ? (
        <Grid container justifyContent="center">
          <ReactLoading type="bars" color={`${theme.palette.primary.main}`} />
        </Grid>
      ) : (
        <>
          {spaceID ? (
            <Text color="error">
              Você ainda não selecionou uma{' '}
              {`${i18n.t('StructuresName.Space.SingularUpper')}`}.
            </Text>
          ) : null}

          {environments ? (
            <Grid
              container
              direction="column"
              spacing={2}
              style={{ height: 'max-content' }}
            >
              <Grid item xs={12} id="jsdhkjsdhkjfsdahjkafsd">
                <PaginatableSelect
                  value={selectedEnvironment}
                  onChange={handleEnvironments}
                  label={`Selecione um ${i18n.t(
                    'StructuresName.Environment.SingularUpper'
                  )}`}
                  paginatableFunction={fetchStructures}
                  params={{}}
                  objectList={environments}
                  setObjectList={setEnvironments}
                  labelAttribute={'name'}
                  valueAttribute={'id'}
                />
              </Grid>

              <Grid item xs={12}>
                <Collapse in={isLoading && environments && !courses}>
                  <ReactLoading
                    width={30}
                    height={30}
                    type="bars"
                    color={`${theme.palette.primary.main}`}
                  />
                </Collapse>

                <Collapse in={courses}>
                  <PaginatableSelect
                    value={selectedCourse}
                    onChange={handleCourses}
                    label={`Selecione um ${i18n.t(
                      'StructuresName.Course.PluralUpper'
                    )}`}
                    paginatableFunction={fetchStructures}
                    params={{ environmentID: selectedEnvironment }}
                    objectList={courses}
                    setObjectList={setCourses}
                    labelAttribute={'name'}
                    valueAttribute={'id'}
                  />
                </Collapse>
              </Grid>

              <Grid item xs={12}>
                <Collapse in={isLoading && courses && !spaces}>
                  <ReactLoading
                    width={30}
                    height={30}
                    type="bars"
                    color={`${theme.palette.primary.main}`}
                  />
                </Collapse>

                <Collapse in={courses && spaces}>
                  <PaginatableSelect
                    value={watch('spaceID')}
                    onChange={(e) => setValue('spaceID', e.target.value)}
                    label={`Selecione uma ${i18n.t(
                      'StructuresName.Space.SingularUpper'
                    )}`}
                    paginatableFunction={fetchStructures}
                    params={{ courseID: selectedCourse }}
                    objectList={spaces}
                    setObjectList={setSpaces}
                    labelAttribute={'name'}
                    valueAttribute={'id'}
                  />
                </Collapse>
              </Grid>

              <Grid item xs={12}>
                <Collapse in={watch('spaceID')}>
                  <Text
                    color="black"
                    variant={'actionText'}
                  >{`${selectedSpace?.course?.environment.name} >
                                                                  ${selectedSpace?.course.name} >
                                                                  ${selectedSpace?.name}`}</Text>
                  <Text
                    color="primary"
                    variant={'title'}
                    style={{ marginTop: '5px' }}
                  >
                    <span style={{ fontWeight: 'bold' }}>
                      {selectedSpace?.name}
                    </span>{' '}
                    selecionada
                  </Text>
                </Collapse>
              </Grid>
            </Grid>
          ) : (
            <Text color="primary">
              Infelizmente não encontramos nenhuma{' '}
              {`${i18n.t('StructuresName.Space.SingularUpper')}`} em que você
              seja o proprietário.
            </Text>
          )}
        </>
      )}
    </div>
  );
}
