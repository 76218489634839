import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    gap: '16px'
  },
  cancelButton: {
    display: 'flex',
    padding: '12px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#0056B4',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    borderRadius: '4px',
    '&:hover': {
      color: '#1A72F9',
      backgroundColor: '#F5F5F5'
    },
    '&:active': {
      color: '#0056B4',
      backgroundColor: '#E1E1E5'
    }
  },
  confirmButton: {
    display: 'flex',
    padding: '12px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    background: '#0056B4',
    color: 'white',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#1A72F9'
    },
    '&:active': {
      backgroundColor: '#1D3A85'
    },
    '&:disabled': {
      backgroundColor: '#B4BDD3',
      color: 'white'
    }
  }
}));
