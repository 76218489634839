import React from 'react';

import { useStyles } from './styles';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function RowText({ children, style }) {
  const classes = useStyles();

  return (
    <Typography variant="h6" className={classes.root} style={style}>
      {children}
    </Typography>
  );
}

RowText.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object
};
RowText.defaultProps = {
  children: {},
  style: {}
};
