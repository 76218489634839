import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const StyledTab = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparente',
    border: `1px solid #707070`,
    borderRadius: '5px',
    padding: '5px 10px',
    minHeight: 0,
    minWidth: 0,
    opacity: 0.4,
    marginRight: '5px'
  },
  selected: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`
  }
}));

export const WrapperCircularProgress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10;
`;
