import React from 'react';

import PropTypes from 'prop-types';

import { ButtonOutlined } from './styles';

export default function Button({
  pending,
  height,
  onClick,
  children,
  padding,
  textTransform,
  className,
}) {
  return (
    <ButtonOutlined
      className={className || ''}
      onClick={onClick}
      height={height}
      pending={pending}
      padding={padding}
      textTransform={textTransform}
    >
      {children}
    </ButtonOutlined>
  );
}

Button.propTypes = {
  pending: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  children: PropTypes.shape(),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textTransform: PropTypes.string,
};

Button.defaultProps = {
  pending: false,
  height: 40,
  onClick: null,
  children: null,
  padding: 0,
  textTransform: '',
};
