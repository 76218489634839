import React, { memo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import FriendshipRequest from '~/components/FeedItems/FriendshipRequest';
import AlertsDialog from '~/components/molecules/AlertsDialog';
import MuralItem from '~/components/molecules/MuralItem';
import TextForm from '~/components/Mural/TextForm';
import api from '~/services/api';
import { sendToMuralAnswerListener } from '~/services/mural';
import { Creators as SpacesCreators } from '~/store/ducks/spaces';

import Shimmer from './shimmer';
import { useStyles } from './styles';
import { Box, Divider, useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

const MuralTemplate = ({
  statuses,
  getStatuses,
  friendshipRequests,
  showTextarea,
  isDashboard,
  hasMore,
  space,
  addNewStatusMural,
  loading,
  setStatuses,
  target,
  lecture,
  postStatuses
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const { handleOpen, handleClose } = SpacesCreators;
  const { item } = useSelector((state) => state.spaces);
  const [proceed, setProceed] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const deleteItem = () => {
    if (item) {
      // dispara o event next no caso do usuário deletar todos os itens da lista
      if (item.type !== 'Answer') {
        if (target) {
          let scrollableDivTop = document.querySelector(`#${target}`);
          scrollableDivTop.scrollTop =
            scrollableDivTop.scrollTop > 0
              ? (scrollableDivTop.scrollTop = 0)
              : (scrollableDivTop.scrollTop = 1);
        }
        setProceed(true);
        api
          .delete(`/api/statuses/${item.id}`)
          .then(async () => {
            const newStatus = await statuses.filter((arrayItem) => {
              if (arrayItem.id !== item.id) return arrayItem;
            });
            setProceed(false);
            setStatuses(newStatus);
            dispatch(handleClose());
            enqueueSnackbar(`Item: ${item.text}, excluído!`, {
              variant: 'success'
            });
          })
          .catch(() => {
            setProceed(false);
            dispatch(handleClose());
            enqueueSnackbar(
              `Ocorreu um erro ao tentar excluir o item: ${item.text}!`,
              { variant: 'error' }
            );
          });
      } else {
        setProceed(true);
        api
          .delete(`/api/statuses/${item.id}`)
          .then(async () => {
            sendToMuralAnswerListener({ delete: true });
            setProceed(false);
            dispatch(handleClose());
            enqueueSnackbar(`Item: ${item.text}, excluído!`, {
              variant: 'success'
            });
          })
          .catch(() => {
            setProceed(false);
            dispatch(handleClose());
            enqueueSnackbar(
              `Ocorreu um erro ao tentar excluir o item: ${item.text}!`,
              { variant: 'error' }
            );
          });
      }
    }
  };

  return (
    <Box>
      <AlertsDialog handleProceed={deleteItem} proceed={proceed} />
      {friendshipRequests && friendshipRequests.length > 0 && (
        <FriendshipRequest list={friendshipRequests} />
      )}
      {showTextarea && (
        <TextForm
          space={space}
          addNewStatusMural={addNewStatusMural}
          loading={loading}
          postStatuses={postStatuses}
        />
      )}
      <InfiniteScroll
        style={{ overflowX: 'hidden' }}
        dataLength={statuses.length}
        next={getStatuses}
        hasMore={hasMore}
        loader={<Shimmer />}
        scrollableTarget={target}
        onScroll={() => {}}
      >
        {statuses.map((item) => {
          if (item.type === 'Answer') return false;
          return (
            <Box key={item.id} className={classes.muralItemContainer}>
              <MuralItem
                item={item}
                dashboard={isDashboard}
                handleOpen={handleOpen}
                lecture={lecture}
              />
              <Divider className={classes.divider} />
            </Box>
          );
        })}
      </InfiniteScroll>
    </Box>
  );
};

export default memo(MuralTemplate);

MuralTemplate.propTypes = {
  loading: PropTypes.bool,
  statuses: PropTypes.array,
  getStatuses: PropTypes.func,
  friendshipRequests: PropTypes.array,
  showTextarea: PropTypes.bool,
  isDashboard: PropTypes.bool,
  noMoreStatuses: PropTypes.bool,
  space: PropTypes.bool,
  hasMore: PropTypes.bool,
  addNewStatusMural: PropTypes.func,
  postStatuses: PropTypes.func,
  setStatuses: PropTypes.func,
  lecture: PropTypes.func,
  target: PropTypes.string
};

MuralTemplate.defaultProps = {
  loading: false,
  statuses: [],
  getStatuses: null,
  friendshipRequests: [],
  showTextarea: false,
  isDashboard: false,
  noMoreStatuses: false,
  space: false,
  hasMore: true,
  addNewStatusMural: null,
  postStatuses: null,
  setStatuses: () => {},
  lecture: null,
  target: null
};
