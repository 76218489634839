import {
  hasCustomAccessTo,
  hasManageAccessTo,
  hasReadAccessTo
} from './accessControl';

const MIRRORED_COURSE_URL = 'mirrored_courses/';

export const hasManageAccessToMirroredCourse = async (mirroredCourseID) =>
  await hasManageAccessTo(`${MIRRORED_COURSE_URL}${mirroredCourseID}`);
export const hasReadAccessToMirroredCourse = async (mirroredCourseID) =>
  await hasReadAccessTo(`${MIRRORED_COURSE_URL}${mirroredCourseID}`);
export const hasUpdateAccessToMirroredCourse = async (mirroredCourseID) =>
  await hasCustomAccessTo(
    `${MIRRORED_COURSE_URL}${mirroredCourseID}`,
    'update'
  );
