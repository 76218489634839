/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import ReplicatedEnvironmentForm from '~/components/organisms/ReplicatedEnvironmentForm';
import { useCustomSnackbar } from '~/contexts/customSnackBar';
import {
  createMirroredEnvironment,
  createClonedEnvironment
} from '~/services/replications/environments';
import { handleErrors } from '~/utils/auxiliaryFunctions';
import { replicatedEnvironmentValidations } from '~/utils/EnvironmentValidation/ReplicatedEnvironment';

import { useSnackbar } from 'notistack';

export default function CreateReplicatedEnvironment({
  setFormConfig,
  isMirroring,
  setSubmission,
  texts
}) {
  const [, setSubmissionStatus] = useState('');
  const { showSnackbar } = useCustomSnackbar();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const defaultValues = {
    environmentID: ''
  };
  useEffect(() => {
    setFormConfig(defaultValues, replicatedEnvironmentValidations);
    setSubmission(() => submitForm);
  }, []);

  const finalizeSuccess = (response) => {
    const createdEnvironmentID = isMirroring
      ? response.data.parent.id
      : response.data.id;

    setSubmissionStatus('success');

    showSnackbar(
      `${isMirroring ? texts.mirroring.success : texts.duplication.success}!`,
      {
        buttonText: 'Ver andamento',
        buttonAction: () =>
          history.push(`/dashboard/environments/${createdEnvironmentID}`)
      }
    );
  };

  const finalizeFailure = (error) => {
    handleErrors(error, enqueueSnackbar);
    setSubmissionStatus('error');
  };

  function submitForm(fields) {
    const createReplicatedEnvironment = isMirroring
      ? createMirroredEnvironment
      : createClonedEnvironment;

    let body = {};

    if (isMirroring) {
      body = {
        mirrored_environment: { original_id: fields.environmentID }
      };
    } else {
      body = fields.environmentID;
    }

    createReplicatedEnvironment(body)
      .then((response) => finalizeSuccess(response))
      .catch(finalizeFailure);
  }

  return <ReplicatedEnvironmentForm isMirroring={isMirroring} />;
}
