import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    whiteSpace: 'break-spaces',
    textOverflow: 'ellipsis'
  },
  titleBox: {
    marginTop: '16px',
    height: 45,
    '@media (max-width: 1460px)': {
      height: 20,
      marginBottom: 5
    },
    '@media (max-width: 450px)': {
      fontSize: 25
    }
  }
});
