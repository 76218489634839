/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Text from '~/components/atoms/Text';
import FormFilter from '~/components/FormFilter';
import OrderByPopover from '~/components/OrderByPopover';
import i18n from '~/I18n';
import { getFilters } from '~/services/environment';

import Selection from './Selection';
import { Box, Button, Grid, useMediaQuery, useTheme } from '@material-ui/core';

export default function HeaderFilters({
  fetchEnvironments,
  setEnvironments,
  setHasMore,
  setPage
}) {
  const { watch, setValue, getValues, reset } = useFormContext();
  const theme = useTheme();
  const [states, setStates] = useState([]);
  const [regionalGroups, setRegionalGroups] = useState([]);
  const [cities, setCities] = useState([]);

  const [lock, setLock] = useState(true);

  const isXsScreen = useMediaQuery(theme.breakpoints.only('xs'));

  const clearFilters = () => {
    reset({
      ...getValues(),
      state: '',
      regionalGroup: '',
      city: ''
    });
    updateFilteredData();
  };

  useEffect(() => {
    reset({
      ...getValues(),
      ord: watch('ord'),
      direction: watch('direction'),
      name_like: watch('name_like')
    });
    updateFilteredData();
  }, [watch('ord'), watch('direction'), watch('name_like')]);

  const updateFilteredData = () => {
    getFilters(getValues())
      .then((filtersData) => {
        setStates(filtersData.states);
        setRegionalGroups(filtersData.regional_groups);
        setCities(filtersData.cities);
        setLock(false);
      })
      .catch(() => {
        setLock(false);
      });

    setEnvironments([]);
    setPage(1);
    setHasMore(true);

    fetchEnvironments(1, getValues());
  };

  const handleSelectFilters = (selectedFilter, filterType) => {
    if (lock) return;
    setLock(true);

    switch (filterType) {
      case 'state':
        setValue('state', selectedFilter);
        setValue('regionalGroup', '');
        setValue('city', '');

        updateFilteredData();
        break;
      case 'regionalGroup':
        setValue('regionalGroup', selectedFilter);
        setValue('city', '');

        updateFilteredData();
        break;
      case 'city':
        setValue('city', selectedFilter);

        updateFilteredData();
        break;
    }
  };

  return (
    <Box>
      <Grid container spacing={1} alignItems="center">
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            marginBottom: '10px',
            gap: 24,
            paddingLeft: '4px'
          }}
        >
          <FormFilter
            placeholder={`Pesquisar ${i18n.t(
              'StructuresName.Environment.SingularUpper'
            )}...`}
          />
          <OrderByPopover />
        </div>

        <Grid item xs={isXsScreen ? 12 : undefined}>
          <Grid container spacing={1}>
            <Grid item xs={isXsScreen ? 12 : undefined}>
              <Selection
                objects={states}
                handleOnChange={(state) => handleSelectFilters(state, 'state')}
                selectedValue={watch('state')}
                defaultValue={'Estado'}
              />
            </Grid>
            <Grid item xs={isXsScreen ? 12 : undefined}>
              <Selection
                objects={regionalGroups}
                handleOnChange={(regionalGroup) =>
                  handleSelectFilters(regionalGroup, 'regionalGroup')
                }
                selectedValue={watch('regionalGroup')}
                defaultValue={'Grupo Regional'}
              />
            </Grid>
            <Grid item xs={isXsScreen ? 12 : undefined}>
              <Selection
                objects={cities} // Replace 'objects' with appropriate prop name
                handleOnChange={(city) => handleSelectFilters(city, 'city')}
                selectedValue={watch('city')}
                defaultValue={i18n.t('City')}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={isXsScreen ? 12 : undefined}>
          {watch('state') === '' &&
          watch('regionalGroup') === '' &&
          watch('city') === '' ? (
            <Button
              style={{
                width: '140px',
                height: '35px',
                textTransform: 'none'
              }}
              disabled
              variant="outlined"
              color="gray"
            >
              <Text color="gray"> Limpar Filtros </Text>
            </Button>
          ) : (
            <Button
              style={{
                width: '140px',
                height: '35px',
                textTransform: 'none'
              }}
              variant="contained"
              color="primary"
              onClick={clearFilters}
            >
              <Text color="white"> Limpar Filtros </Text>
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
