import React, { useEffect, useState } from 'react';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';

import { useStyles } from '../LectureDirectionButton/styles';
import { useTheme } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { getPdfData } from '~/services/Lecture/pdf';
import fileDownload from 'js-file-download';

export default function DownloadDocumentButton({ lecture }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [pdfData, setPdfData] = useState(null);

  function downloadPdf() {
    if (!pdfData) {
      getPdfData(lecture.id)
        .then((file) => {
          setPdfData(file);
        })
        .catch();
    } else {
      fileDownload(pdfData, `${lecture.name}.pdf`);
    }
  }

  useEffect(() => {
    if (pdfData) fileDownload(pdfData, `${lecture.name}.pdf`);
  }, [pdfData]);

  return (
    <ButtonSquare
      className={`${classes.button} ${classes.downloadButton}`}
      border={`0.5px solid ${theme.palette.secondary.main}`}
      padding="5px 10px"
      onClick={downloadPdf}
    >
      <GetAppIcon
        className={classes.icon}
        style={{ color: theme.palette.secondary.main, marginRight: '7px' }}
      />
      <Text variant="mediumText" fontWeight="700" color="secondary">
        download
      </Text>
    </ButtonSquare>
  );
}
