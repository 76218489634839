import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    backgroundColor: 'white',
    padding: '4px 12px',
    borderRadius: '5px',
    marginBottom: '8px'
  },
  textField: {
    margin: 0, 
    padding: 0,
    fontSize: '12px'
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
});
