import React from 'react';
import { Collapse, Grid, Hidden } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import DateTimePickerInput from '~/components/atoms/DateTimePickerInput';
import Select from '~/components/atoms/Select';
import Input from '~/components/atoms/TextInput';
import Field from '~/components/organisms/GenericFormWrapper/Field';
import { TextPrimary } from '~/utils/forms';

import { useStyles } from './styles';

const videoCallLabels = [
  "Chamada de Vídeo padrão",
  // "Chamada de Vídeo Zoom",
  "Chamada de Vídeo Google Meet",
]

const videoCallOptions = [
  "call",
  // "zoom",
  "google_meet",
]

export default function VideoCall() {
  const classes = useStyles()
  const { watch } = useFormContext()

  return(
    <div>
      <div className={classes.field}>
        <Field  label={TextPrimary("Tipo de vídeo")} 
                name="lectureable.videocall.kind" 
                Component={Select}
                placeholder="Selecione o tipo de vídeo..." 
                values={videoCallOptions}
                labels={videoCallLabels}
                fullWidth 
                />
      </div>
      <div>
        <Collapse in={watch("lectureable.videocall.kind") == 'google_meet'}>
          <div className={classes.description}>
            <Field label={TextPrimary("Descrição do evento")} name="lectureable[videocall][google_meet][description]" placeholder="Descrição..." Component={Input} multiline />
          </div>
          <div>

            <Hidden mdUp>
              
              <Grid container direction='column' spacing={2}>
                <Grid item xs={9} sm={6}>
                  <Field label={TextPrimary("Inicia em")} name="lectureable.videocall.google_meet.event_start" Component={DateTimePickerInput} />
                </Grid>
                <Grid item xs={9} sm={6}>
                  <Field label={TextPrimary("Termina em")} name="lectureable.videocall.google_meet.event_end" Component={DateTimePickerInput} />
                </Grid>
              </Grid>
            
            </Hidden>
            <Hidden smDown>
              
              <Grid container spacing={4}>

                <Grid item>
                  <Field label={TextPrimary("Inicia em")} name="lectureable.videocall.google_meet.event_start" Component={DateTimePickerInput} />
                </Grid>
                <Grid item>
                  <Field label={TextPrimary("Termina em")} name="lectureable.videocall.google_meet.event_end" Component={DateTimePickerInput} />
                </Grid>

              </Grid>
              
            </Hidden>
            
          </div>
        </Collapse>
      </div>
    </div>
  );
}
