import settings from '~settings';

const redu = {
  MyEnvironments: 'Meus Ambientes',
  InfoEnvironments: 'Informações do Ambiente',
  InfoCourses: 'Informações do Curso',
  InfoSpace: 'Informações da Disciplina',
  Hours: 'horas',
  Message: 'Mensagem',
  Messages: 'Mensagens',
  CreateEnvironment: 'Novo ambiente',
  CreateMirroredEnvironment: 'Criar Ambiente Espelhado',
  EmptyListEnvironments: 'Você ainda não possui nenhum ambiente.',
  NoSubscription: 'Sem ambientes',
  AddSubscription: 'Cadastrar ambiente',
  ManageEnvironment: 'Gerenciar Ambiente',
  EditEnvironment: 'Editar Ambiente',
  NoCoursesForThisEnvironment: 'Não foi possível encontrar nenhum curso',
  NoSpacesForThisCourse: 'Não foi possível encontrar nenhuma disciplina.',
  lastClass: 'Última mídia acessada',
  nextClasses: 'Próximas mídias',
  CreateCourse: 'Novo Curso',
  EditCourse: 'Editar Curso',
  ManageCourse: 'Gerenciar Curso',
  CourseInformation: 'Informações do curso',
  CommonCourses: 'Cursos em comum',
  AddNewMemberToCourse: 'Adicionar novo membro ao Curso',
  ofTheSpace: 'da Disciplina',
  myCourses: 'Minhas Disciplinas',
  noCourses: 'Sem disciplinas',
  ManageDiscipline: 'Gerenciar Disciplina',
  Disciplines: 'Disciplinas',
  AddDisciplineToCourse: 'Adicionar disciplina',
  AllModules: 'Todos os módulos',
  NoClassesOnThisModule: 'Esse módulo não tem mídias ainda',
  Classes: 'Mídias',
  commentedLecture: 'comentou na mídia',
  helpLecture: 'pediu ajuda na mídia',
  EmptyLectureSumaryList: 'Você não acessou nenhuma mídia recentemente',
  noNextClasses: 'Não há próximas mídias',
  Environments: 'Ambientes',
  Environment: 'Ambiente',
  Subjects: 'Módulo',
  Lectures: 'mídia',
  AddNewMember: {
    Course: 'Adicionar novo membro ao Curso',
    Space: 'Adicionar novo membro a Disciplina',
    Environment: 'Adicionar novo membro ao Ambiente'
  },
  CreateSubject: {
    title: 'Novo Módulo',
    saveButton: 'Criar'
  },
  CreateMirroredSubject: {
    title: 'Criar Módulo Espelhado',
    saveButton: 'Criar'
  },
  EditSubject: {
    title: 'Editar Módulo',
    saveButton: 'Salvar'
  },
  CreateSpace: {
    title: 'Nova Disciplina',
    saveButton: 'Criar'
  },
  CreateMirroredSpace: {
    title: 'Criar Disciplina Espelhada',
    saveButton: 'Criar'
  },
  EditSpace: {
    title: 'Editar Disciplina',
    saveButton: 'Salvar'
  },
  CreateMirroredCourse: {
    title: 'Criar Curso Espelhado',
    saveButton: 'Criar'
  },
  EditMirroredCourse: {
    title: 'Editar Curso',
    saveButton: 'Salvar'
  },
  DeleteEnvironment: {
    title: 'Excluir Ambiente'
  },
  DeleteMirroredEnvironment: {
    title: 'Excluir Ambiente Espelhado'
  },
  DeleteCourse: {
    title: 'Excluir Curso'
  },
  DeleteMirroredCourse: {
    title: 'Excluir Curso Espelhado'
  },
  DeleteSpace: {
    title: 'Excluir Disciplina'
  },
  DeleteMirroredSpace: {
    title: 'Excluir Disciplina Espelhada'
  },
  DeleteSubject: {
    title: 'Excluir Módulo'
  },
  DeleteMirroredSubject: {
    title: 'Excluir Módulo Espelhado'
  },
  DeleteMedia: {
    title: 'Excluir Mídia'
  },
  DeleteMirroredMedia: {
    title: 'Excluir Mídia Espelhada'
  },
  StructuresName: {
    Environment: {
      SingularUpper: 'Ambiente',
      PluralUpper: 'Ambientes',
      SingularLower: 'ambiente',
      PluralLower: 'ambientes'
    },
    Course: {
      SingularUpper: 'Curso',
      SingularLower: 'curso',
      PluralUpper: 'Cursos',
      PluralLower: 'cursos'
    },
    Space: {
      SingularUpper: 'Disciplina',
      PluralUpper: 'Disciplinas',
      SingularLower: 'disciplina',
      PluralLower: 'disciplinas'
    },
    Subjects: {
      SingularUpper: 'Módulo',
      PluralUpper: 'Módulos',
      SingularLower: 'módulo',
      PluralLower: 'módulos'
    },
    Lectures: {
      SingularLower: 'mídia',
      PluralLower: 'mídias',
      PluralUpper: 'Mídias',
      SingularUpper: 'Mídia'
    }
  }
  /* ${i18n.t('StructuresName.Lectures.PluralLower')} */
  /* import i18n from '~/I18n'; */
};

const avamec = {
  MyEnvironments: 'Meus ambientes',
  InfoEnvironments: 'Informações do ambiente',
  InfoCourses: 'Informações do Espaço',
  InfoSpace: 'Informações da Área',
  Hours: 'horas',
  Message: 'Chat',
  Messages: 'Chat',
  CreateEnvironment: 'Novo ambiente',
  CreateMirroredEnvironment: 'Criar ambiente Espelhado',
  EmptyListEnvironments: 'Você ainda não possui nenhum ambiente.',
  NoSubscription: 'Sem ambientes',
  AddSubscription: 'Cadastrar ambiente',
  ManageEnvironment: 'Gerenciar ambiente',
  EditEnvironment: 'Editar ambiente',
  NoCoursesForThisEnvironment: 'Não foi possível encontrar nenhum espaço',
  NoSpacesForThisCourse: 'Não foi possível encontrar nenhuma área.',
  lastClass: 'Última mídia acessada',
  nextClasses: 'Próximas mídias',
  CreateCourse: 'Novo Espaço',
  EditCourse: 'Editar Espaço',
  ManageCourse: 'Gerenciar Espaço',
  CourseInformation: 'Informações do espaço',
  CommonCourses: 'Espaços em comum',
  AddNewMemberToCourse: 'Adicionar novo membro ao Espaço',
  ofTheSpace: 'da Área',
  myCourses: 'Minhas Áreas',
  noCourses: 'Sem áreas',
  ManageDiscipline: 'Gerenciar Área',
  Disciplines: 'Áreas',
  AddDisciplineToCourse: 'Adicionar área',
  AllModules: 'Todos os módulos',
  NoClassesOnThisModule: 'Esse módulo não tem mídias ainda',
  Classes: 'Mídias',
  commentedLecture: 'comentou na mídia',
  helpLecture: 'pediu ajuda na mídia',
  EmptyLectureSumaryList: 'Você não acessou nenhuma mídia recentemente',
  noNextClasses: 'Não há próximas mídias',
  Environments: 'Ambientes',
  Environment: 'Ambiente',
  Subjects: 'Módulo',
  Lectures: 'mídia',
  CreateSubject: {
    title: 'Novo Módulo',
    saveButton: 'Criar'
  },
  CreateMirroredSubject: {
    title: 'Criar Módulo Espelhado',
    saveButton: 'Criar'
  },
  EditSubject: {
    title: 'Editar Módulo',
    saveButton: 'Salvar'
  },
  CreateSpace: {
    title: 'Nova Área',
    saveButton: 'Criar'
  },
  CreateMirroredSpace: {
    title: 'Criar Área Espelhada',
    saveButton: 'Criar'
  },
  EditSpace: {
    title: 'Editar Área',
    saveButton: 'Salvar'
  },
  CreateMirroredCourse: {
    title: 'Criar Espaço Espelhado',
    saveButton: 'Criar'
  },
  EditMirroredCourse: {
    title: 'Editar Espaço',
    saveButton: 'Salvar'
  },
  DeleteEnvironment: {
    title: 'Excluir Ambiente'
  },
  DeleteMirroredEnvironment: {
    title: 'Excluir Ambiente Espelhado'
  },
  DeleteCourse: {
    title: 'Excluir Espaço'
  },
  DeleteMirroredCourse: {
    title: 'Excluir Espaço Espelhado'
  },
  DeleteSpace: {
    title: 'Excluir Área'
  },
  DeleteMirroredSpace: {
    title: 'Excluir Área Espelhada'
  },
  DeleteSubject: {
    title: 'Excluir Módulo'
  },
  DeleteMirroredSubject: {
    title: 'Excluir Módulo Espelhado'
  },
  DeleteMedia: {
    title: 'Excluir Mídia'
  },
  DeleteMirroredMedia: {
    title: 'Excluir Mídia Espelhada'
  },
  StructuresName: {
    Environment: {
      SingularUpper: 'Ambiente',
      PluralUpper: 'Ambientes',
      SingularLower: 'ambiente',
      PluralLower: 'ambientes'
    },
    Course: {
      SingularUpper: 'Espaço',
      SingularLower: 'espaço',
      PluralUpper: 'Espaços',
      PluralLower: 'espaços'
    },
    Space: {
      SingularUpper: 'Área',
      PluralUpper: 'Áreas',
      SingularLower: 'área',
      PluralLower: 'áreas'
    },
    Subjects: {
      SingularUpper: 'Módulo',
      PluralUpper: 'Módulos',
      SingularLower: 'módulo',
      PluralLower: 'módulos'
    },
    Lectures: {
      SingularLower: 'mídia',
      PluralLower: 'mídias',
      PluralUpper: 'Mídias',
      SingularUpper: 'Mídia'
    }
  }
};

export function selectEnglishWordsByClient() {
  switch (settings.name) {
    case 'redu':
      return redu;

    case 'AVAMEC Interativo':
      return avamec;

    default:
      return redu;
  }
}
