import React, { useEffect, useState } from 'react';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import { IconWrapper } from '~/utils/forms';

import ExerciseAverageReport from './ExerciseAverageReport';
import ExerciseGradesCountReport from './ExerciseGradesCountReport';
import { getSpaceSubjects } from './fetch';
import FormConclusionReport from './FormConclusionReport';
import SimplifiedSubjectsReport from './SimplifiedSubjectsReport';
import { useStyles } from './styles';
import SubjectLecturesReport from './SubjectLecturesReport';
import {
  ListSubheader,
  List,
  ListItem,
  ListItemIcon,
  Collapse,
  Grid
} from '@material-ui/core';
import { ExpandLess, ExpandMore, Description } from '@material-ui/icons/';

export default function SpaceReports ({ space }) {
  const classes = useStyles();

  const [subjects, setSubjects] = useState([]);

  const [openSimpSubReport, setOpenSimpSubReport] = useState(false);
  const [openSubLecReport, setOpenSubLecReport] = useState(false);
  const [openAverageReport, setOpenAverageReport] = useState(false);
  const [openGradesCountReport, setOpenGradesCountReport] = useState(false);
  const [openFormConclusionReport, setopenFormConclusionReport] = useState(
    false
  );

  const toggleSimp = () => {
    setOpenSimpSubReport(!openSimpSubReport);
  };

  const toggleSub = () => {
    setOpenSubLecReport(!openSubLecReport);
  };

  const toggleAverageReport = () => {
    setOpenAverageReport(!openAverageReport);
  };

  const toggleGradesCountReport = () => {
    setOpenGradesCountReport(!openGradesCountReport);
  };

  const toggleFormConclusionReport = () => {
    setopenFormConclusionReport(!openFormConclusionReport);
  };

  const initSubjects = (response) => {
    setSubjects(response.data);
  };

  const Header = () => {
    return (
      <ListSubheader
        className={classes.header}
        component="div"
        id="nested-list-subheader"
      >
        <Text color="primary" variant="lg">
          {' '}
          Relatórios{' '}
        </Text>
      </ListSubheader>
    );
  };

  useEffect(() => {
    if (space)
      getSpaceSubjects(space.id)
        .then(initSubjects)
        .catch(console.log);
  }, [space]);

  return (
    <div className={classes.container}>
      <List subheader={<Header />} className={classes.root}>
        <div className={classes.field}>
          <ListItem className={classes.listItem} button onClick={toggleSimp}>
            <Grid container alignItems="center">
              <Grid container item xs={10} alignItems="center">
                <ListItemIcon>
                  <IconWrapper
                    color="primary"
                    fontSize={40}
                    Icon={Description}
                  />
                </ListItemIcon>
                <Text color="primary" variant="largeText">
                  Relatório simplificado de conclusão de{' '}
                  {`${i18n.t('StructuresName.Subjects.PluralUpper')}`}
                </Text>
              </Grid>

              <Grid item xs={2}>
                <IconWrapper
                  color="primary"
                  fontSize={35}
                  Icon={openSimpSubReport ? ExpandLess : ExpandMore}
                />{' '}
                { }
              </Grid>
            </Grid>
          </ListItem>
        </div>

        <Collapse in={openSimpSubReport} timeout="auto" unmountOnExit>
          <div className={classes.field}>
            <SimplifiedSubjectsReport space={space} />
          </div>
        </Collapse>

        <div className={classes.field}>
          <ListItem className={classes.listItem} button onClick={toggleSub}>
            <Grid container alignItems="center">
              <Grid container item xs={10} alignItems="center">
                <ListItemIcon>
                  <IconWrapper
                    color="primary"
                    fontSize={40}
                    Icon={Description}
                  />
                </ListItemIcon>
                <Text color="primary" variant="largeText">
                  Relatório de conclusão de{' '}
                  {`${i18n.t('StructuresName.Lectures.SingularLower')}`} por{' '}
                  {`${i18n.t('StructuresName.Subjects.SingularUpper')}`}
                </Text>
              </Grid>

              <Grid item xs={2}>
                <IconWrapper
                  color="primary"
                  fontSize={35}
                  Icon={openSubLecReport ? ExpandLess : ExpandMore}
                />{' '}
                { }
              </Grid>
            </Grid>
          </ListItem>
        </div>

        <Collapse in={openSubLecReport} timeout="auto" unmountOnExit>
          <div className={classes.field}>
            <SubjectLecturesReport subjects={subjects} />
          </div>
        </Collapse>

        <div className={classes.field}>
          <ListItem
            className={classes.listItem}
            button
            onClick={toggleAverageReport}
          >
            <Grid container alignItems="center">
              <Grid container item xs={10} alignItems="center">
                <ListItemIcon>
                  <IconWrapper
                    color="primary"
                    fontSize={40}
                    Icon={Description}
                  />
                </ListItemIcon>
                <Text color="primary" variant="largeText">
                  Relatório com média de todos os Exercícios
                </Text>
              </Grid>

              <Grid item xs={2}>
                <IconWrapper
                  color="primary"
                  fontSize={35}
                  Icon={openAverageReport ? ExpandLess : ExpandMore}
                />{' '}
                { }
              </Grid>
            </Grid>
          </ListItem>
        </div>

        <Collapse in={openAverageReport} timeout="auto" unmountOnExit>
          <div className={classes.field}>
            <ExerciseAverageReport space={space} />
          </div>
        </Collapse>

        <div className={classes.field}>
          <ListItem
            className={classes.listItem}
            button
            onClick={toggleGradesCountReport}
          >
            <Grid container alignItems="center">
              <Grid container item xs={10} alignItems="center">
                <ListItemIcon>
                  <IconWrapper
                    color="primary"
                    fontSize={40}
                    Icon={Description}
                  />
                </ListItemIcon>
                <Text color="primary" variant="largeText">
                  Relatório de acertos nos Exercícios
                </Text>
              </Grid>

              <Grid item xs={2}>
                <IconWrapper
                  color="primary"
                  fontSize={35}
                  Icon={openGradesCountReport ? ExpandLess : ExpandMore}
                />{' '}
                { }
              </Grid>
            </Grid>
          </ListItem>
        </div>

        <Collapse in={openGradesCountReport} timeout="auto" unmountOnExit>
          <div className={classes.field}>
            <ExerciseGradesCountReport space={space} />
          </div>
        </Collapse>

        <div className={classes.field}>
          <ListItem
            className={classes.listItem}
            button
            onClick={toggleFormConclusionReport}
          >
            <Grid container alignItems="center">
              <Grid container item xs={10} alignItems="center">
                <ListItemIcon>
                  <IconWrapper
                    color="primary"
                    fontSize={40}
                    Icon={Description}
                  />
                </ListItemIcon>
                <Text color="primary" variant="largeText">
                  Relatório de conclusão dos Questionários
                </Text>
              </Grid>

              <Grid item xs={2}>
                <IconWrapper
                  color="primary"
                  fontSize={35}
                  Icon={openFormConclusionReport ? ExpandLess : ExpandMore}
                />{' '}
                { }
              </Grid>
            </Grid>
          </ListItem>
        </div>

        <Collapse in={openFormConclusionReport} timeout="auto" unmountOnExit>
          <div className={classes.field}>
            <FormConclusionReport space={space} />
          </div>
        </Collapse>
      </List>
    </div>
  );
}
