import React from 'react';
import { useFormContext } from 'react-hook-form';

import ToggleComponent from '~/components/molecules/ToggleComponent';
import Field from '~/components/organisms/GenericFormWrapper/Field';
import { TextPrimary } from '~/utils/forms';
import i18n from '~/I18n';

import { useStyles } from './styles';
import { Grid, Switch } from '@material-ui/core';

function LectureDependency() {
  const classes = useStyles();
  const { control, watch } = useFormContext();

  return (
    <ToggleComponent name="lecture_dependency" label={`Dependência de ${i18n.t('StructuresName.Lectures.PluralLower')}`}>
      <Grid container>
        <Grid item xs={12} className={classes.switch}>
          <Field
            label={TextPrimary(`Ativar dependência de ${i18n.t('StructuresName.Lectures.PluralLower')}`)}
            labelPlacement={'end'}
            name="lecture_dependency"
            control={control}
            checked={watch('lecture_dependency')}
            Component={Switch}
            size="small"
          />
        </Grid>
      </Grid>
    </ToggleComponent>
  );
}

export default LectureDependency;
