import React from 'react';

import AdvancedOptions from './AdvancedOptions';
import Content from './Content';
import Informations from './Informations';
import MainInfo from './MainInfo';
import Section from './Section';
import { useStyles } from './styles';

export default function EditMirroredSubjectForm() {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <MainInfo />
      <Section title="Informações" Content={Informations} />
      <Section title="Conteúdo" Content={Content} />
      <Section title="Opções Avançadas" Content={AdvancedOptions} />
    </div>
  );
}
