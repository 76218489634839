import React from 'react';
import { MdOutlineFolderCopy } from 'react-icons/md';

import Description from '../Description';
import { useStyles } from './styles';
import {
  Box,
  DialogContent,
  DialogActions,
  Grid,
  RadioGroup,
  Radio,
  Button,
  Typography
} from '@material-ui/core';
import FilterNoneIcon from '@material-ui/icons/FilterNone';

export default function TypeSelection({ texts, setValue }) {
  const classes = useStyles();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Box className={classes.box}>
      <Box>
        <Typography className={classes.content} variant="body1" paragraph>
          Selecione qual o método que deseja utilizar:
        </Typography>

        <RadioGroup name="replication-method" onChange={handleChange}>
          <Grid container alignItems="flex-start">
            <Grid item>
              <Radio value="duplication" />
            </Grid>
            <Grid item xs>
              <Description
                label="Duplicação"
                text={texts.duplication.description}
                Icon={FilterNoneIcon}
              />
            </Grid>
          </Grid>

          <Grid container alignItems="flex-start">
            <Grid item>
              <Radio value="mirroring" />
            </Grid>
            <Grid item xs>
              <Description
                label="Espelhamento"
                text={texts.mirroring.description}
                Icon={MdOutlineFolderCopy}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </Box>
    </Box>
  );
}
