import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';

import { ArrowUpwardIcon } from '~/components/atoms/Icons';
import Text from '~/components/atoms/Text';
import AlertsDialog from '~/components/molecules/AlertsDialog';
import MuralItem from '~/components/molecules/MuralItem';
import TextForm from '~/components/Mural/TextForm';
import i18n from '~/I18n';
import api from '~/services/api';
import {
  createMuralListener,
  perPage,
  sendToMuralListener,
  handleAddNewStatusMural,
  handleGetStatuses
} from '~/services/mural';
import { Creators as SpacesCreators } from '~/store/ducks/spaces';

import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

const Status = ({ item, lecture, muralRef }) => {
  const classes = useStyles();
  const [deleted, setDeleted] = useState(false);

  return (
    <div className={`${classes.muralItem} ${deleted && 'animation'}`}>
      <MuralItem
        muralRef={muralRef}
        item={item}
        lecture={lecture}
        setDeleted={setDeleted}
        deleted={deleted}
      />
    </div>
  );
};

Status.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string
  }).isRequired,
  lecture: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  muralRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]).isRequired
};

function LectureMural({ lecture }) {
  const classes = useStyles();
  const [hasMore, setHasMore] = useState(true);
  const [loadingComments, setLoadingComments] = useState(true);
  const [status, setStatus] = useState([]);
  const [newData, setNewData] = useState([]);
  const [channel, setChannel] = useState('');
  const [offset, setOffset] = useState(0);
  const lectureId = lecture && lecture.id;
  const [newStatusCount, setNewStatusCount] = useState(0);
  const [hasNewStatus, setHasNewStatus] = useState(false);
  const [wasMounted, setWasMounted] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const dispatch = useDispatch();
  const { handleClose } = SpacesCreators;
  const { item } = useSelector((state) => state.spaces);
  const { deletedCount } = useSelector((state) => state.lectures);

  const [proceed, setProceed] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const store = useStore();
  const muralRef = useRef(null);

  async function getStatuses() {
    setLoadingComments(true);
    try {
      const response = await handleGetStatuses(lectureId, perPage, offset);

      const statusWithoutAnswerType = response.data.filter(
        (item) => item.type !== 'Answer'
      );

      setHasMore(statusWithoutAnswerType.length === perPage);
      setStatus((prev) => [...prev, ...statusWithoutAnswerType]);
      setOffset((prev) => prev + statusWithoutAnswerType.length);
      setLoadingComments(false);
    } catch (error) {
      setLoadingComments(false);
    }
  }

  async function addNewStatusMural(text) {
    handleAddNewStatusMural(lectureId, text)
      .then((res) => {
        if (!lecture.private_mural) {
          sendToMuralListener(channel, {
            content: res.data,
            type: 'TYPE_COMMENT_POSTED'
          });
        } else {
          setStatus((prev) => [res.data, ...prev]);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          enqueueSnackbar(error.response.data.message, {
            variant: 'error'
          });
        }
      });
  }

  useEffect(() => {
    if (channel !== '') {
      createMuralListener(channel, (data) => {
        const content = data.content;
        if (data.type === 'TYPE_COMMENT_POSTED') {
          if (wasMounted) {
            setWasMounted(false);
          } else {
            const currentUserId = store.getState().user.profile.id;
            const statusUserId = content.user.id;

            if (currentUserId === statusUserId) {
              setStatus((prev) => {
                const contactStatus = [content, ...prev];
                setOffset(contactStatus.length);
                return contactStatus;
              });
              const currentScroller = muralRef && muralRef.current;
              currentScroller &&
                currentScroller.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
              setHasNewStatus(true);
              setNewStatusCount((prev) => prev + 1);
              setNewData((prev) => [content, ...prev]);
            }
          }
        } else {
          setOffset((prev) => {
            return prev - 1;
          });
        }
      });
    }
  }, [channel]);

  //

  useEffect(() => {
    if (lectureId) {
      setIsMounted(true);
      getStatuses();
      setChannel(`channel-${lectureId}`);
    }
    return () => {
      setIsMounted(false);
      setWasMounted(false);
      // setStatus([]);
      setHasNewStatus(false);
      setOffset(0);
      setNewData([]);
      setChannel('');
      setNewStatusCount(0);
      setLoadingComments(true);
      setHasMore(false);
    };
  }, [lectureId]);

  const onNewStatusClick = () => {
    const filterDeletedItems = status.filter(
      (item) => !deletedCount.includes(item.id)
    );
    let slicedStatus;
    if (status.length > 4) slicedStatus = filterDeletedItems.slice(0, 4);
    else slicedStatus = filterDeletedItems;

    const contactNewStatus = [...newData, ...slicedStatus];
    setStatus(contactNewStatus);
    setNewStatusCount(0);
    setHasNewStatus(false);
    const concatCount = contactNewStatus.length;
    setOffset(concatCount);
    setNewData([]);
    muralRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const deleteItem = () => {
    if (item) {
      // dispara o event next no caso do usuário deletar todos os itens da lista
      if (item.type !== 'Answer') {
        // muralRef && muralRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        setProceed(true);
        api
          .delete(`/api/statuses/${item.id}`)
          .then(async () => {
            const newStatus = status.filter((arrayItem) => {
              if (arrayItem.id !== item.id) return arrayItem;
            });
            setProceed(false);
            setStatus(newStatus);
            setOffset(newStatus.length);
            dispatch(handleClose());
            enqueueSnackbar(`Comentário excluído!`, {
              variant: 'success'
            });
            sendToMuralListener(channel, {
              content: 'deletar',
              type: 'TYPE_COMMENT_DELETED'
            });
          })
          .catch(() => {
            setProceed(false);
            dispatch(handleClose());
            enqueueSnackbar(`Ocorreu um erro ao tentar excluir o comentário!`, {
              variant: 'error'
            });
          });
      } else {
        setProceed(true);
        api
          .delete(`/api/statuses/${item.id}`)
          .then(async () => {
            setProceed(false);
            dispatch(handleClose());
            enqueueSnackbar(`Item: ${item.text}, excluído!`, {
              variant: 'success'
            });
          })
          .catch(() => {
            setProceed(false);
            dispatch(handleClose());
            enqueueSnackbar(
              `Ocorreu um erro ao tentar excluir o item: ${item.text}!`,
              { variant: 'error' }
            );
          });
      }
    }
  };

  return (
    isMounted && (
      <Box className={classes.root}>
        <AlertsDialog handleProceed={deleteItem} proceed={proceed} />

        <Box className={classes.titleAndForm}>
          <Box className={classes.title}>
            <Text color="primary" variant="lg">
              {i18n.t('Wall')}
            </Text>
          </Box>
          {status ? (
            <TextForm lecture addNewStatusMural={addNewStatusMural} />
          ) : (
            <Skeleton height={67} width="100%" />
          )}
        </Box>

        <div
          id="comment__view"
          ref={muralRef}
          className={classes.comment__view}
        >
          <div className={classes.hasNewStatus}>
            {hasNewStatus && (
              <div
                onClick={onNewStatusClick}
                className={classes.hasNewStatus__button}
              >
                <ArrowUpwardIcon className={classes.customIcon} /> Novos
                comentários {`(${newStatusCount})`}
              </div>
            )}
          </div>
          {loadingComments}
          {status.map((currentStatus) => {
            return (
              <Status
                muralRef={muralRef}
                key={currentStatus.id}
                item={currentStatus}
                lecture={lecture}
              />
            );
          })}
          <div className={classes.getMoreButtom}>
            {hasMore && (
              <div
                className={classes.getMoreButtom__button}
                onClick={getStatuses}
              >
                {!loadingComments && (
                  <ArrowUpwardIcon className={classes.customIcon__rotate} />
                )}
                {loadingComments ? 'Carregando...' : 'Mais comentários'}
              </div>
            )}
          </div>
        </div>
      </Box>
    )
  );
}

LectureMural.propTypes = {
  lecture: PropTypes.shape({
    id: PropTypes.number.isRequired
  })
};

LectureMural.defaultProps = {
  lecture: {}
};

export default LectureMural;
