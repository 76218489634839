import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';

import ImageWelcome from './ImageWelcome';
import { TextInfo, CloseButton, CloseIcon, useStyles } from './styles';
import { Box } from '@material-ui/core';

export default function Welcome() {
  const { profile } = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    if (profile !== null && profile.login_count === 1) {
      setShow(true);
    }
  }, [profile]);

  return (
    show &&
    profile && (
      <Box className={classes.welcomeContainer}>
        <Box className={classes.infoContainer}>
          <Text variant="welcomeTitle" color="primary">
            {`${i18n.t('Welcome')}, ${profile?.first_name || ''}!`}
          </Text>
          <Box className={classes.welcomeText}>
            <TextInfo>
              {/* O
               {' '}
               <TextHighlighted>SebraeEdu</TextHighlighted>
               {' '}
               é uma plataforma online que vai lhe ajudar a aprender de forma fácil e rápida. E o melhor, tudo isso sem sair de casa! */}
              {i18n.t('WelcomeMessage')}
            </TextInfo>
          </Box>
        </Box>
        <Box className={classes.imageContainer}>
          <ImageWelcome />
        </Box>
        <Box className={classes.closeIcon}>
          <CloseButton
            onClick={() => {
              setShow(false);
            }}
          >
            <CloseIcon />
          </CloseButton>
        </Box>
      </Box>
    )
  );
}
