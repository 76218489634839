import React, { useMemo } from 'react';

import AvatarProfile from '~/components/atoms/Avatar';
import i18n from '~/I18n';

import { useStyles } from './styles';
import { Box, Typography, useTheme } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

const roleDict = {
  admin: i18n.t('Admin'),
  moderator: i18n.t('Moderator'),
  member: i18n.t('Student'),
  environment_admin: i18n.t('Environment_admin'),
  course_admin: i18n.t('Course_admin'),
  teacher: i18n.t('Teacher'),
  tutor: i18n.t('Tutor')
};

export default function MemberItem({
  userData,
  selectedMembersList,
  setSelectedMembersList
}) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const isSelected = useMemo(
    () => selectedMembersList.some((item) => item.id === userData.id),
    [selectedMembersList, userData.id]
  );

  const handleClick = () => {
    setSelectedMembersList((prev) =>
      isSelected
        ? prev.filter((item) => item.id !== userData.id)
        : [...prev, userData]
    );
  };

  return (
    <Box
      className={`${classes.container} ${isSelected &&
        classes.containerActivated}`}
      onClick={handleClick}
    >
      <Box className={classes.memberInfo}>
        <AvatarProfile size={56} profile={userData?.user} />
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <Typography
            className={`${classes.userName} ${isSelected &&
              classes.userNameHover}`}
          >
            {`${userData?.user?.first_name} ${userData?.user?.last_name}`}
          </Typography>
          <Typography
            className={`${classes.userRole} ${isSelected &&
              classes.userRoleHover}`}
          >
            {roleDict[userData.role]}
          </Typography>
        </Box>
      </Box>
      <Checkbox
        className={`${classes.customCheckbox} ${isSelected &&
          classes.customCheckboxHover}`}
        color="default"
        checked={isSelected}
        disableRipple
      />
    </Box>
  );
}

const userDataPropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string
  }).isRequired,
  role: PropTypes.string.isRequired
});

MemberItem.propTypes = {
  userData: userDataPropTypes.isRequired,
  selectedMembersList: PropTypes.arrayOf(userDataPropTypes).isRequired,
  setSelectedMembersList: PropTypes.func.isRequired
};
