import React from 'react';

import { Box } from '@material-ui/core';
import Proptypes from 'prop-types';

import Text from '~/components/atoms/Text';

import { useStyles } from './styles';

const TextBox = ({ title, children, isListItem }) => {
  const classes = useStyles({ isListItem });
  return (
    <Box className={classes.root}>
      <Text color="gray" variant="text">
        <Text
          color="black"
          variant="text"
          style={{ letterSpacing: 1, fontWeight: 500 }}
        >
          {title}
        </Text>
        {children}
      </Text>
    </Box>
  );
};

TextBox.propTypes = {
  title: Proptypes.string,
  children: Proptypes.string,
  isListItem: Proptypes.bool
};

TextBox.defaultProps = {
  title: '',
  children: '',
  isListItem: false
};

export default TextBox;
