import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import IframeModal from '~/components/organisms/IframeModal';
import { IframeList } from '~/components/organisms/LectureForm/Lectureable/Forms/Multimedia/IframeList';
import simpleUploadBuilder from '~/utils/ckeditor/plugins/simpleUpload';

import { useStyles } from './styles';
import Editor from 'ckeditor5-custom-viitra-build/build/ckeditor';
import { Iframe } from 'src/components/organisms/LectureForm/Lectureable/Forms/Multimedia/IframeList/IframeItem';

const editableStyles = {
  padding: '30px 20px',
  border: '1px solid #c4c4c4',
  borderTop: 'transparent'
};

export default function Multimedia({ onChange, ...rest }) {
  const classes = useStyles();
  const { watch, setValue } = useFormContext();
  const { auth } = useSelector((state) => state);

  const [data, setData] = useState(watch('lectureable.multimedia.body') ?? '');
  const [iframes, setIFrames] = useState([]);
  const [editor, setEditor] = useState(null);
  const [imageTrack, setImageTrack] = useState({ loaded: [], removed: [] });

  const [iframeModalOpen, setIframeModalOpen] = useState(false);
  const [currentIframe, setCurrentIframe] = useState(null);

  const parseIframes = (editor) => {
    let iframes = [];

    const el = document.createElement('body');
    el.innerHTML = data;

    const iframesElements = el.querySelectorAll('iframe');
    iframesElements.forEach((iframe, index) => {
      const body = iframe.parentElement.cloneNode(true).outerHTML;
      iframes = [...iframes, { body: body, position: index + 1 }];
      iframe.outerHTML = `<h2>IFRAME${index + 1}</h2>`;
    });

    editor.setData(el.innerHTML);
    setIFrames(iframes);
  };

  const simpleUpload = simpleUploadBuilder(watch('subjectID'), auth.token);
  const editorConfig = {
    toolbar: {
      shouldNotGroupWhenFull: true
    },
    htmlSupport: {
      allow: [
        {
          name: 'iframe',
          attributes: true,
          classes: true,
          styles: true
        }
      ]
    },
    simpleUpload
  };

  const handleDeleteIframe = (iframe) => {
    const content = document.createElement('body');
    content.innerHTML = editor.getData();

    const placeholderElements = content.querySelectorAll('h2');
    placeholderElements.forEach((placeholder) => {
      if (placeholder.textContent.includes('IFRAME')) {
        const position = parseInt(
          placeholder.textContent.replace('IFRAME', '')
        );

        if (iframe.position === position) {
          placeholder.remove();
          editor.setData(content.innerHTML);
          setIFrames([...iframes.filter((item) => item !== iframe)]);
          return;
        }
      }
    });
  };

  const updateImageTrack = (content) => {
    const doc = new DOMParser().parseFromString(content, 'text/html');
    const imagesInEditor = [...doc.querySelectorAll('img')].map(
      (img) => img.src
    );

    setImageTrack((prevTrack) => {
      const newLoaded = imagesInEditor.filter(
        (src) => src && src.trim() !== '' && !prevTrack.loaded.includes(src)
      );

      const newRemoved = prevTrack.loaded.filter(
        (src) => !imagesInEditor.includes(src)
      );

      return {
        loaded: [...prevTrack.loaded, ...newLoaded].filter(
          (src) => !newRemoved.includes(src)
        ),
        removed: [...prevTrack.removed, ...newRemoved],
      };
    });
  };

  const initEditor = (editor) => {
    const toolbarContainer = document.querySelector(
      '#document-editor__toolbar'
    );
    toolbarContainer.appendChild(editor.ui.view.toolbar.element);
    editor.setData(data);
    setEditor(editor);

    editor.model.document.on('change:data', () => {
      const currentData = editor.getData();
      updateImageTrack(currentData);
      setData(currentData);
    });

    parseIframes(editor);
    const toolbarItemSpace = document.querySelector('.ck-toolbar__items');

    const separatorBar = document.querySelector('.ck-toolbar__separator');
    toolbarItemSpace.appendChild(separatorBar);

    const iframeButton = document.querySelector('.fa-laptop-code');
    toolbarItemSpace.appendChild(iframeButton);

    const styledIframeButton = document.querySelectorAll('.fa-laptop-code');
    styledIframeButton.forEach((item) => {
      item.style.width = '30px';
      item.style.paddingLeft = '10px';
      item.style.cursor = 'pointer';
    });
  };

  const queryCkEditorClassName = () => {
    var elements = document.getElementsByClassName('ck-balloon-panel');
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.zIndex = 1400;
    }
  };

  useEffect(() => {
    setValue('lectureable.multimedia.content', data);
  }, [data]);

  useEffect(() => {
    setValue('lectureable.multimedia.iframes', iframes);
  }, [iframes]);

  useEffect(() => {
    Editor.create(
      document.querySelector('#document-editor__editable'),
      editorConfig
    )
      .then(initEditor)
      .catch(console.log);
  }, []);

  useEffect(() => {
    setValue('imageTrack', imageTrack);
  }, [imageTrack, setValue]);

  useEffect(() => {}, [iframes]);

  return (
    <div>
      <div
        onClick={() => queryCkEditorClassName()}
        id="document-editor__toolbar"
      ></div>
      <div
        id="document-editor__editable"
        style={editableStyles}
        className={classes.editableStyles}
      ></div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItens: 'center'
        }}
      >
        <IframeModal
          data={data}
          setData={setData}
          editor={editor}
          iframes={iframes}
          setIFrames={setIFrames}
          open={iframeModalOpen}
          setOpen={setIframeModalOpen}
          currentIframe={currentIframe}
          setCurrentIframe={setCurrentIframe}
        />
        <IframeList
          iframes={iframes}
          setOpenModal={setIframeModalOpen}
          setCurrentIframe={setCurrentIframe}
          handleDeleteIframe={handleDeleteIframe}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0, 0.4)',
            color: '#ffff',
            widht: '250px',
            height: '25px',
            marginTop: '10px',
            marginLeft: '20px',
            borderRadius: '5px'
          }}
        ></div>
      </div>
    </div>
  );
}
