import React from 'react';
import Text from '~/components/atoms/Text';
import { MdFolderCopy } from 'react-icons/md';
import { Box, useTheme } from '@material-ui/core';
import { useStyles } from './styles';

export default function MirroredFlag({ type }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const text =
    type !== 'course' && type !== 'environment' ? 'ESPELHADA' : 'ESPELHADO';

  return (
    <Box
      className={classes.container}
      style={{ backgroundColor: getBackgroundColor(theme, type) }}
    >
      <MdFolderCopy className={classes.icon} color="primary" />
      <Text
        color="primary"
        className={classes.text}
        style={{ fontWeight: 600 }}
      >
        {text}
      </Text>
    </Box>
  );
}

function getBackgroundColor(theme, type) {
  const opacity = type === 'lecture' ? 0.8 : 0.4;
  const cleanHex = theme.palette.secondary.main.replace('#', '');
  const [red, green, blue] = cleanHex
    .match(/.{1,2}/g)
    .map((channel) => parseInt(channel, 16));
  return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
}
