import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  openModalButton: {
    marginTop: 20,
    fontSize: 15,
    color: theme.palette.primary.main,
    fontWeight: 12,
    transitionDuration: '0.3s',
    '&:hover': {
      color: 'rgba(0,0,0, 0.5)'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid gray',
    padding: 5,
    width: '50%',
    height: 500,
    borderRadius: 5
  },
  header: {
    marginTop: -40,
    marginBottom: 20,
    paddingBottom: 5
  },
  searchInput: {
    background: 'rgba(0, 0, 0, 0.04)',
    width: 400,
    height: 40,
    borderRadius: 5,
    border: '1px solid',
    borderColor: theme.palette.secondary.main
  },
  scrollableArea: {
    height: 300,
    width: '90%',
    overflowX: 'hidden',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.secondary.main
    }
  },
  itens: {
    width: '100%'
  },
  closeModal: {
    display: 'flex',
    position: 'relative',
    bottom: '10%',
    left: '44%',
    fontSize: 17,
    color: 'gray',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}));
