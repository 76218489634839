import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import StructureSelection from './StructureSelection';
import { useStyles } from './styles';
import TypeSelection from './TypeSelection';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Typography
} from '@material-ui/core';
export default function CreateReplica({
  open,
  setOpenReplicationModal,
  structure,
  structureName
}) {
  const classes = useStyles();
  const [renderComponent, setRenderComponent] = useState(1);
  const [value, setValue] = useState(null);
  const [defaultValues, setDefaultValues] = useState(null);
  const [resolver, setResolver] = useState({});
  const [handleSubmission, setHandleSubmission] = useState(() => {});

  const setFormConfig = (values, validations) => {
    setDefaultValues(values);
    setResolver(validations);
  };

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: resolver ? yupResolver(resolver) : null
  });

  const texts = {
    mirroring: {
      description:
        'Será criada uma cópia perfeita desta estrutura e ela permanecerá conectada à estrutura original. Isso significa que toda vez que algo novo for adicionado à estrutura original, essa cópia também será automaticamente atualizada com essas mudanças.',
      processing: 'O processo de espelhamento está ocorrendo em segundo plano',
      success: 'O espelhamento está em andamento. Por favor, aguarde'
    },
    duplication: {
      description:
        'Será criada uma cópia idêntica dessa estrutura, porém ela permanecerá independente, sem qualquer vínculo ou conexão com a original. Caso uma estrutura espelhada seja selecionada para essa duplicação, a cópia será gerada a partir da original.',
      processing: 'O processo de duplicação está ocorrendo em segundo plano',
      success: 'A duplicação está em andamento. Por favor, aguarde'
    }
  };

  const handleClose = () => {
    methods.reset();
    setOpenReplicationModal(false);
    setRenderComponent(1);
  };

  const handleConfirm = () => {
    setRenderComponent(2);
  };

  const onSubmit = async (data) => {
    if (renderComponent === 2) {
      handleClose();
      handleSubmission(data);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.dialogPaper }}
    >
      <FormProvider {...methods}>
        <Box className={classes.outterBox}>
          <Box className={classes.title}>
            <Typography variant="h6" className={classes.title}>
              Replicar {structureName}
            </Typography>
          </Box>
          {renderComponent === 1 ? (
            <TypeSelection texts={texts} setValue={setValue} />
          ) : (
            <StructureSelection
              structureName={structureName}
              structure={structure}
              type={value}
              texts={texts}
              setFormConfig={setFormConfig}
              setSubmission={setHandleSubmission}
            />
          )}
          <DialogActions>
            <Button
              onClick={handleClose}
              className={classes.button}
              color="primary"
            >
              Cancelar
            </Button>
            <Button
              onClick={
                renderComponent === 1
                  ? handleConfirm
                  : methods.handleSubmit(onSubmit)
              }
              className={classes.button}
              color="primary"
              variant="contained"
              type={renderComponent === 1 ? 'button' : 'submit'}
            >
              Replicar
            </Button>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  );
}
