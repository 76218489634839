import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px'
  },
  textHeader: {
    fontSize: 24,
    color: '#0056B4',
    fontWeight: 500
  },
  exitButton: {
    fontSize: 28,
    cursor: 'pointer',
    color: '#676d82'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    marginTop: '8px'
  },
  cancelButton: {
    display: 'flex',
    padding: '12px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#0056B4',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    borderRadius: '4px',
    '&:hover': {
      color: '#1A72F9',
      backgroundColor: '#F5F5F5'
    },
    '&:active': {
      color: '#0056B4',
      backgroundColor: '#E1E1E5'
    }
  },
  addButton: {
    width: '115px',
    display: 'flex',
    padding: '12px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    background: '#CB410B',
    color: 'white',
    fontSize: '16px',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#F34D0C'
    },
    '&:active': {
      backgroundColor: '#AB3000'
    }
  }
}));
