import React, { useState } from 'react';
import { IoMdTrash } from 'react-icons/io';

import ConfirmationDialog from '~/components/molecules/ConfirmationDialog';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
export default function DeleteConfirmationModal({
  selectedMembersList,
  deleteMembersService,
  structureID,
  setSelectedMembersList,
  loadMembersDetails,
  showDeleteConfirmationModal,
  closeDeleteConfirmationModal
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingDeletionMembers, setLoadingDeletionMembers] = useState(false);

  const deleteModalMessage = `Tem certeza que deseja remover ${
    selectedMembersList.length
  } ${selectedMembersList.length > 1 ? 'membros' : 'membro'} desta estrutura?`;

  const handleRequestFeedback = (message, variant) => {
    enqueueSnackbar(message, {
      variant
    });
    setSelectedMembersList([]);
    closeDeleteConfirmationModal();
  };

  const handleConfirmDeleteModal = async () => {
    if (loadingDeletionMembers) return;
    setLoadingDeletionMembers(true);
    try {
      await deleteMembersService(
        structureID,
        selectedMembersList.map((member) => member.id)
      );
      const resetMembersList = true;
      loadMembersDetails(resetMembersList);
      handleRequestFeedback(
        `${selectedMembersList.length} ${
          selectedMembersList.length > 1
            ? 'membros foram removidos'
            : 'membro foi removido'
        } desta estrutura`,
        'success'
      );
    } catch (e) {
      handleRequestFeedback(
        `Não foi possivel remover da estrutura
            ${
              selectedMembersList.length > 1
                ? 'os membros selecionados'
                : 'o membro selecionado'
            }`,
        'error'
      );
    }
    setLoadingDeletionMembers(false);
  };

  return (
    <ConfirmationDialog
      show={showDeleteConfirmationModal}
      handleClose={closeDeleteConfirmationModal}
      message={deleteModalMessage}
      handleConfirm={handleConfirmDeleteModal}
      loadingConfirmButton={loadingDeletionMembers}
      confirmButtonText="Remover"
      titleText="Remover Membros"
      titleIcon={<IoMdTrash style={{ fontSize: '32px', color: '#CB410B' }} />}
    />
  );
}

DeleteConfirmationModal.propTypes = {
  selectedMembersList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ).isRequired,
  deleteMembersService: PropTypes.func.isRequired,
  structureID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  setSelectedMembersList: PropTypes.func.isRequired,
  loadMembersDetails: PropTypes.func.isRequired,
  showDeleteConfirmationModal: PropTypes.bool.isRequired,
  closeDeleteConfirmationModal: PropTypes.func.isRequired
};
