import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactLoading from 'react-loading';

import Text from '~/components/atoms/Text';
import PaginatableSelect from '~/components/molecules/PaginatableSelect';
import i18n from '~/I18n';
import {
  getNotMirroredEnvironments,
  getCloneableEnvironments
} from '~/services/replications/environments';

import ShowSelectedEnvironment from './ShowSelectedEnvironment';
import { useStyles } from './styles';
import { Collapse, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export default function ReplicatedEnvironmentForm({ isMirroring }) {
  const theme = useTheme();
  const classes = useStyles();

  const { setValue } = useFormContext();
  const [selectValue, setSelectValue] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [environments, setEnvironments] = useState([]);

  const [selectedEnvironment, setSelectedEnvironment] = useState(null);

  const handleSuccess = (environments) => {
    setEnvironments(environments);
    setIsLoading(false);
  };

  const handleFailure = () => {
    setIsLoading(false);
  };

  const fetchEnvironments = isMirroring
    ? getNotMirroredEnvironments
    : getCloneableEnvironments;

  useEffect(() => {
    setIsLoading(true);

    fetchEnvironments()
      .then(handleSuccess)
      .catch(handleFailure);
  }, []);

  const handleChange = (event) => {
    setValue('environmentID', event.target.value);
    setSelectValue(event.target.value);
    setSelectedEnvironment(
      environments.find((x) => x.id == event.target.value)
    );
  };

  return (
    <div className={classes.content}>
      {isLoading ? (
        <Grid container justifyContent="center">
          <ReactLoading type="bars" color={`${theme.palette.primary.main}`} />
        </Grid>
      ) : environments.length > 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PaginatableSelect
              value={selectValue}
              onChange={handleChange}
              label={`Selecione um ${i18n.t(
                'StructuresName.Environment.SingularUpper'
              )}`}
              paginatableFunction={fetchEnvironments}
              objectList={environments}
              setObjectList={setEnvironments}
              labelAttribute={'name'}
              valueAttribute={'id'}
            />
          </Grid>

          <Grid item xs={12}>
            {selectedEnvironment && (
              <Collapse in={selectedEnvironment}>
                <ShowSelectedEnvironment
                  selectedEnvironment={selectedEnvironment}
                />
              </Collapse>
            )}
          </Grid>
        </Grid>
      ) : (
        <Text color="primary">
          Infelizmente não encontramos nenhum{' '}
          {`${i18n.t('StructuresName.Environment.SingularUpper')}`} em que você
          seja o proprietário.
        </Text>
      )}
    </div>
  );
}
