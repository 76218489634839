import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Box } from '@material-ui/core';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import settings from '~settings';

import ButtonSquare from '../atoms/ButtonSquare';
import { Container, Title, BadgesList, BadgesItem } from './styles';

export default function Badges() {
  const user = useSelector((state) => state.user);
  const { profile } = user && user;
  const { badges } = user && user;
  const history = useHistory();
  const targetRoute = {
    pathname: profile ? `/profile/${profile.id}` : '#',
    state: {
      targetSection: 'conquests'
    }
  };

  return (
    <Container>
      <Text variant="text" color="white" style={{ paddingBottom: 15 }}>
        {i18n.t('RecentAchievements')}
      </Text>
      <BadgesList>
        {badges ? (
          Object.values(badges).map((item, index) => {
            const { badge } = item[item.length - 1];
            const image = badge.custom_fields.badge;
            if (index < 6)
              return (
                <BadgesItem key={badge.id} title={badge.description}>
                  <div>
                    <img
                      src={`${settings.baseUrl}${image}`}
                      alt={badge.custom_fields.category}
                    />
                  </div>
                </BadgesItem>
              );
          })
        ) : (
          <Text variant="text" color="white">
            Não há conquistas
          </Text>
        )}
      </BadgesList>
      <Box display="flex" justifyContent="flex-end">
        <ButtonSquare
          noCapitalize
          padding="4px 0px"
          onClick={() => history.push(targetRoute)}
          style={{ display: 'flex', justifyContent: 'flex-end !important' }}
        >
          <Text variant="text" color="white">
            {i18n.t('ShowAll')}
          </Text>
        </ButtonSquare>
      </Box>
    </Container>
  );
}
