/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import DeleteConfirmModal from '~/components/molecules/DeleteConfirmModal';
import EditMirroredSpaceForm from '~/components/organisms/EditMirroredSpaceForm';
import GenericFormWrapper from '~/components/organisms/GenericFormWrapper';
import MembersManagement from '~/components/organisms/MembersManagement';
import SpaceReports from '~/components/organisms/SpaceReports';
import StepperForm from '~/components/templates/StepperForm';
import i18n from '~/I18n';
import SpaceService from '~/services/Space';
import spaceServices from '~/services/Space';
import userSpaceAssociationServices from '~/services/user-space-association';
import { handleErrors, handleSuccess } from '~/utils/auxiliaryFunctions';

import {
  getMirroredSpace,
  updateMirroredSpace,
  deleteMirroredSpace
} from './fetch';
import { useSnackbar } from 'notistack';

export default function EditMirroredSpace() {
  const { mirroredSpaceID } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [, setSubmissionStatus] = useState('');
  const [reportURLs, setReportURLs] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [parentSpaceID, setParentSpaceID] = useState(null);
  const [parentCourseID, setParentCourseID] = useState(null);
  const [parentEnvironmentID, setParentEnvriparentEnvironmentID] = useState(
    null
  );
  const [parentSpace, setParentSpace] = useState(null);
  const openDeleteModal = () => setShowDeleteModal(true);
  const closeDeleteModal = () => setShowDeleteModal(false);
  const [defaultValues, setDefaultValues] = useState({
    toggle: {
      name: null,
      initials: null,
      turn: null,
      description: null,
      dependent_subjects: null,
      order_subjects_by: null
    },
    name: null,
    initials: null,
    turn: null,
    description: null,
    dependent_subjects: null,
    order_subjects_by: null,
    parent: {
      id: null
    }
  });

  const successMessage = `A ${i18n.t(
    'StructuresName.Space.SingularLower'
  )} foi atualizada!`;
  const callbackSuccessPath = `/dashboard/spaces/${parentSpaceID}`;

  const breadcrumbs = [
    { name: i18n.t('MyEnvironments'), path: '/dashboard/environments' }
  ];

  const navOptions = [
    i18n.t('Information'),
    i18n.t('Reports'),
    i18n.t('Members')
  ];

  const handleExclude = () => {
    openDeleteModal();
  };

  const deleteMessage = () => {
    const message = (
      <>
        A {i18n.t('StructuresName.Space.SingularLower')}{' '}
        <span style={{ fontWeight: 'bold' }}>{defaultValues.name}</span>, que é
        um espelho da {i18n.t('StructuresName.Space.SingularLower')} "
        {defaultValues.name}", será permanentemente excluída, incluindo todos os
        dados e arquivos. Todos os membros irão perder o acesso. A{' '}
        {i18n.t('StructuresName.Space.SingularLower')} original não será
        afetada.
      </>
    );
    return message;
  };

  const handleDeleteConfirm = () => {
    deleteMirroredSpace(mirroredSpaceID)
      .then(() => {
        enqueueSnackbar(
          `${i18n.t(
            'StructuresName.Space.SingularUpper'
          )} excluída com sucesso!`,
          { variant: 'success' }
        );

        history.push(
          `/dashboard/environments/${parentEnvironmentID}/courses/${parentCourseID}`
        );
      })
      .catch(() => {
        enqueueSnackbar(
          `Erro ao excluir ${i18n.t('StructuresName.Space.SingularLower')}.`,
          { variant: 'error' }
        );
      })
      .finally(() => {
        closeDeleteModal();
      });
  };

  const renderContent = (props) => {
    const { selectedTab } = props;
    if (selectedTab === 0) {
      return (
        <GenericFormWrapper
          Form={EditMirroredSpaceForm}
          defaultValues={defaultValues}
          handleExclude={handleExclude}
          excludeTitle="Excluir"
          {...props}
        />
      );
    } else if (selectedTab === 1) {
      return <SpaceReports space={parentSpace} />;
    } else if (selectedTab === 2) {
      return (
        <MembersManagement
          structureID={defaultValues.parent.id}
          structureServices={spaceServices}
          userStructureAssociationServices={userSpaceAssociationServices}
          reportURLs={reportURLs}
        />
      );
    }

    return false;
  };

  const configSpace = (data) => {
    setParentSpaceID(data.parent.id);
    setParentCourseID(data.parent.course_id);
    setParentEnvriparentEnvironmentID(data.parent.environment_id);
    setReportURLs(data.report_urls);
    setDefaultValues({
      toggle: {
        name: data.real.name,
        initials: data.real.initials,
        turn: data.real.turn,
        description: data.real.description,
        dependent_subjects: data.real.dependent_subjects,
        order_subjects_by: data.real.order_subjects_by
      },
      name: data.name,
      initials: data.initials,
      turn: data.turn,
      description: data.description,
      dependent_subjects: data.dependent_subjects,
      order_subjects_by: data.order_subjects_by,
      parent: {
        id: data.parent.id
      }
    });
  };

  const fetchFailure = () => {
    window.alert(
      `Não foi possível carregar a ${i18n.t(
        'StructuresName.Space.SingularUpper'
      )}!`
    );
    handleCancel();
  };

  useEffect(() => {
    if (mirroredSpaceID)
      getMirroredSpace(mirroredSpaceID)
        .then(configSpace)
        .catch(fetchFailure);
  }, [mirroredSpaceID]);

  useEffect(() => {
    if (parentSpaceID)
      SpaceService.findByID(parentSpaceID)
        .then((response) => setParentSpace(response))
        .catch(fetchFailure);
  }, [parentSpaceID]);

  const handleCancel = () => {
    history.push(`/dashboard/spaces/${parentSpaceID}`);
  };

  const finalizeSuccess = () => {
    setSubmissionStatus('success');
    handleSuccess(
      successMessage,
      enqueueSnackbar,
      callbackSuccessPath,
      history
    );
  };

  const finalizeFailure = (error) => {
    handleErrors(error, enqueueSnackbar);
    setSubmissionStatus('error');
  };

  function submitForm(fields) {
    const { toggle } = fields;

    let mirroredSpace = {};

    for (let k in toggle) {
      if (toggle[k]) {
        mirroredSpace[k] = fields[k];
      } else {
        mirroredSpace[k] = null;
      }
    }

    const body = { mirrored_space: { ...mirroredSpace } };

    updateMirroredSpace(mirroredSpaceID, body)
      .then(finalizeSuccess)
      .catch(finalizeFailure);
  }

  return (
    <>
      <StepperForm
        unclickable={false}
        breadcrumbs={breadcrumbs}
        navOptions={navOptions}
        onSubmit={submitForm}
        formTitle={i18n.t('EditSpace.title')}
        submitButtonTitle={i18n.t('EditSpace.saveButton')}
        handleCancel={handleCancel}
        renderContent={renderContent}
      />
      <DeleteConfirmModal
        show={showDeleteModal}
        handleClose={closeDeleteModal}
        handleConfirm={handleDeleteConfirm}
        maxWidth={440}
        loading={false}
        showButtons={true}
        descriptionMessage={deleteMessage()}
        title={i18n.t('DeleteMirroredSpace.title')}
      />
    </>
  );
}
