import api from '~/services/api';

export const createMirroredEnvironment = async (body) => {
  return await api.post(`/api/mirrored_environments`, body);
};

export const createClonedEnvironment = async (originalID) => {
  return await api.post(`/api/cloning/environments`, { original_id: originalID});
};

export const getNotMirroredEnvironments = async (page = 1) => {
  const { data } = await api.get(
    `/api/mirroring/environments/mirror_options?page=${page}`
  );
  if (data.length == 0) return null;
  return data;
};

export const getCloneableEnvironments = async (page = 1) => {
  const { data } = await api.get(
    `/api/cloning/environments/cloning_options?page=${page}`
  );
  if (data.length == 0) return null;
  return data;
};
