import React from 'react';
import { useLocation } from 'react-router-dom';

import MirroredFlag from '~/components/molecules/MirroredFlag';
import Text from '~/components/atoms/Text';
import {
  TextLecture,
  ExerciseIcon,
  VideoLecture,
  ApresentationLecture
} from '~/components/Icons/Lecture';
import history from '~/services/history';

import LecturePopUp from './LecturePopUp';
import { useStyles } from './styles';
import { Box, Tooltip, useTheme } from '@material-ui/core';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { MdLock, MdLockClock } from 'react-icons/md';
import PropTypes from 'prop-types';

export default function Lecture({ lecture, spaceName, spaceId, setLectures }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const {
    id,
    name,
    type,
    duration,
    can_read,
    subject_id: lectureSubjectId,
    expired: isBlock
  } = lecture;

  const location = useLocation();

  function renderIcon() {
    let Icon;
    let color = can_read ? 'white' : '#676D82';

    if (type === 'Seminar') Icon = VideoLecture;
    else if (type === 'Document') Icon = ApresentationLecture;
    else if (type === 'Exercise') Icon = ExerciseIcon;
    else if (type === 'Page') Icon = TextLecture;
    else if (type === 'Form')
      return (
        <AssignmentRoundedIcon style={{ fontSize: '60px', color: color }} />
      );
    else return null;

    return <Icon width={45} height={45} color={color} />;
  }
  const {
    environmentName,
    environmentPath,
    spaceName: spaceNameComing,
    spaceId: spaceIdComing
  } = location.state || {};

  function goToLecture() {
    history.push({
      pathname: `/dashboard/lecture/${id}`,
      state: {
        environmentPath: environmentPath,
        environmentName: environmentName || '...',
        spacePath: `/dashboard/spaces/${spaceId || spaceIdComing}`,
        spaceName: spaceName || spaceNameComing || '...',
        spaceId: spaceId || spaceIdComing,
        lectureSubjectId: lectureSubjectId
      }
    });
  }

  function shareContent(event) {
    event.stopPropagation();
  }

  function timeToString(time) {
    const timeTypeOf = typeof time === 'number';
    return timeTypeOf && (time < 10 ? `0${time}` : `${time}`);
  }

  function getFormattedDuration() {
    const hours = timeToString(parseInt(duration / 3600));
    const minutes = timeToString(parseInt((duration % 3600) / 60));
    const seconds = timeToString((duration % 3600) % 60);

    let format = '';
    if (hours > 0) format += `${hours}:`;
    format += `${minutes}:${seconds}`;
    return format;
  }

  return (
    <Box
      className={classes.lectureContainer}
      style={{ cursor: 'pointer' }}
      onClick={goToLecture}
    >
      <Box
        className={classes.iconContainer}
        style={{
          backgroundColor:
            isBlock || !can_read ? '#B4BDD3' : theme.palette.primary
        }}
      >
        {renderIcon()}
      </Box>
      <Box className={classes.lectureInfo}>
        <Box className={classes.iconsContainer}>
          {lecture.user_completion && (
            <Box className={classes.seenIcon}>
              <CheckCircleIcon />
            </Box>
          )}
          {!can_read && (
            <Box className={classes.lockIcon}>
              {isBlock ? <MdLockClock /> : <MdLock />}
            </Box>
          )}
          {/* [REFACTOR] Quando retira o box quebra o alinhamento do card da aula */}
          {/* {(lecture.can_manage || !lecture.user_completion) && (
            <Box className={classes.seenIcon} />
          )} */}
          {lecture.can_manage && (
            <>
              {lecture.is_mirror && <MirroredFlag type="lecture" />}
              <Box
                className={classes.infoButton}
                onClick={shareContent}
                style={{ position: 'absolute', right: '0px' }}
              >
                <LecturePopUp
                  lectureID={id}
                  setLectures={setLectures}
                  isMirror={lecture.is_mirror}
                  mirrorsCount={lecture.mirrors_count}
                  lectureName={lecture.name}
                />
              </Box>
            </>
          )}
        </Box>
        <Box className={classes.bottomRow}>
          <Box className={classes.titleContainer}>
            <Tooltip
              title={name.length > 25 ? name : ''}
              arrow
              placement="bottom"
              classes={{ tooltip: classes.customTooltip }}
            >
              <Box>
                <Text
                  variant="largeText"
                  style={{ color: can_read ? 'white' : '#676D82' }}
                >
                  {name}
                </Text>
              </Box>
            </Tooltip>
          </Box>
          {duration && (
            <Box className={classes.duration}>
              <Text variant="text" color="white">
                {getFormattedDuration()}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

Lecture.propTypes = {
  lecture: PropTypes.shape(),
  spaceName: PropTypes.string,
  spaceId: PropTypes.number,
  setLectures: PropTypes.func
};

Lecture.defaultProps = {
  lecture: {},
  spaceName: '',
  spaceId: 0,
  setLectures: async () => {}
};
