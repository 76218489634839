/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import { SettingsIcon, DeleteIcon } from '~/components/atoms/Icons';
import Text from '~/components/atoms/Text';
import ShareContentLecture from '~/components/organisms/ShareContents/ShareContentLectures';
import ShareContent from '~/components/organisms/ShareContents/ShareContentSubjects';
import i18n from '~/I18n';

import ConfirmModal from '../ConfirmModal';
import DeleteConfirmModal from '../DeleteConfirmModal';
import { deleteLecture } from './fetch';
import { useStyles } from './styles';
import { useTheme, MenuItem, Menu, Modal } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

export default function PopUpMenu ({
  linkList,
  showText,
  text,
  justOneLink,
  link,
  Icon,
  width,
  hasMore,
  wasDeleted,
  onDelete,
  isCourse,
  subjects,
  currentSubjectId,
  lecture,
  lectureID,
  setLectures,
  onClose,
  instance,
  instanceType,
  showDeleteButton
}) {
  const theme = useTheme();
  const classes = useStyles({
    theme
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const questionMessage = i18n.t('DeleteEducation');

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [showConfirmButtons, setShowConfirmButtons] = useState(true);
  const [dialogMessage, setDialogMessage] = useState(questionMessage);
  const [deleterMessage, setDeleteMessage] = useState('');
  const [deletionTitle, setDeletionTitle] = useState('');

  const [message, setMessage] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    deleteMessage(instance, instanceType).then((result) => {
      setMessage(result);
    });
  }, [instance, instanceType]);

  useEffect(() => {
    const message = localStorage.getItem('snackbarMessage');
    if (message) {
      enqueueSnackbar(message, { variant: 'success' });
      localStorage.removeItem('snackbarMessage');
    }
  }, []);

  async function handleOpenDeleteModal () {
    let message;

    if (lecture) {
      message = lectureDeleteMessage(lecture);
    } else {
      message = await deleteMessage(instance, instanceType);
    }
    setDeleteMessage(message);
    setShowDeleteConfirmDialog(true);
  }

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  function handleCloseDialog () {
    setShowConfirmDialog(false);
    setShowDeleteConfirmDialog(false);
  }

  const handleConfirm = () => {
    onDelete();
    handleCloseDialog();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (onClose) {
      onClose();
    }
  };

  const lectureDeleteMessage = (lecture) => {
    let message;
    if (lecture.mirror_child) {
      message = (
        <>
          A {i18n.t('StructuresName.Lectures.SingularLower')}{' '}
          <span style={{ fontWeight: 'bold' }}>{lecture.name}</span>, que é um
          espelho da {i18n.t('StructuresName.Lectures.SingularLower')} "
          {lecture.mirror_child.name}", será permanentemente excluída, incluindo
          todos os dados e arquivos. Todos os membros irão perder o acesso. A{' '}
          {i18n.t('StructuresName.Lectures.SingularLower')} original não será
          afetada.
        </>
      );
      setDeletionTitle(i18n.t('DeleteMirroredMedia.title'));
    } else {
      message = (
        <>
          A {i18n.t('StructuresName.Lectures.SingularLower')}{' '}
          <span style={{ fontWeight: 'bold' }}>{lecture.name}</span>, será
          permanentemente excluída, incluindo todos os dados e arquivos. Todos
          os membros irão perder o acesso.
        </>
      );
      setDeletionTitle(i18n.t('DeleteMedia.title'));
    }

    return message;
  };

  const deleteMessage = async (instance, instanceType) => {
    let message;

    switch (instanceType) {
      case 'course':
        if (instance.is_mirror || instance.mirrors_count === 0) {
          message = (
            <>
              O {i18n.t('StructuresName.Course.SingularLower')}{' '}
              <span style={{ fontWeight: 'bold' }}>{instance.name}</span>, será
              permanentemente excluído, incluindo todos os dados e arquivos.
              Todos os membros irão perder o acesso.
            </>
          );
        } else {
          message = (
            <>
              O {i18n.t('StructuresName.Course.SingularLower')}{' '}
              <span style={{ fontWeight: 'bold' }}>{instance.name} </span>
              possui {instance.mirrors_count} espelho(s) e será permanentemente
              excluído, incluindo todos os dados e arquivos dos seus espelhos.
              Todos os membros irão perder o acesso.
            </>
          );
        }
        setDeletionTitle(i18n.t('DeleteCourse.title'));
        break;

      case 'space':
        if (instance.is_mirror || instance.mirrors_count === 0) {
          message = (
            <>
              A {i18n.t('StructuresName.Space.SingularLower')}{' '}
              <span style={{ fontWeight: 'bold' }}>{instance.name}</span>, será
              permanentemente excluída, incluindo todos os dados e arquivos.
              Todos os membros irão perder o acesso.
            </>
          );
        } else {
          message = (
            <>
              A {i18n.t('StructuresName.Space.SingularLower')}{' '}
              <span style={{ fontWeight: 'bold' }}>{instance.name} </span>
              possui {instance.mirrors_count} espelho(s) e será permanentemente
              excluída, incluindo todos os dados e arquivos dos seus espelhos.
              Todos os membros irão perder o acesso.
            </>
          );
        }
        setDeletionTitle(i18n.t('DeleteSpace.title'));
        break;
    }
    return message;
  };

  const handleDelete = async () => {
    deleteLecture(lectureID)
      .then(() => {
        handleClose();
        enqueueSnackbar(`Mídia excluída com sucesso!`, {
          variant: 'success'
        });
        setLectures((lectures) =>
          lectures.filter((elem) => elem.id != lectureID)
        );
      })
      .catch((error) => {
        enqueueSnackbar(`Erro ao excluir mídia.`, {
          variant: 'error'
        });
      });
  };

  const LinkAlone = () => (
    <Link
      to={link}
      className={classes.popUpMenuWraper}
      style={{ width: width ? width : 'auto' }}
    >
      <div
        className={`${showText ? classes.iconOneLinkText : classes.iconCustomWraper
          }`}
      >
        {Icon && <Icon className={classes.iconCustom} />}
      </div>
      {showText && (
        <Text variant="text" color="primary">
          {text}
        </Text>
      )}
    </Link>
  );

  const MenuItemRef = React.forwardRef(({ onClick, style, children }, ref) => (
    <MenuItem ref={ref} onClick={onClick} style={style}>
      {children}
    </MenuItem>
  ));

  const LinkList = () => {
    const menuItemRef = useRef(null);
    return linkList.map((item, key) => {
      // Verifica se o item tem um componente para renderizar
      if (item.component) {
        return (
          <MenuItemRef key={key} style={item.style} ref={menuItemRef}>
            <div className={classes.iconCustomWraper}>
              {item.Icon && <item.Icon className={classes.iconCustom} />}
            </div>
            {React.cloneElement(item.component, { forwardedRef: menuItemRef })}
          </MenuItemRef>
        );
      }

      // Renderização padrão para itens de link
      return (
        <MenuItem key={key} disabled={item.disabled}>
          <Link
            to={item.link ? item.link : '#'}
            onClick={item?.action}
            className={classes.wrapMenuItem}
          >
            <div className={classes.iconCustomWraper}>
              {item.Icon && <item.Icon className={classes.iconCustom} />}
            </div>
            <Text variant="text" color="primary">
              {item.text}
            </Text>
          </Link>
        </MenuItem>
      );
    });
  };
  return justOneLink ? (
    <LinkAlone />
  ) : (
    <>
      <ConfirmModal
        show={showConfirmDialog}
        text={dialogMessage}
        handleClose={handleCloseDialog}
        handleConfirm={handleConfirm}
        maxWidth={440}
        loading={loadingDelete}
        showButtons={showConfirmButtons}
      />
      <DeleteConfirmModal
        show={showDeleteConfirmDialog}
        handleClose={handleCloseDialog}
        handleConfirm={lecture ? handleDelete : handleConfirm}
        maxWidth={440}
        title={deletionTitle}
        loading={loadingDelete}
        showButtons={showConfirmButtons}
        descriptionMessage={deleterMessage}
      />
      <div
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.popUpMenuWraper}
      >
        <div
          className={`${classes.iconCustomWraper} ${showText &&
            classes.iconOneLinkText} ${hasMore && classes.NoBackground}`}
        >
          {lecture || instanceType === 'course' || instanceType === 'space' ? (
            <Icon
              className={`${classes.iconCustomLecture} ${hasMore &&
                classes.NoBackgroundIconColor}`}
            />
          ) : (
            <Icon
              className={`${classes.iconCustom} ${hasMore &&
                classes.NoBackgroundIconColor}`}
            />
          )}
        </div>
        {showText && (
          <div>
            <Text variant="text" color="primary">
              {text}
            </Text>
          </div>
        )}
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && !wasDeleted}
        onClose={handleClose}
      >
        <LinkList />
        {lecture && (
          <>
            <a
              onClick={handleOpenModal}
              className={classes.wrapMenuItem}
              style={{ cursor: 'pointer', padding: '6px 16px' }}
            >
              <div className={classes.iconCustomWraper}>
                <ShareIcon className={classes.iconCustom} />
              </div>

              <Text variant="text" color="primary">
                Compartilhar{' '}
                {`${i18n.t('StructuresName.Lectures.SingularLower')}`}
              </Text>
            </a>
            <a
              onClick={handleOpenDeleteModal}
              className={classes.wrapMenuItem}
              style={{ cursor: 'pointer', padding: '6px 16px' }}
            >
              <div className={classes.iconCustomWraper}>
                <DeleteIcon className={classes.iconCustom} />
              </div>

              <Text variant="text" color="primary">
                {i18n.t('Delete')}
              </Text>
            </a>
            <Modal
              open={open && lectureID}
              onClose={handleCloseModal}
              className={classes.modal}
            >
              <div>
                <ShareContentLecture lectureID={lectureID} />
              </div>
            </Modal>
          </>
        )}
        {subjects && (
          <>
            <a
              onClick={handleOpenDeleteModal}
              className={classes.wrapMenuItem}
              style={{ cursor: 'pointer', padding: '6px 16px' }}
            >
              <div className={classes.iconCustomWraper}>
                <ShareIcon className={classes.iconCustom} />
              </div>

              <Text variant="text" color="primary">
                Compartilhar{' '}
                {`${i18n.t('StructuresName.Subjects.SingularLower')}`}
              </Text>
            </a>
            <a
              onClick={handleOpenDeleteModal}
              className={classes.wrapMenuItem}
              style={{ cursor: 'pointer', padding: '6px 16px' }}
            >
              <div className={classes.iconCustomWraper}>
                <DeleteIcon className={classes.iconCustom} />
              </div>

              <Text variant="text" color="primary">
                {i18n.t('Delete')}
              </Text>
            </a>
            <Modal
              open={open && currentSubjectId}
              onClose={handleCloseModal}
              className={classes.modal}
            >
              <div>
                <ShareContent subjectID={currentSubjectId} />
              </div>
            </Modal>
          </>
        )}
        {isCourse && showDeleteButton && (
          <>
            <a
              onClick={handleOpenDeleteModal}
              className={classes.wrapMenuItem}
              style={{ cursor: 'pointer', padding: '6px 16px' }}
            >
              <div className={classes.iconCustomWraper}>
                <DeleteIcon className={classes.iconCustom} />
              </div>

              <Text variant="text" color="primary">
                {i18n.t('Delete')}
              </Text>
            </a>
          </>
        )}
      </Menu>
    </>
  );
}

PopUpMenu.propTypes = {
  text: PropTypes.string,
  showText: PropTypes.bool,
  linkList: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string,
      Icon: PropTypes.any,
      disabled: PropTypes.bool
    })
  ),
  justOneLink: PropTypes.bool,
  Icon: PropTypes.any,
  onDelete: PropTypes.shape(),
  isCourse: PropTypes.bool
};

PopUpMenu.defaultProps = {
  text: '',
  showText: false,
  linkList: [],
  disabled: false,
  justOneLink: false,
  Icon: SettingsIcon,
  onDelete: null,
  isCourse: false
};
