import {
  lectureItemHeight,
  lectureItemWidth,
  lectureItemMinHeight,
  lectureItemMinWidth
} from '~/constants/styles';

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: 7.5,
    cursor: 'pointer'
  },
  shimmerRoot: {
    marginRight: 7.5
  },
  iconContainer: {
    width: lectureItemWidth.lg,
    height: lectureItemHeight.lg,
    minWidth: lectureItemMinWidth,
    minHeight: lectureItemMinHeight,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '12px',
    justifyContent: 'center',
    borderRadius: '5px',
    position: 'relative',
    '@media (max-width: 1790px)': {
      width: lectureItemWidth.md,
      height: lectureItemHeight.md
    },
    '@media (max-width: 450px)': {
      width: lectureItemWidth.sm,
      height: lectureItemHeight.sm
    }
  },
  iconBackground: {
    backgroundColor: theme.palette.primary.main
  },
  lectureTitle: {
    marginBottom: 18,
    letterSpacing: 6,
    width: lectureItemWidth.lg,
    '@media (max-width: 1790px)': {
      width: lectureItemWidth.md
    },
    '@media (max-width: 450px)': {
      width: lectureItemWidth.sm
    }
  },
  lectureDescription: {
    letterSpacing: 0
  },
  bottomRow: {
    position: 'absolute',
    top: `calc(${lectureItemHeight.lg}px - 27px)`,
    width: '100%',
    display: 'flex',
    padding: '0 5px',
    justifyContent: 'flex-end',
    '@media (max-width: 1790px)': {
      top: `calc(${lectureItemHeight.md}px - 27px)`
    },
    '@media (max-width: 450px)': {
      top: `calc(${lectureItemHeight.sm}px - 27px)`
    }
  },
  duration: {
    padding: '0 5px',
    borderRadius: 2,
    backgroundColor: '#70707074',
    display: 'flex',
    alignItems: 'center',
    height: 22
  }
}));
