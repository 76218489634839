import React from 'react';

import { faQuestion, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Fab, Badge, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles(() => ({
  rightDrawer: {
    '@media (max-width: 1190px)': {
      display: 'none'
    }
  },
  sideNavItem: {
    maxWidth: '97%',
    marginTop: 30,
    '@media (max-width: 1024px)': {
      display: 'none'
    }
  },
  logoContainer: {
    display: 'none',
    '@media (max-width: 1024px)': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      marginBottom: '35px'
    }
  },
  appBarContainer: {
    display: 'none',
    '@media (max-width: 450px)': {
      display: 'block'
    }
  }
}));

export const Container = styled(({ ...otherProps }) => (
  <Box {...otherProps} display="flex" flexDirection="row wrap" />
))``;

export const Logo = styled.img`
  width: 47px;
  height: auto;
`;
