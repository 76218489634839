import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Text from '~/components/atoms/Text';
import Input from '~/components/atoms/TextInput';
import ToggleComponent from '~/components/molecules/ToggleComponent';
import { IconWrapper, TextPrimary } from '~/utils/forms';

import Field from '../../GenericFormWrapper/Field';
import { useStyles } from './styles';
import { Grid, Switch } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';

export default function MainInfo() {
  const classes = useStyles();

  const { control, watch, formState } = useFormContext();
  const [showText, setShowText] = useState(true);

  const handleClick = () => {
    setShowText(false);
  };

  const handleBlur = () => {
    setShowText(true);
  };

  useEffect(() => {
    if (formState.errors.name && !formState.isValid) setShowText(false);
  }, [formState]);

  useEffect(() => {
    if (!showText) {
      let elem = document.getElementById('input-name');
      if (elem) elem.focus();
    }
  }, [showText]);

  const CustomText = () => {
    return (
      <Text fontWeight="bold" className={classes.name}>
        {' '}
        {watch('name') || 'Sem nome...'}{' '}
      </Text>
    );
  };

  return (
    <div className={classes.root}>
      <div>
        <ToggleComponent name="name" label="Nome">
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              {showText ? (
                <Field
                  id="input-name"
                  name="name"
                  control={control}
                  hideErrors
                  onBlur={handleBlur}
                  Component={CustomText}
                />
              ) : (
                <Field
                  id="input-name"
                  name="name"
                  control={control}
                  onBlur={handleBlur}
                  Component={Input}
                />
              )}
            </Grid>

            <Grid item>
              <IconWrapper
                onClick={handleClick}
                color="primary"
                fontSize="20px"
                Icon={CreateIcon}
              />
            </Grid>
          </Grid>
        </ToggleComponent>
      </div>

      <ToggleComponent name="visible" label="Visibilidade">
        <div className={classes.visible}>
          <Field
            label={TextPrimary('Ativo')}
            name="visible"
            control={control}
            labelPlacement={'end'}
            Component={Switch}
            checked={watch('visible')}
          />
        </div>
      </ToggleComponent>
    </div>
  );
}
