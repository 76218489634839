import React from 'react';
import PropTypes from 'prop-types';

const FormattedText = ({ text }) => {
    const renderTextWithLinks = () => {
        const parts = text.split(/(https?:\/\/\S+)/);
        return parts.map((part, index) => {
            if (part.match(/(https?:\/\/\S+)/)) {
                return (
                    <a key={index} href={part} target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
                        {part}
                    </a>
                );
            }
            return part;
        });
    };

    return <span>{renderTextWithLinks()}</span>;
};

FormattedText.propTypes = {
    text: PropTypes.string,
};

export default FormattedText;