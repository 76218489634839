import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  content: {
    display: 'flex',
    overflowX: 'hidden',
    overflow: 'auto',
    flexGrow: 1,
    flexDirection: 'column',
    width: 1080,
    gap: '30px',
    '@media (max-width: 1023px)': {
      width: 550,
      columnGap: 0,
      rowGap: 30
    },
    '@media (max-width: 750px)': {
      width: '95%'
    },
    paddingTop: 85
  },
  header: {
    marginBottom: 20
  },
  environmentContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
    marginTop: -50,
  },
  environmentsGrid: {
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'grid',
    justifyContent: 'space-between',
    gridTemplateColumns: 'repeat(2,50%)',
    width: 950,
    // gap: 30,
    '@media (max-width: 1023px)': {
      width: 550,
      gridTemplateColumns: 'repeat(1,100%)'
    },
    '@media (max-width: 750px)': {
      width: '95%'
    }
  },
  shimmerContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  shimmerItem: {
    width: 208,
    height: 208,
    margin: '0px 10px 0px 0',
    '@media (max-width: 1023px)': {
      height: 164,
      width: 164,
      margin: '12px 10px 12px 0'
    }
  },
  loadingContainer: {
    display: 'flex',
    marginLeft: '2%'
  },
  noEnvironmentsContainer: {},
  shimmerText: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    marginTop: 30
  },
  filter: {
    marginTop: 30
  }
});
