import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactLoading from 'react-loading';

import Text from '~/components/atoms/Text';
import PaginatableSelect from '~/components/molecules/PaginatableSelect';
import i18n from '~/I18n';
import {
  getMirrorOptions,
  getCloneableOptions
} from '~/services/replications/courses';

import { useStyles } from './styles';
import { Collapse, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export default function ReplicatedCourseForm({ isMirroring }) {
  const theme = useTheme();
  const classes = useStyles();

  const {
    setValue,
    formState: {
      errors: { courseID }
    },
    watch
  } = useFormContext();

  const [isLoading, setIsLoading] = useState(false);
  const [environments, setEnvironments] = useState(null);
  const [courses, setCourses] = useState(null);

  const [selectedEnvironment, setSelectedEnvironment] = useState('');
  const [selectedCourse, setSelectedCourse] = useState(null);
  const fetchStructures = isMirroring ? getMirrorOptions : getCloneableOptions;

  const handleSuccess = (values, setValues) => {
    setValues(values);
    setIsLoading(false);
  };

  const handleEnvironments = (event) => {
    setIsLoading(true);
    setValue('courseID', '');
    setCourses(null);

    const { target } = event;
    fetchStructures({ environmentID: target.value })
      .then((courses) => {
        setSelectedEnvironment(target.value);
        handleSuccess(courses, setCourses);
      })
      .catch(setIsLoading(false));
  };

  useEffect(() => {
    setIsLoading(true);
    fetchStructures({})
      .then((environments) => handleSuccess(environments, setEnvironments))
      .catch(setIsLoading(false));
  }, []);

  useEffect(() => {
    if (watch('courseID'))
      setSelectedCourse(courses.find((x) => x.id == watch('courseID')));
    else setSelectedCourse(null);
  }, [watch('courseID')]);

  return (
    <div className={classes.content}>
      {isLoading && !environments ? (
        <Grid container justifyContent="center">
          <ReactLoading type="bars" color={`${theme.palette.primary.main}`} />
        </Grid>
      ) : (
        <>
          {courseID ? (
            <Text color="error">
              Você ainda não selecionou um{' '}
              {`${i18n.t('StructuresName.Course.SingularUpper')}`}.
            </Text>
          ) : null}

          {environments ? (
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <PaginatableSelect
                  value={selectedEnvironment}
                  onChange={handleEnvironments}
                  label={`Selecione um ${i18n.t(
                    'StructuresName.Environment.SingularUpper'
                  )}`}
                  paginatableFunction={fetchStructures}
                  params={{}}
                  objectList={environments}
                  setObjectList={setEnvironments}
                  labelAttribute={'name'}
                  valueAttribute={'id'}
                />
              </Grid>

              <Grid item xs={12}>
                <Collapse in={isLoading && environments && !courses}>
                  <ReactLoading
                    width={30}
                    height={30}
                    type="bars"
                    color={`${theme.palette.primary.main}`}
                  />
                </Collapse>

                <Collapse in={courses}>
                  <PaginatableSelect
                    value={watch('courseID')}
                    onChange={(e) => setValue('courseID', e.target.value)}
                    label={`Selecione um ${i18n.t(
                      'StructuresName.Course.SingularUpper'
                    )}`}
                    paginatableFunction={fetchStructures}
                    params={{ environmentID: selectedEnvironment }}
                    objectList={courses}
                    setObjectList={setCourses}
                    labelAttribute={'name'}
                    valueAttribute={'id'}
                  />
                </Collapse>
              </Grid>

              <Grid item xs={12}>
                <Collapse in={watch('courseID')}>
                  <Text color="black" variant={'actionText'}>
                    {`${selectedCourse?.environment.name} > ${selectedCourse?.name}`}
                  </Text>
                  <Text
                    color="primary"
                    variant={'title'}
                    style={{ marginTop: '5px' }}
                  >
                    <span style={{ fontWeight: 'bold' }}>
                      {selectedCourse?.name}
                    </span>{' '}
                    selecionado
                  </Text>
                </Collapse>
              </Grid>
            </Grid>
          ) : (
            <Text color="primary">
              Infelizmente não encontramos nenhum{' '}
              {`${i18n.t('StructuresName.Course.SingularUpper')}`} em que você
              seja o proprietário.
            </Text>
          )}
        </>
      )}
    </div>
  );
}
