import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import ContactUsModal from '~/components/molecules/ContactUsModal';
import MessagesModal from '~/components/molecules/MessagesModal';
import {
  unreadMessageFromChatCountEmit,
  unreadMessageFromChatCountOn
} from '~/socket/message-events';

import { useStyles } from './styles';
import { faQuestion, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Fab, Badge, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function FloatButtons () {
  const theme = useTheme();
  const location = useLocation();
  const isMessagesRoute = location.pathname.includes('/messages');
  const classes = useStyles(theme);
  const { profile } = useSelector((state) => state.user);
  const [openContactUs, setOpenContactUs] = useState(false);
  const [openMessages, setOpenMessages] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  const [unreadMessages, setUnreadMessages] = useState(0);

  useEffect(() => {
    setShowButtons(Boolean(profile));
    if (profile) {
      unreadMessageFromChatCountEmit(profile.id);
      unreadMessageFromChatCountOn(profile.id, (unreadMessageNumber) => {
        setUnreadMessages(unreadMessageNumber?.unread?.unread);
      });
    }
  }, [profile]);

  function toggleContactUsModal () {
    if (!openContactUs && openMessages) setOpenMessages(false);
    setOpenContactUs(!openContactUs);
  }

  function toggleMessagesModal () {
    if (!openMessages && openContactUs) setOpenContactUs(false);
    setOpenMessages((prev) => !prev);
  }
  return (
    <>
      {showButtons && !isMessagesRoute && (
        <Box className={classes.root}>
          <ContactUsModal open={openContactUs} setOpen={setOpenContactUs} />
          <MessagesModal open={openMessages} />
          <Fab
            size="small"
            className={classes.buttons}
            onClick={toggleContactUsModal}
          >
            <FontAwesomeIcon
              icon={faQuestion}
              color={theme.palette.primary.main}
            />
          </Fab>
          <Badge
            overlap="circle"
            color="error"
            badgeContent={unreadMessages}
            max={99}
            classes={{ badge: classes.badge }}
            onClick={toggleMessagesModal}
          >
            <Fab className={classes.buttons} size="small">
              <FontAwesomeIcon
                icon={faCommentDots}
                color={theme.palette.primary.main}
                style={{ fontSize: 20 }}
              />
            </Fab>
          </Badge>
        </Box>
      )}
    </>
  );
}

FloatButtons.propTypes = {
  handleContact: PropTypes.func,
  profilePageContact: PropTypes.object,
  setProfilePageContact: PropTypes.func
};

FloatButtons.defaultProps = {
  handleContact: null,
  profilePageContact: null,
  setProfilePageContact: null
};
