import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    backgroundColor: theme.palette.background.paper,
    maxHeight: '80vh',
    gap: '24px',
    borderRadius: '8px',
    minHeight: 'min-content',
    width: '95%',
    overflow: 'auto',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0, 0.2)'
    },
    [theme.breakpoints.up('xl')]: {
      width: '90%'
    },
    [theme.breakpoints.up('sm')]: {
      width: '85%'
    },
    [theme.breakpoints.up('md')]: {
      width: '85%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '80%'
    }
  },
  titleTermsAndPrivacy: {
    color: '#0056B4',
    fontSize: 24,
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  ScrollDiv: {
    height: '60%',
    overflow: 'scroll',
    padding: 20,
    background: '#F2F2F7',
    maskImage:
      'linear-gradient(180deg, rgba(242, 242, 247, 20), transparent 150%)',
    border: '1px solid rgba(0,0,0, 0.04)',
    borderRadius: 5,
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0, 0.2)'
    }
  }
}));
