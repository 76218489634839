import React from 'react';
import NumberFormat from 'react-number-format';

export default function InputMask(otherProps) {
  const MAX_VAL = 10;
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return true;
    return false;
  };

  return (
    <NumberFormat
      {...otherProps}
      decimalScale={1}
      fixedDecimalScale={true}
      allowNegative={false}
      isAllowed={withValueCap}
    />
  );
}
