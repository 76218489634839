import { makeStyles } from '@material-ui/core';

const itemWidth = 230;
const itemHeight = 130;
const mobileWidth = 250;
const mobileHeight = 100;

export const useStyles = makeStyles({
  lectureContainer: {
    cursor: 'pointer',
    width: itemWidth,
    height: itemHeight,
    marginBottom: 24,
    borderRadius: 5,
    position: 'relative',
    '@media (max-width: 600px)': {
      width: mobileWidth,
      height: mobileHeight
    }
  },
  iconContainer: {
    width: itemWidth,
    height: itemHeight,
    backgroundColor: (props) => props.theme.palette.primary.main,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    '@media (max-width: 600px)': {
      width: mobileWidth,
      height: mobileHeight
    }
  },
  lectureInfo: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    bottom: itemHeight,
    width: itemWidth,
    height: itemHeight,
    zIndex: 10,
    '@media (max-width: 600px)': {
      width: mobileWidth,
      height: mobileHeight,
      bottom: mobileHeight
    }
  },
  iconsContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    top: 10,
    width: itemWidth - 20,
    '@media (max-width: 600px)': {
      width: mobileWidth - 20
    }
  },
  seenIcon: {
    width: 20,
    height: 20,
    '& svg': {
      color: 'white',
      fontSize: 20
    }
  },
  lockIcon: {
    color: '#676D82',
    fontSize: 20
  },
  infoButton: {
    padding: 0,
    margin: 0,
    width: 20,
    height: 20,
    minWidth: 20,
    borderRadius: 10
  },
  infoIcon: {
    color: 'white',
    fontSize: 20
  },
  bottomRow: {
    position: 'absolute',
    bottom: 4,
    left: 4,
    width: '100%',
    padding: '0 5px',
    display: 'flex',
    alignItems: 'flex-end',
    '@media (max-width: 600px)': {
      top: mobileHeight - 50
    }
  },
  titleContainer: {
    flex: 1,
    overflow: 'hidden',
    '& p': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    }
  },
  customTooltip: {
    fontSize: '14px !important',
    width: '100% !important',
    lineHeight: '24px !important',
    maxWidth: '700px !important',
    backgroundColor: '#676D82 !important'
  },
  duration: {
    padding: '0 5px',
    display: 'flex',
    alignItems: 'center',
    height: 22,
    borderRadius: 2,
    backgroundColor: '#70707074',
    position: 'relative',
    marginLeft: 10
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});
