import React from 'react';
import { useFormContext } from 'react-hook-form';

import EnrollmentLinkToggle from '~/components/molecules/EnrollmentLinkToggle';
import { createEnrollmentLink, getEnrollmentLink } from '~/services/Space';
import Input from '~/components/atoms/TextInput';
import ToggleComponent from '~/components/molecules/ToggleComponent';
import Field from '~/components/organisms/GenericFormWrapper/Field';
import { TextPrimary } from '~/utils/forms';
import i18n from '~/I18n';

import { useStyles } from './styles';
import SubjectDependency from './SubjectDependency';
import { Grid } from '@material-ui/core';

export default function EditMirroredSpaceForm() {
  const classes = useStyles();
  const { watch } = useFormContext();

  const tooltipEnrollmentLinkMessage = `Se este campo for preenchido, apenas membros de posse
  dessa chave conseguirão se inscrever na
  ${i18n.t(`StructuresName.Space.SingularUpper`)}
  através do link.`;

  return (
    <div className={classes.content}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={8}>
          <ToggleComponent name="name" label="Nome">
            <div className={classes.field}>
              <Field
                label={TextPrimary('Nome *')}
                name="name"
                Component={Input}
                placeholder={`Digite o nome da ${i18n.t(
                  'StructuresName.Space.SingularLower'
                )}`}
              />
            </div>
          </ToggleComponent>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ToggleComponent name="initials" label="Sigla">
            <div className={classes.field}>
              <Field
                label={TextPrimary('Sigla')}
                name="initials"
                Component={Input}
                placeholder="Sigla..."
              />
            </div>
          </ToggleComponent>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} className={classes.field}>
          <ToggleComponent name="turn" label="Turno">
            <Field
              label={TextPrimary('Turno')}
              name="turn"
              Component={Input}
              placeholder="Turno..."
            />
          </ToggleComponent>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={9} className={classes.field}>
          <ToggleComponent name="description" label="Descrição">
            <Field
              label={TextPrimary('Descrição')}
              name="description"
              Component={Input}
              placeholder={`Informações da ${i18n.t(
                'StructuresName.Space.SingularUpper'
              )}...`}
              multiline
            />
          </ToggleComponent>
        </Grid>
      </Grid>

      {watch('parent.id') && (
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.field}>
            <EnrollmentLinkToggle
              structureID={watch('parent.id')}
              tooltipMessage={tooltipEnrollmentLinkMessage}
              getEnrollmentLink={getEnrollmentLink}
              createEnrollmentLink={createEnrollmentLink}
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} lg={6} className={classes.field}>
          <SubjectDependency />
        </Grid>
      </Grid>
    </div>
  );
}
