import React, { useEffect } from 'react';

import { useStyles } from './styles';
import Editor from 'ckeditor5-custom-viitra-build/build/ckeditor';

export default function PageLecture({ lecture }) {
  const { body } = lecture.lectureable;
  const classes = useStyles();

  const editorConfig = {
    htmlSupport: {
      allow: [
        {
          name: 'iframe',
          attributes: true,
          style: true
        }
      ]
    },
    htmlEmbed: {
      showPreviews: true
    }
  };

  useEffect(() => {
    let editorInstance;
    const editorElement = document.querySelector('#editor');

    Editor.create(editorElement, editorConfig)
      .then((instance) => {
        instance.enableReadOnlyMode('editor');
        editorInstance = instance;

        handleIframes();
        handleEmbbedLinks();
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      if (editorInstance) {
        editorInstance.destroy();
      }
    };
  }, []);

  const handleIframes = () => {
    const iframes = document.querySelectorAll('#editor iframe');
    iframes.forEach((iframe) => {
      iframe.style.pointerEvents = 'auto';
    });
  };

  const handleEmbbedLinks = () => {
    const links = document.querySelectorAll('#editor a[href]');
    links.forEach((link) => {
      link.addEventListener('click', (event) => {
        event.preventDefault();
        window.open(link.href, '_blank');
      });
    });
  };

  return (
    <div className={classes.root}>
      <div id="editor" dangerouslySetInnerHTML={{ __html: body }}></div>
    </div>
  );
}
