/* eslint-disable react/prop-types */
import React from 'react';

import Text from '~/components/atoms/Text';
import {
  TextLecture,
  ExerciseIcon,
  VideoLecture,
  ApresentationLecture
} from '~/components/Icons/Lecture';

import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { MdLock, MdLockClock } from 'react-icons/md';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';

export default function LectureItem({ lecture }) {
  const { name, type, finalized, duration, can_read, expired } = lecture;
  const theme = useTheme();
  const classes = useStyles({ theme });

  function renderIcon() {
    let Icon;
    let color = can_read ? 'white' : '#676D82';

    if (type === 'Seminar') Icon = VideoLecture;
    else if (type === 'Document') Icon = ApresentationLecture;
    else if (type === 'Exercise') Icon = ExerciseIcon;
    else if (type === 'Page') Icon = TextLecture;
    else if (type === 'Form')
      return (
        <AssignmentRoundedIcon style={{ fontSize: '60px', color: color }} />
      );
    else return null;

    return (
      <>
        <div className={classes.lgTitle}>
          <Icon width={45} height={45} color={color} />
        </div>
        <div className={classes.smTitle}>
          <Icon width={33} height={33} color={color} />
        </div>
      </>
    );
  }

  function timeToString(time) {
    const timeTypeOf = typeof time === 'number';
    return timeTypeOf && (time < 10 ? `0${time}` : `${time}`);
  }

  function getFormattedDuration() {
    const hours = timeToString(parseInt(duration / 3600));
    const minutes = timeToString(parseInt((duration % 3600) / 60));
    const seconds = timeToString((duration % 3600) % 60);

    let format = '';
    if (hours > 0) format += hours + ':';
    format += minutes + ':' + seconds;
    return format;
  }

  return (
    <>
      <Box className={classes.seenIcon}>{finalized && <VisibilityIcon />}</Box>
      <Box className={classes.lockIcon}>
        {!can_read && (!expired ? <MdLock /> : <MdLockClock />)}
      </Box>
      <Box className={classes.center}>{renderIcon()}</Box>
      <Box
        className={classes.details}
        style={{ justifyContent: duration ? 'space-between' : 'center' }}
      >
        <Text
          variant="mediumText"
          color="white"
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
            color: can_read ? '#fff' : '#676D82',
            fontSize: 13
          }}
        >
          {name}
        </Text>
        {duration && (
          <Text
            variant="mediumText"
            color="white"
            style={{
              backgroundColor: '#70707074',
              padding: '0 5px',
              borderRadius: 2,
              marginLeft: 10
            }}
          >
            {getFormattedDuration()}
          </Text>
        )}
      </Box>
    </>
  );
}
