import React from 'react';
import { useFormContext } from 'react-hook-form';

import ToggleComponent from '~/components/molecules/ToggleComponent';
import Field from '~/components/organisms/GenericFormWrapper/Field';
import { TextPrimary } from '~/utils/forms';
import i18n from '~/I18n';

import { useStyles } from './styles';
import {
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch
} from '@material-ui/core';

function SubjectDependency() {
  const classes = useStyles();
  const { control, watch, setValue } = useFormContext();

  const handleOrder = (event) => {
    setValue('order_subjects_by', event.target.value);
  };

  return (
    <Grid container>
      <Grid item xs={12} className={classes.switch}>
        <ToggleComponent
          name="dependent_subjects"
          label={`Ativação de dependência entre ${i18n.t('StructuresName.Subjects.PluralLower')}`}
        >
          <Field
            label={TextPrimary(`Ativar dependência entre ${i18n.t('StructuresName.Subjects.PluralLower')}`)}
            labelPlacement={'end'}
            name="dependent_subjects"
            control={control}
            checked={watch('dependent_subjects')}
            Component={Switch}
            size="small"
          />
        </ToggleComponent>
      </Grid>

      <Collapse in={watch('dependent_subjects')}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={{ xs: 12, sm: null }}>
              <ToggleComponent
                name="order_subjects_by"
                label={`Ordenação de ${i18n.t('StructuresName.Subjects.PluralUpper')}`}
              >
                <FormControl component="fieldset" style={{ padding: '10px' }}>
                  {TextPrimary(`Ordernar ${i18n.t('StructuresName.Subjects.PluralUpper')} por:`)}
                  <RadioGroup
                    aria-label="order_subjects_by"
                    name="order_subjects_by"
                    value={watch('order_subjects_by')}
                    onChange={handleOrder}
                  >
                    <FormControlLabel
                      value="created_at"
                      control={<Radio />}
                      label={TextPrimary('Data de Criação')}
                    />
                    <FormControlLabel
                      value="name"
                      control={<Radio />}
                      label={TextPrimary('Ordem lexicográfica')}
                    />
                  </RadioGroup>
                </FormControl>
              </ToggleComponent>
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
}

export default SubjectDependency;
