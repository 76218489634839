import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Text from '~/components/atoms/Text';
import EnvironmentForm from '~/components/organisms/EnvironmentForm';
import GenericFormWrapper from '~/components/organisms/GenericFormWrapper';
import StepperForm from '~/components/templates/StepperForm';
import i18n from '~/I18n';
import { handleSuccess } from '~/utils/auxiliaryFunctions';
import { environmentValidations } from '~/utils/EnvironmentValidation/yup';

import { createEnvironment } from './fetch';
import { useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';

export default function CreateEnvironment() {
  const theme = useTheme();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [, setSubmissionStatus] = useState('');
  const defaultValues = {
    new_avatar_attributes: null,
    description: '',
    enable_pagseguro: false,
    enable_youtube: false,
    enable_zoom: false,
    keywords: [],
    initials: '',
    minimum_rate_for_completion: 0,
    name: '',
    path: '',
    send_completion_reminder_mail: true,
    send_reminder_mail_when_completion_is_below: 30.0,
    zoom_api_key: '',
    zoom_api_secret: ''
  };

  const mirroringPath = '/dashboard/environments/new_mirror';
  const navOptions = [i18n.t('Information')];
  const successMessage = `O ${i18n.t(
    'StructuresName.Environment.SingularLower'
  )} foi criado!`;

  const breadcrumbs = [
    { name: i18n.t('MyEnvironments'), path: '/dashboard/environments' },
    { name: i18n.t('CreateEnvironment'), path: '/create-environment' }
  ];

  useEffect(() => {
    document.title = `${theme.title} | Criar ${i18n.t(
      'StructuresName.Environment.SingularLower'
    )}`;
  }, []);

  function renderContent(props) {
    const { selectedTab } = props;

    if (selectedTab === 0) {
      return (
        <>
          <GenericFormWrapper
            Form={EnvironmentForm}
            defaultValues={defaultValues}
            resolver={environmentValidations}
            {...props}
          />
          ;
        </>
      );
    }

    return false;
  }

  function handleCancel() {
    history.push('/dashboard/environments');
  }

  const finalizeSuccess = (response) => {
    const callbackSuccessPath = `/dashboard/environments/${response.data.id}`;
    setSubmissionStatus('success');
    handleSuccess(
      successMessage,
      enqueueSnackbar,
      callbackSuccessPath,
      history
    );
  };

  const finalizeFailure = () => {
    alert(
      `Não foi possível criar o ${i18n.t(
        'StructuresName.Environment.SingularLower'
      )}!`
    );
    setSubmissionStatus('error');
  };

  function submitForm(fields) {
    createEnvironment(fields)
      .then(finalizeSuccess)
      .catch(finalizeFailure);
  }

  return (
    <StepperForm
      navOptions={navOptions}
      breadcrumbs={breadcrumbs}
      onSubmit={submitForm}
      formTitle={i18n.t('CreateEnvironment')}
      submitButtonTitle={i18n.t('CreateEnvironment')}
      handleCancel={handleCancel}
      renderContent={renderContent}
    />
  );
}
