import React from 'react';
import { useFormContext } from 'react-hook-form';

import Input from '~/components/atoms/TextInput';
import ToggleComponent from '~/components/molecules/ToggleComponent';
import { TextPrimary } from '~/utils/forms';
import i18n from '~/I18n';

import Field from '../../GenericFormWrapper/Field';
import { Grid } from '@material-ui/core';

export default function Informations() {
  const { control } = useFormContext();
  return (
    <Grid container spacing={3} direction="column">
      <Grid item xs={8} sm={5} md={4}>
        <ToggleComponent name="initials" label="Sigla">
          <Field
            label={TextPrimary('Sigla')}
            name="initials"
            control={control}
            placeholder="Digite a sigla..."
            Component={Input}
          />
        </ToggleComponent>
      </Grid>
      <Grid item xs={12} sm={8} md={7}>
        <ToggleComponent name="description" label="Descrição">
          <Field
            label={TextPrimary('Objetivos e Programa')}
            name="description"
            control={control}
            multiline
            placeholder={`Informações acerca do ${i18n.t('StructuresName.Subjects.SingularLower')} e suas ${i18n.t('StructuresName.Lectures.PluralUpper')}...`}
            Component={Input}
          />
        </ToggleComponent>
      </Grid>
    </Grid>
  );
}
