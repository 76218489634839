import React, { useState } from 'react';

import { useStyles } from './styles';
import { Typography } from '@material-ui/core';
import { Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore, Description } from '@material-ui/icons/';
import PropTypes from 'prop-types';

export default function ReportCollapsable({ title, report: Report, id }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <div className={classes.root}>
      <button className={classes.button} onClick={() => setOpen(!open)}>
        <div className={classes.buttonContent}>
          <div className={classes.buttonTitle}>
            <Description
              color="primary"
              className={classes.buttonIcon}
              style={{ marginRight: '8px' }}
            />
            <Typography
              variant="h6"
              color="primary"
              style={{ fontSize: '16px' }}
            >
              {title}
            </Typography>
          </div>

          {open ? (
            <ExpandLess className={classes.buttonIcon} />
          ) : (
            <ExpandMore className={classes.buttonIcon} />
          )}
        </div>
      </button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Report id={id} />
      </Collapse>
    </div>
  );
}

ReportCollapsable.propTypes = {
  title: PropTypes.string,
  report: PropTypes.func,
  id: PropTypes.string
};

ReportCollapsable.defaultProps = {
  title: '',
  report: () => {},
  id: ''
};
