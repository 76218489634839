import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { EnvironmentIcon } from '~/components/atoms/Icons';
import QuestionBankModal from '~/components/atoms/QuestionDatabaseModal';
import Show from '~/components/atoms/Show';
import ListItem from '~/components/molecules/ListItem';
import i18n from '~/I18n';

import { Menu, useStyles } from './styles';
import { Box } from '@material-ui/core';
import AssessmentIcon from '@material-ui/icons/Assessment';
import BookIcon from '@material-ui/icons/Book';
import MessageIcon from '@material-ui/icons/Message';
import PersonIcon from '@material-ui/icons/Person';

export default function MenuMain() {
  const classes = useStyles();
  const { profile } = useSelector((state) => state.user);
  const [openQuestionDatabase, setOpenQuestionDatabase] = useState(false);

  return (
    <Box className={classes.menuMainContainer}>
      <Menu>
        <Link exact to={profile ? `/profile/${profile.id}` : '#'}>
          <ListItem text={i18n.t('MenuItem.MyProfile')}>
            <PersonIcon color="white" className={classes.icon} />
          </ListItem>
        </Link>
        <Link exact to="/dashboard/environments">
          <ListItem text={i18n.t('MyEnvironments')}>
            <EnvironmentIcon width={20} color="white" />
          </ListItem>
        </Link>
        <Link exact to="/messages">
          <ListItem text={i18n.t('Messages')}>
            <MessageIcon color="white" className={classes.icon} />
          </ListItem>
        </Link>
        <Show condition={profile?.laplace_access || profile?.inovaula_access}>
          <Box onClick={() => setOpenQuestionDatabase(true)}>
            <ListItem text={i18n.t('QuestionDatabase')}>
              <BookIcon className={classes.icon} />
            </ListItem>
          </Box>
        </Show>
        <Show condition={profile?.analytics_login_url}>
          <a href={`${profile?.analytics_login_url}`}>
            <ListItem text={i18n.t('Analytics')}>
              <AssessmentIcon className={classes.icon} />
            </ListItem>
          </a>
        </Show>
      </Menu>
      <QuestionBankModal
        open={openQuestionDatabase}
        setOpen={setOpenQuestionDatabase}
      />
    </Box>
  );
}
