import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles(() => ({
  divider: {
    margin: '0 -40px',
    '@media (max-width: 1100px)': {
      margin: 0
    }
  }
}));

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabContainer = styled.div`
  display: flex;
  margin-bottom: 1.7rem;
  margin-top: 2.9rem;
  width: 100%;
  @media (max-width: 495px) {
    justify-content: space-evenly;
  }
`;

export const TabButton = styled.button`
  margin-right: 42px;
  font-size: 20px;
  color: ${(props) =>
    props.selected === props.buttonId
      ? props.theme.palette.primary.main
      : props.theme.palette.textActiveElement.main};
  @media (max-width: 1460px) {
    font-size: 16px;
  }
  @media (max-width: 495px) {
    margin-right: 0;
  }
`;
