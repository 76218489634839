/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import DeleteConfirmModal from '~/components/molecules/DeleteConfirmModal';
import EditMirroredSubjectForm from '~/components/organisms/EditMirroredSubjectForm';
import GenericFormWrapper from '~/components/organisms/GenericFormWrapper';
import StepperForm from '~/components/templates/StepperForm';
import i18n from '~/I18n';
import { getLectures } from '~/services/Subject';
import { handleErrors, handleSuccess } from '~/utils/auxiliaryFunctions';
import { editMirroredSubjectValidations } from '~/utils/SubjectValidation/editMirroredSubject';

import {
  getMirroredSubject,
  updateMirroredSubject,
  deleteMirroredSubject
} from './fetch';
import { useSnackbar } from 'notistack';

export default function EditMirroredSubject() {
  const { mirroredSubjectID } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [, setSubmissionStatus] = useState('');
  const [parentSpaceID, setParentSpaceID] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const openDeleteModal = () => setShowDeleteModal(true);
  const closeDeleteModal = () => setShowDeleteModal(false);

  const [defaultValues, setDefaultValues] = useState({
    toggle: {
      name: null,
      initials: null,
      description: null,
      scheduleable: null,
      subject_opening: null,
      subject_closure: null,
      visible: null,
      lecture_dependency: null
    },
    id: null,
    parentID: null,
    name: null,
    initials: null,
    description: null,
    scheduleable: null,
    subject_opening: null,
    subject_closure: null,
    visible: null,
    lecture_dependency: null,
    lectures: []
  });

  const successMessage = `O ${i18n.t(
    'StructuresName.Subjects.SingularLower'
  )} foi atualizado!`;

  const callbackSuccessPath = `/dashboard/spaces/${parentSpaceID}`;

  const breadcrumbs = [
    { name: i18n.t('MyEnvironments'), path: '/dashboard/environments' }
  ];

  const handleExclude = () => {
    openDeleteModal();
  };

  const handleDeleteConfirm = () => {
    deleteMirroredSubject(mirroredSubjectID)
      .then(() => {
        enqueueSnackbar(`Módulo excluído com sucesso!`, { variant: 'success' });
        history.push(callbackSuccessPath);
      })
      .catch(() => {
        enqueueSnackbar(`Erro ao excluir módulo.`, { variant: 'error' });
      })
      .finally(() => {
        closeDeleteModal();
      });
  };

  const deleteMessage = () => {
    const message = (
      <>
        O {i18n.t('StructuresName.Subjects.SingularLower')}{' '}
        <span style={{ fontWeight: 'bold' }}>{defaultValues.name}</span>, que é
        um espelho do {i18n.t('StructuresName.Subjects.SingularLower')} "
        {defaultValues.name}", será permanentemente excluído, incluindo todos os
        dados e arquivos. Todos os membros irão perder o acesso. O{' '}
        {i18n.t('StructuresName.Subjects.SingularLower')} original não será
        afetado.
      </>
    );
    return message;
  };

  const renderContent = (props) => {
    const { selectedTab } = props;

    if (selectedTab === 0) {
      return (
        <GenericFormWrapper
          Form={EditMirroredSubjectForm}
          defaultValues={defaultValues}
          resolver={editMirroredSubjectValidations}
          handleExclude={handleExclude}
          excludeTitle="Excluir"
          {...props}
        />
      );
    }

    return false;
  };

  const configDefaultValues = async (data) => {
    try {
      const lectures = await getLectures(data.parent.id);
      setParentSpaceID(data.parent.space_id);
      setDefaultValues({
        toggle: {
          name: data.real.name,
          initials: data.real.initials,
          description: data.real.description,
          scheduleable: data.real.scheduleable,
          subject_opening: data.real.subject_opening,
          subject_closure: data.real.subject_closure,
          visible: data.real.visible,
          lecture_dependency: data.real.lecture_dependency
        },
        id: data.id,
        parentID: data.parent.id,
        name: data.name,
        initials: data.real.initials,
        description: data.description,
        scheduleable: data.real.scheduleable,
        subject_opening: data.real.subject_opening || new Date(),
        subject_closure:
          data.real.subject_closure || new Date(Date.now() + 3600000),
        visible: data.visible,
        lecture_dependency: data.lecture_dependency,
        lectures: lectures
      });
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const fetchFailure = () => {
    window.alert(
      `Não foi possível carregar o ${i18n.t(
        'StructuresName.Subjects.SingularUpper'
      )}!`
    );
    handleCancel();
  };

  useEffect(() => {
    if (mirroredSubjectID)
      getMirroredSubject(mirroredSubjectID)
        .then(configDefaultValues)
        .catch(fetchFailure);
  }, [mirroredSubjectID]);

  const handleCancel = () => {
    history.push(`/dashboard/spaces/${parentSpaceID}`);
  };

  const finalizeSuccess = () => {
    setSubmissionStatus('success');
    handleSuccess(
      successMessage,
      enqueueSnackbar,
      callbackSuccessPath,
      history
    );
  };

  const finalizeFailure = (error) => {
    handleErrors(error, enqueueSnackbar);
    setSubmissionStatus('error');
  };

  function submitForm(fields) {
    const { toggle } = fields;

    let mirroredSubject = {};

    for (let k in toggle) {
      if (toggle[k]) {
        mirroredSubject[k] = fields[k];
      } else {
        mirroredSubject[k] = null;
      }
    }

    if (!mirroredSubject.scheduleable) {
      mirroredSubject.subject_closure = null;
      mirroredSubject.subject_opening = null;
    }
    const body = { mirrored_subject: { ...mirroredSubject } };

    updateMirroredSubject(mirroredSubjectID, body)
      .then(finalizeSuccess)
      .catch(finalizeFailure);
  }

  return (
    <>
      <DeleteConfirmModal
        show={showDeleteModal}
        handleClose={closeDeleteModal}
        handleConfirm={handleDeleteConfirm}
        maxWidth={440}
        loading={false}
        showButtons={true}
        descriptionMessage={deleteMessage()}
        title={i18n.t('DeleteMirroredSubject.title')}
      />
      <StepperForm
        breadcrumbs={breadcrumbs}
        onSubmit={submitForm}
        formTitle={i18n.t('EditSubject.title')}
        submitButtonTitle={i18n.t('EditSubject.saveButton')}
        handleCancel={handleCancel}
        renderContent={renderContent}
      />
    </>
  );
}
