import { Grid } from '@material-ui/core';
import React from 'react';

export default function Content({ MiniCard, Form }) {

  return(
    <Grid container direction='column' spacing={4}>
      <Grid item xs={12}>
        <div style={{ display: 'flex' }}>
          <MiniCard size="medium" minWidth="150px"/>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Form/>
      </Grid>
    </Grid>
  );
}
