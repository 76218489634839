import React from 'react';
import styled from 'styled-components';
import { Box, Avatar, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const ActionButton = styled(({ color, ...otherProps }) => (
  <Button
    size="small"
    {...otherProps}
    style={{
      height: 40,
      minWidth: 40,
      borderRadius: 20,
      padding: 0,
    }}
  />
))``;

export const ContactName = styled(({ color, ...otherProps }) => (
  <Typography
    {...otherProps}
    style={{
      fontSize: 16,
      color: color,
      margin: 0,
      padding: 0,
      maxWidth: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }}
  />
))``;

export const ContactSocialNetwork = styled(({ color, ...otherProps }) => (
  <Typography
    {...otherProps}
    style={{
      fontSize: 12,
      color: color,
      margin: 0,
      padding: 0,
      maxWidth: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }}
  />
))``;

export const ContainerInfoContact = styled(({ ...otherProps }) => (
  <Box
    {...otherProps}
    component="div"
    display="flex"
    justifyContent="flex-start"
    flexDirection="column"
    alignItems="flex-start"
    style={{
      marginRight: 10,
      flex: 3,
      width: '100%',
      overflow: 'hidden'
    }}
  />
))``;

export const Container = styled(({ ...otherProps }) => (
  <Box
    {...otherProps}
    component="div"
    display="flex"
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="center"
    style={{
      margin: 5,
      height: 70,
    }}
  />
))``;

export const AvatarCustom = styled(({ source, size, id , ...otherProps}) => (
  <Link to={`/profile/${id}`}>
    <Button
      display="flex"
      style={{
        minWidth: size,
        width: size,
        height: size,
        borderRadius: size / 2,
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 5,
        marginBottom: 5,
        marginTop: 5,
        marginRight: 15,
      }}
      >
      <Avatar
        {...otherProps}
        src={source}
        alt="Avatar"
        style={{
          width: size,
          height: size,
        }}
      />
    </Button>
  </Link>
))``;
