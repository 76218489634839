import { selectEnglishWordsByClient } from '~/I18n/modules/pt-BR';
import { welcomeMessages } from '~/utils/welcome-messages';
import settings from '~settings';
import themes from '~themes';

const wordsByClient = selectEnglishWordsByClient();
const client = settings.name;

const title = welcomeMessages().title;
const subtitle = welcomeMessages().subtitle;

export default {
  translations: {
    ...wordsByClient,
    Analytics: 'Analytics', // Enquanto não encontra uma palavra em português adequada.
    Manual: 'Manual da plataforma',
    Follow: 'Seguir',
    Pending: 'Pendente',
    Accepted: 'Conectado',
    ExerciseNotDoneYet: 'Nenhum Aluno realizou este exercício ainda',
    DoExercise: 'Fazer exercício',
    NotDoneYet: 'Você ainda não realizou essa atividade!',
    ListOfAnswers: ' Ver Gabarito',
    MemberTime: 'Seu tempo',
    MemberGrade: 'Sua nota',
    MemberName: 'Nome do aluno',
    Seconds: 'Segundos',
    Minutes: 'Minutos',
    Hours: 'Horas',
    QuestionsReport: 'Relatório',
    Review: 'Rever questões',
    QuestionsMultipleChoices: 'Questões(múltipla escolha)',
    ExplainedQuestions: 'Questões explicadas',
    BestGrades: 'Melhores notas',
    Recently: 'Recentemente',
    Name: 'Nome',
    Grade: 'Nota',
    Time: 'Tempo',
    Hits: 'Acertos',
    Misses: 'Erros',
    Null: 'Nulas',
    OverallTime: 'Tempo médio',
    People: 'Pessoas',
    HowManyDidAlready: 'Quantos já fizeram',
    OverallGrade: 'Média geral',
    Abbreviation: 'Abreviação',
    Keywords: 'Palavras-chave',
    Content: 'Conteúdo',
    Discussion: 'Discussão',
    Reports: 'Relatórios',
    Members: 'Membros',
    Send: 'ENVIAR',
    Explanations: 'Comentários do professor',
    Question: 'Questão',
    Submitted: 'Exercicio já respondido',
    Rating: 'Avaliação',
    Exercise: 'Exercício',
    BioCurriculum: 'Bio e Currículo',
    Certificates: 'Certificados',
    Back: 'Voltar',
    Education: 'Formação',
    Experience: 'Experiencia Profissional',
    Languages: 'Idiomas',
    InterestAreas: 'Interesses',
    Contacts: 'Contatos',
    Present: 'Presente',
    bachelorship: 'Bacharelado em',
    Quit: 'Sair',
    EmptyListCourses: 'Este ambiente ainda não possui nenhum curso.',
    EmptyListSpaces: 'Este curso ainda não possui disciplinas.',
    EmptyListLectures:
      'Não foi possível obter a lista de mídias para este módulo. Por favor, tente novamente.',
    EmptyListModules: 'Esta disciplina ainda não possui módulos.',
    ExceptionHandlerMessage: 'Algo deu errado. Tente novamente',
    ErrorMessage:
      'Não foi possível completar a solicitação. Por favor, tente novamente.',
    ErrorUnsuportedFileMessage: 'Nós nao suportamos este tipo de arquivo.',
    Profile: 'Perfil',
    FieldRequiredErrorMessage: 'Existe algum campo que precisa ser preenchido',
    ComplementaryCourseCourse: 'Curso',
    ComplementaryCoursePlaceholder: 'ex: Experiencia do usuário',
    ComplementaryCourseInstitution: 'Instituição',
    ComplementaryCourseInstitutionPlaceholder: 'ex: Udemy',
    ComplementaryCourseYear: 'Ano',
    ComplementaryCourseYearPlaceholder: 'ex: 2020',
    ComplementaryCourseWorkload: 'Carga Horária',
    ComplementaryCourseWorkloadPlaceholder: 'Tempo total do curso',
    ComplementaryCourseDescription: 'Descrição',
    ComplementaryCourseDescriptionPlaceholder: 'Opcional',
    EventEducationName: 'Evento',
    EventEducationNamePlaceholder: 'ex: Campus party',
    EventEducationRole: 'Papel',
    EventEducationRolePlaceholder: 'Selecione um papel',
    EventEducationYear: 'Ano',
    EventEducationYearPlaceholder: 'Selecione um ano',
    HigherEducationInstitution: 'Instituição',
    HigherEducationInstitutionPlaceholder: 'ex: Universidade de São Paulo',
    HigherEducationKind: 'Grau',
    HigherEducationKindPlaceholder: 'Selecione um grau',
    HigherEducationCourse: 'Curso',
    HigherEducationCoursePlaceholder: 'ex: Administração',
    HigherEducationArea: 'Campo de estudo',
    HigherEducationAreaPlaceholder: 'ex: Software Educationais',
    HigherEducationStartYear: 'Ano de inicio',
    HigherEducationStartYearPlaceholder: 'ex:2020',
    HigherEducationEndYear: 'Ano de término',
    HigherEducationEndYearPlaceholder: 'ex:2025',
    HigherEducationDescription: 'Descrição',
    HigherEducationDescriptionPlaceholder: 'Opcional',
    HighSchoolInstitution: 'Instituição',
    HighSchoolInstitutionPlaceholder: 'ex: Escola Tecnica Estadual',
    HighSchoolEndYear: 'Ano de término',
    HighSchoolEndYearPlaceholder: 'Selecione um ano',
    HighSchoolDescription: 'Descrição',
    HighSchoolDescriptionPlaceholder: 'Opcional',
    AddItem: 'Adicionar item',
    AddEducation: 'Adicionar formação',
    Cancel: 'Cancelar',
    Confirm: 'Confirmar',
    Save: 'Salvar',
    Technical: 'Técnico',
    Degree: 'Licenciatura',
    Bachelorship: 'Bacharelado',
    PosLatoSensu: 'Pós "Lato Sensu / MBA"',
    PosStrictoSensu: 'Pós "Stricto Sensu"',
    Doctorate: 'Doutorado',
    Phd: 'PhD',
    Participant: 'Participante',
    Speaker: 'Palestrante',
    Organizer: 'Organizador',
    HigherEducation: 'Ensino Superior',
    HighSchool: 'Ensino Médio',
    EventEducation: 'Evento',
    ComplementaryCourse: 'Curso Complementar',
    HomePage: 'Página Inicial',
    overview: 'Visão geral',
    myMural: 'Meu mural',
    following: 'Seguindo',
    shareSomething: 'Compartilhe algo ao seu mural',
    friendshipRequestText: 'quer se conectar a você',
    noDescription: 'Sem descrição',
    noEducations: 'Sem Formação',
    noExperiences: 'Sem experiência profissional',
    noLanguages: 'Sem idiomas',
    NoInterestAreas: 'Sem interesses',
    NoMobileNumber: 'Sem número de telefone',
    NoDescription: 'Sem descrição',
    noEnvironments: 'Sem ambientes',
    noContacts: 'Sem contatos',
    ViewAchievements: 'Ver conquistas',

    AddReply: 'Adicione uma resposta',
    Reply: 'Responder',
    ShowReplies: 'Mostrar respostas',
    HideReplies: 'Ocultar respostas',
    commentedOn: 'comentou no',

    of: 'de',
    Student: 'Aluno',
    EditProfile: 'Editar Conta',
    Achievements: 'Conquistas',
    Mural: 'Mural',
    AddedBy: 'Adicionado por',
    EmptyWall: 'Mural Vazio',
    MaximumImageSize: 'O tamanho máximo da imagem deve ser 10MB',
    LoadingImage: 'Carregando imagem',
    DescriptionPlaceholder: 'Escreva um pouco sobre você',
    InterestPlaceholder: 'Adicione um interesse...',
    AddLanguage: 'Adicionar idioma',
    AddExperience: 'Adicionar experiência',
    Language: 'Idioma',
    LanguagePlaceholder: 'ex: Português, Inglês',
    Role: 'Cargo',
    Company: 'Empresa',
    StartDate: 'Data de entrada',
    EndDate: 'Data de saída',
    CurrentlyWorking: 'Atualmente trabalho nesta empresa',
    Description: 'Descrição',
    RolePlaceholder: 'ex: Assistente administrativo',
    CompanyPlaceholder: 'ex: Sebrae',
    Optional: 'Opcional',
    EducationType: 'Tipo de formação',
    EducationTypePlaceholder: 'Selecione um tipo',
    Month: 'Mês',
    Year: 'Ano',
    UpdateProfileError: 'Houve algum problema, verifique os campos.',
    UpdateProfileSuccess: 'Dados atualizados com sucesso!',
    DeleteEducation: 'Você tem certeza que deseja excluir esta formação?',
    DeleteExperience: 'Você tem certeza que deseja excluir esta experiência?',
    DeleteErrorMessage:
      'Houve um problema na requisição, por favor tente novamente mais tarde.',
    HelpCenter: 'Central de ajuda',
    HelpCenterMessage: {
      prefix: `Olá, se você está com dúvidas referentes ao uso do ${client} acesse nossa `,
      sufix:
        'Se a dúvida não foi esclarecida ou você quer nos sugerir algo, deixe-nos uma mensagem.'
    },
    BasicGuide: 'Guia básico',
    StudentGuide: 'Guia do aluno',
    TeacherGuide: 'Guia do professor',
    ManagerGuide: 'Guia do gestor',
    ContactUs: 'Fale conosco',
    ContactUsNamePlaceholder: 'Insira seu nome',
    ContactUsEmailPlaceholder: 'Insira um e-mail para contato',
    ContactUsSubjectPlaceholder: 'Escreva aqui o assunto da mensagem',
    ContactUsBody: 'Mensagem',
    ContactUsBodyPlaceholder: 'Escreva aqui sua mensagem',
    Email: 'E-mail',
    Category: 'Categoria',
    Subject: 'Assunto',
    SendMessage: 'Enviar mensagem',
    EmptyFieldError: 'Este campo precisa ser preenchido.',
    InvalidEmail: 'Este e-mail não é válido.',
    SendMessageSuccess: 'Mensagem enviada com sucesso!',
    SendMessageError: 'Não foi possível enviar a mensagem.',
    OpenZoom: 'Abrir mídia no Zoom',
    OpenJitsi: 'Abrir mídia no Jitsi Meet',
    StreamKey: 'Sua chave da stream',
    ObsExplanation:
      'Utilize essa chave para iniciar a transmissão no seu programa de streaming. Recomendamos o software gratuito ',
    StreamGuide:
      'Clique aqui para ver as instruções de como realizar sua primeira transmissão.',
    DeletedUser: 'Usuário deletado',
    GenerateCertificate: 'Gerar Certificado',
    GenerateCertificateSuccess:
      'Download efetuado com sucesso! Você pode achá-lo na pasta de Downloads.',
    GenerateCertificateRequestError:
      'Ocorreu algum problema na requisição. Por favor, tente novamente mais tarde.',
    GenerateCertificateRuleError:
      'Você precisa completar mais mídias para conseguir o certificado!',
    GetCertificate: 'Gerar certificado',
    NoStoragePermission: 'A permissão de armazenamento não foi concedida',
    SearchSebraeEdu: `Buscar no ${client}`,
    RecentSearches: 'Buscas recentes',
    Clean: 'Limpar',
    ContactListInputPlaceholder: 'Buscar',
    ShowMore: 'Mostrar mais',
    NoStatus: 'Sem status',
    MenuItem: {
      Settings: 'Configurações',
      Messages: 'Mensagens',
      MyProfile: 'Meu Perfil',
      HelpCenter: 'Central de ajuda',
      ContactUs: 'Fale conosco',
      PrivacyPolicy: 'Política de privacidade e termos de uso',
      LogOut: 'Sair'
    },

    MyContacts: 'Meus contatos',

    ShowAll: 'Ver todos',
    ShowAll_male: 'Ver todos',
    ShowAll_female: 'Ver todas',
    Friendship: {
      title: 'Sugestões de amizade',
      pending: 'pendente',
      connect: 'conectar',
      Requested: 'Solicitado'
    },
    Home: 'Página Inicial',
    OldVersion: 'Mudar para versão antiga',
    SearchNotFound: 'Nada foi encontrado na busca',
    NoSubscription: 'Sem inscrições',

    SearchPlaceholder: 'Buscar',
    Welcome: 'Bem-vindo(a)',
    Tour: {
      Welcome: `Seja bem-vindo à plataforma ${client}`,
      About: `A plataforma ${client} é um ambiente educativo que adota uma estrutura de rede social para facilitar o aprendizado e promover o engajamento. Deseja conhecer melhor? Faz um tour!`
    },
    WelcomeMessage: `A plataforma ${client} é um ambiente educativo que adota uma estrutura de rede social para facilitar o aprendizado e promover o engajamento!`,
    SharedOnItsWall: 'compartilhou uma nova mensagem em seu mural',
    Refuse: 'Recusar',
    Remove: 'Remover',
    Accept: 'Aceitar',
    ReportComment: 'Denunciar comentário',
    Report: 'Denunciar',
    RecentAchievements: 'Conquistas recentes',
    ShareOnWallLecture: 'Compartilhe algo no mural',
    ShareOnYourWall: 'Compartilhe algo ao seu mural',
    Account: 'Conta',
    toEdit: 'Editar',
    toList: 'Listar',
    toCreate: 'Criar',
    OtherWall: 'Outro Mural',
    ShowAnotherProfile: 'Exibir Outro Perfil',
    Users: 'Usuários',
    CreateUser: 'Criar Usuário',
    toFollow: 'Seguir',
    commonContacts: 'contatos em comum',
    searchMembroPlaceholder: 'Buscar Membro',
    Admin: 'Administrador',
    Moderator: 'Moderador',
    Environment_admin: 'Administrador',
    Course_admin: 'Administrador',
    Teacher: 'Professor',
    Tutor: 'Tutor',
    removeFromContactList:
      'Deseja remover {{firstName}} {{lastName}} dos seus Contatos',
    removeFromContactListSuccess:
      'Solicitação realizada com sucesso, {{firstName}} {{lastName}} foi removido dos seus Contatos',
    CertificateInfo: {
      StartDate: 'Data de início do curso',
      EndDate: 'Data de término do curso',
      DateExample: 'Ex: 30/04/2021',
      EnableGenerator: 'Habilitar geração de certificado',
      ResponsibleName: 'Nome do responsável',
      ResponsibleNamePlaceholder:
        'Insira o nome do responsável pela assinatura',
      ResponsibleRole: 'Cargo do responsável',
      ResponsibleRolePlaceholder:
        'Insira o cargo do responsável pela assinatura',
      OrganizationName: 'Nome da organização',
      OrganizationNamePlaceholder: 'Insira o nome da organização',
      MinPercent: 'Percentual mínimo para certificado',
      MinPercentPlaceholder:
        'Insira a porcentagem mínima de mídia assistidas para a geração do certificado',
      ResponsibleSignature: 'Assinatura do responsável'
    },

    CourseInfo: {
      CourseNamePlaceholder: 'Insira o nome do curso',
      AbbreviationPlaceholder: 'Insira uma sigla para o curso',
      WorkloadPlaceholder: 'Ex: 50',
      AverageRating: 'Média de avaliação',
      AverageRatingPlaceholder: 'Ex: 9.5',
      AddressPlaceholder: 'Insira um endereço web',
      CourseProgram: 'Programa do curso',
      CourseProgramPlaceholder: 'Insira um resumo do curso',
      KeywordPlaceholder: 'Insira uma palavra-chave...',
      ModerateEntry: 'Entrada Moderada'
    },
    MonetizeInfo: {
      MonetizeCourse: 'Monetizar curso',
      CoursePrice: 'Preço do curso',
      CoursePricePlaceholder: 'Ex: 30.00'
    },
    Address: 'Endereço',
    Subscription: 'Inscrição',
    all: 'Todos',
    all_male: 'Todos',
    all_female: 'Todas',
    NotificationInfo: {
      SendReminderEmail: 'Enviar emails de lembrete',
      SendEmailWhenPercentIsOver:
        'Enviar emails quando a porcentagem de conclusão do curso estiver abaixo de',
      SendEmailWhenPercentIsOverPlaceholder: 'Ex: 30'
    },
    NewCourse: 'Novo curso',
    SaveCourse: 'Salvar curso',
    EditBioCurriculum: {
      errorMessage: 'Opa! Houve algum problema na submissão.',
      successMessage: 'Dados salvos com sucesso!',
      AddAnInterestAreas: 'Adicione um interesse...',
      LanguagesPlaceholder: 'Ex: Português, Inglês'
    },
    Course: 'Curso',
    Space: 'Disciplina',
    SubjectModel: 'Módulo',
    course_plural: 'Cursos',
    courseWithCount: '{{count}} curso',
    courseWithCount_plural: '{{count}} cursos',
    EnvironmentInfoForm: {
      EnvironmentNamePlaceholder: 'Insira o nome do ambiente',
      AbbreviationPlaceholder: 'Insira uma sigla para o ambiente',
      AddressPlaceholder: 'Insira um endereço web',
      MinPercent: 'Percentual para certificado',
      MinPercentPlaceholder:
        'Insira um percentual mínimo para a geração do certíficado',
      KeywordPlaceholder: 'Insira uma palavra-chave...',
      AddDescription: 'Adicione uma descrição'
    },
    Image: 'Imagem',
    Continue: 'Continuar',
    End: 'Finalizar',
    GoBack: 'Voltar',
    NoImageSet: 'Nenhuma imagem selecionada',
    SelectAnEmoji: 'Selecione um emoji ',
    EventForm: {
      Name: 'Nome do Evento'
    },
    Proceed: 'Prosseguir',
    CreatedAt: 'Criado em',
    SupportMaterials: 'Materiais de apoio',
    toDelete: 'Deletar',
    Delete: 'Excluir',
    ContactDeleteMessage: 'deletei {{index}} da lista de contatos',
    Login: {
      Title: `${title}`,
      Subtitle: `${subtitle}`,
      AccessYourAccount: 'Acesse sua conta',
      Error: 'Login ou senha inválidos',
      EnterButton: 'Entrar',
      RememberMeCheck: 'Lembrar de mim',
      Password: 'Senha',
      Login: 'Login'
    },
    RecoverPassword: {
      Title: 'Somos a rede social educacional que faltava.',
      Subtitle: `${client} é um ambiente de aprendizagem que foi concebido para proporcionar formas intensas de colaboração em torno de mídias dispostas em suas páginas.`,
      SucessMessage: 'Uma mensagem foi enviada para o email cadastrado.',
      ErrorMessage:
        'Tivemos um problema com o seu email cadastrado, favor entrar em contato com o nosso suporte.',
      RecoverPassword: 'Recuperar senha'
    },
    ForgotPassword: 'Esqueceu a senha?',
    PasswordReset: 'Redefinição de senha',
    Information: 'Informações',
    Previous: 'Anterior',
    Next: 'Próximo',
    FullScreen: 'Tela Cheia',
    TeachersComment: 'Comentário do Professor',
    AllQuestionsMustBeAnswered: 'Todas as perguntas devem ser respondidas!!!',
    SubmittedExercise: 'SUBMETIDO',
    ClassesWatched: 'mídias Assistidas',
    FinishedExercizes: 'Exercícios finalizados',
    FinishedDisciplines: 'Disciplinas concluídas',
    FinishedWithMastery: 'Concluído com maestria',
    NumberOfQuestionsAnswered: 'Quantidade de questões respondidas',
    TimeDistance: 'Há {{time}}',
    CreateModule: 'Cadastrar Módulo',
    CreateClass: 'Cadastrar mídia',
    ManageModule: 'Gerenciar Módulo',
    noAbbreviation: 'Sem abreviação',
    link: 'Link',
    noLink: 'Sem link',
    noKeywords: 'Sem palavras-chave',
    noSupportMaterials: 'Sem materiais de apoio.',
    noRating: 'Sem avaliações',
    noTime: 'Sem duração',
    AddDiscipline: 'Cadastrar Disciplina',
    SeeMore: 'Ver mais',
    NoClassesForThisCourse: 'Este curso ainda não tem disciplinas',
    TermsOfUse: 'Termos de uso',
    PrivacyPolicy: 'Política de Privacidade',
    Accessibility: 'Acessibilidade',
    MinimumRequirements: 'Requisitos Mínimos',
    ContentNotAvailableYet: 'Este conteúdo ainda não está disponível',
    CaractersLeft: '{{caracterLeft}} caracteres restantes',
    Member: 'Membro',
    SearchMembers: 'Buscar membros',
    RemoveMember: 'Remover',
    AddMember: 'Adicionar membro',
    ManageSpace: 'Gerenciar Espaço',
    BackToHomePage: 'Volte para a página inicial',
    or: 'ou',
    ReportInfo: {
      ComercialContent: 'Conteúdo comercial indesejado ou spam',
      HateSpeech: 'Discurso de ódio ou violência explícita',
      HarassmentOrBullying: 'Assédio ou bullying',
      Other: 'Outro'
    },
    ChangeToOldVersion: 'Mudar para a versão antiga',
    Import: 'Importar',
    PleaseSelectAValidFile: 'Por favor, selecione um arquivo válido.',
    MaximumSize: 'Por favor, selecione um arquivo de até 10Mb.',
    DragFileToThisField:
      'Arraste o arquivo XLSX para este campo ou faça upload por meio do botão de selecionar arquivo abaixo.',
    DropFileHere: 'Solte o arquivo aqui',
    Wall: 'Mural',
    close: 'Fechar',
    continue: 'Prosseguir',
    alert: 'Alerta',
    success: 'Sucesso',
    error: 'ERRO',
    name: 'Nome',
    path: 'Endereço',
    Create: 'Criar',
    Attachment: 'Anexo',
    City: 'Município',
    CityOptional: 'Município (opcional)',
    QuestionDatabase: 'Banco de Questões',
    ExerciseImportButtonDescription:
      'Seleciona uma lista de exercício para importar',
    SelectExerciseList: {
      Title: 'Importar Questões',
      Description: 'Selecione a lista de questões que deseja importar:',
      Import: 'Importar'
    },
    LaplaceModal: {
      Title: 'Banco de Questões',
      Description:
        'Siga os seguintes passos para a criação da sua lista de exercícios:',
      Step1: '1º Passo:',
      Step1Description:
        'Para elaboração de exercícios, clique no botão "Ir para banco de questões" e você será encaminhado para a plataforma de banco de questões Laplace.',
      Step2: '2º Passo:',
      Step2Description:
        'Na plataforma Laplace você poderá criar uma nova lista de exercícios ao clicar em "Nova lista” ou ao clicar no botão "Clique aqui para começar a criar". Em seguida, escolha o grau de ensino para quem os exercícios estão destinados. Após adicionar os exercícios que deseja, clique em "Finalizar seleção", faça os últimos ajustes necessários e clique em "Finalizar" para que sua lista seja salva.',
      Step3: '3º Passo:',
      Step3Description: `Por fim, para adicionar a lista de exercícios criada, volte para a ${themes.title}. Então, você deverá criar uma aula em formato de exercícios e clicar na opção "Importar questões do banco". Por fim, selecione a lista de exercícios que você criou e clique em "Adicionar".`,
      GoToLaplace: 'Ir para o Banco de Questões'
    },
    InovaulaModal: {
      Title: 'Banco de Questões',
      Description:
        'Siga os seguintes passos para a criação da sua lista de exercícios:',
      Step1: '1º Passo:',
      Step1Description:
        'Para elaboração de exercícios, clique no botão "Ir para banco de questões" e você será encaminhado para a plataforma de banco de questões Inovaula.',
      Step2: '2º Passo:',
      Step2Description:
        'Na plataforma Inovaula você poderá criar uma coleção de exercícios ao clicar em "Criar coleção +". Em seguida, escolha a disciplina dos exercícios que sua coleção vai possuir. Após adicionar os exercícios que deseja, clique em "Salvar coleção", adicione um título e clique em "Salvar" para que sua coleção seja salva.',
      Step3: '3º Passo:',
      Step3Description: `Por fim, para adicionar a lista de exercícios criada, volte para a ${themes.title}. Então, você deverá criar uma aula em formato de exercícios e clicar na opção "Importar questões do banco". Por fim, selecione a coleção que você criou e clique em "Adicionar".`,
      GoToLaplace: 'Ir para o Banco de Questões'
    }
  }
};
