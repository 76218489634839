import api from './api';
import * as websocket from './websocket';

// EXPORTS
export const perPage = 5;

// Websocket
export const createMuralListener = (channel, handleChatDataFunction) => {
  websocket.subscriber(`mural/${channel}`, handleChatDataFunction);
};

export const sendToMuralListener = (channel, data) => {
  websocket.publisher(`mural/${channel}`, data);
};

export const createMuralAnswerListener = (channel, handleChatDataFunction) => {
  websocket.subscriber(`mural/answer/${channel}`, handleChatDataFunction);
};

export const sendToMuralAnswerListener = (channel, data) => {
  websocket.publisher(`mural/answer/${channel}`, data);
};

export const deleteAnswerPublisher = (channel, data) => {
  websocket.publisher(`mural/answer/${channel}`, data);
};

export const deleteAnswerListener = (channel, handleDeleteFunction) => {
  websocket.subscriber(`mural/answer/${channel}`, handleDeleteFunction);
};

// API
export const handleGetStatuses = async (lectureId, perPage, offset) => {
  const a = await api.get(`/api/lectures/${lectureId}/statuses`, {
    params: { per_page: perPage, offset }
  });
  return a;
};

export const handleAddNewStatusMural = (lectureId, data) => {
  return api.post(`/api/lectures/${lectureId}/statuses`, data);
};

export const handleGetAnswers = (itemId) => {
  return api.get(`api/statuses/${itemId}/answers`);
};

export const handleAddNewAnswerMural = (itemId, answer) => {
  return api.post(`api/statuses/${itemId}/answers`, {
    status: { text: answer }
  });
};
