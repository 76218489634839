import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '82%'
  },
  textField: {
    '& label.Mui-focused': {
      color: theme.palette.primary.main
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.main
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#B4BDD3'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#B4BDD3'
      }
    }
  },
  searchIcon: {
    fontSize: '2rem',
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export default useStyles;
