import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import ReplicatedSubjectForm from '~/components/organisms/ReplicatedSubjectForm';
import { useCustomSnackbar } from '~/contexts/customSnackBar';
import {
  createMirroredSubject,
  createClonedSubject
} from '~/services/replications/subjects';
import { handleErrors } from '~/utils/auxiliaryFunctions';
import { replicatedSubjectValidations } from '~/utils/SubjectValidation/replicatedSubject';

import { useSnackbar } from 'notistack';

export default function CreateReplicatedSubject({
  setFormConfig,
  isMirroring,
  setSubmission,
  texts
}) {
  const { id: spaceID } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { showSnackbar } = useCustomSnackbar();
  const [, setSubmissionStatus] = useState('');

  const defaultValues = {
    subjectID: ''
  };

  useEffect(() => {
    setFormConfig(defaultValues, replicatedSubjectValidations);
    setSubmission(() => submitForm);
  }, []);

  const finalizeSuccess = (response) => {
    const createdSubjectID = isMirroring
      ? response.data.parent.id
      : response.data.id;
    setSubmissionStatus('success');
    showSnackbar(
      `${isMirroring ? texts.mirroring.success : texts.duplication.success}!`,
      {
        buttonText: 'Ver andamento',
        buttonAction: () =>
          window.location.replace(
            `/dashboard/spaces/${spaceID}?subjectID=${createdSubjectID}`
          )
      }
    );
  };

  const finalizeFailure = (error) => {
    handleErrors(error, enqueueSnackbar);
    setSubmissionStatus('error');
  };

  function submitForm(fields) {
    const createReplicatedSubject = isMirroring
      ? createMirroredSubject
      : createClonedSubject;

    let body = {};

    if (isMirroring) {
      body = {
        mirrored_subject: { original_id: fields.subjectID }
      };
    } else {
      body = fields.subjectID;
    }

    createReplicatedSubject(spaceID, body)
      .then(finalizeSuccess)
      .catch(finalizeFailure);
  }

  return <ReplicatedSubjectForm isMirroring={isMirroring} />;
}
