/* eslint-disable react/prop-types */
import React from 'react';

import Text from '~/components/atoms/Text';

// eslint-disable-next-line react/prop-types
export default function ShowSelectedEnvironment({ selectedEnvironment }) {
  return (
    <Text color="primary" style={{ marginTop: '5px' }}>
      <span style={{ fontWeight: 'bold' }}>{selectedEnvironment.name}</span>{' '}
      selecionado
    </Text>
  );
}
