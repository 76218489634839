import React from 'react';

import DownloadReportButton from '~/components/organisms/EnvironmentReports/DownloadReportButton';
import { getLecturesReport } from '~/services/reports/subjects';

import RowText from './RowText';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function Row({ subject }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.root}>
      <RowText>{subject.id}</RowText>
      <RowText>{subject.name}</RowText>

      <DownloadReportButton
        getReport={getLecturesReport}
        baseName={`relatorio_de_aulas_por_modulo_`}
        id={subject.id}
      />
    </div>
  );
}

Row.propTypes = {
  subject: PropTypes.object
};

Row.defaultProps = {
  subject: {
    id: '',
    name: ''
  }
};
