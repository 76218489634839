/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import FormFilter from '~/components/FormFilter';
import OrderByPopover from '~/components/OrderByPopover';
import i18n from '~/I18n';
import { getLectures } from '~/services/Space';
import { getLectures as getSubjectLectures } from '~/services/Subject';

import Lecture from './Lecture';
import { useStyles } from './styles';
import SubjectsSelect from './SubjectsSelect';
import { useTheme, Box } from '@material-ui/core';

export default function Content({ space, lectureShimmer: LectureShimmer }) {
  const filters = useForm({
    defaultValues: {
      ord: '',
      direction: '',
      name_like: ''
    }
  });
  const theme = useTheme();
  const classes = useStyles({ theme: theme });
  const { id } = useParams();

  const [subject, setSubject] = useState({
    id: -1,
    name: i18n.t('AllModules')
  });
  const [lectures, setLectures] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [hasMoreLectures, setHasMoreLectures] = useState(true);
  const [page, setPage] = useState(1);

  const handleSetLectures = (lectures) => {
    setLectures((prev) => [...prev, ...lectures]);

    setHasMoreLectures(lectures.length >= 10);
    setPage((page) => page + 1);
    setIsFetching(false);
  };

  const loadMoreLectures = (e) => {
    if (isFetching || !hasMoreLectures) return;

    const bottom =
      e.target.scrollHeight <= e.target.scrollTop + e.target.offsetHeight + 180;

    if (bottom) {
      setIsFetching(true);
      if (subject.id !== -1) {
        getSubjectLectures(subject.id, page + 1, filters.getValues())
          .then(handleSetLectures)
          .catch(() => setIsFetching(false));
      } else {
        getLectures(id, page + 1, filters.getValues())
          .then(handleSetLectures)
          .catch(() => setIsFetching(false));
      }
    }
  };

  useEffect(() => {
    if (isFetching) return;
    setIsFetching(true);
    setLectures([]);
    setPage(0);

    if (subject.id !== -1) {
      getSubjectLectures(subject.id, 1, filters.getValues())
        .then(handleSetLectures)
        .catch(() => setIsFetching(false));
    } else {
      getLectures(id, 1, filters.getValues())
        .then(handleSetLectures)
        .catch(() => setIsFetching(false));
    }
  }, [
    subject,
    filters.getValues().ord,
    filters.getValues().direction,
    filters.getValues().name_like
  ]);

  useEffect(() => {
    document.title = `${theme.title} | ${space?.name}`;
  }, [space]);

  return (
    <FormProvider {...filters}>
      <Box className={`${classes.root} ${classes.justifyContentBetween}`}>
        {space && (
          <SubjectsSelect
            spaceID={space.id}
            courseID={space.course.id}
            environmentID={space.environment.id}
            subject={subject}
            setSubject={setSubject}
          />
        )}
        <div
          style={{
            display: 'flex',
            width: '70%',
            alignItems: 'center',
            gap: 24,
            marginTop: '2rem'
          }}
        >
          <FormFilter
            placeholder={`Pesquisar ${i18n.t(
              'StructuresName.Lectures.SingularUpper'
            )}...`}
          />
          <OrderByPopover />
        </div>
        <Box className={classes.lectureContainer} onScroll={loadMoreLectures}>
          <Box className={classes.lectureGrid}>
            {lectures.map((lecture) => (
              <Lecture
                key={lecture.id}
                lecture={lecture}
                spaceId={space?.id}
                spaceName={space?.name}
                setLectures={setLectures}
              />
            ))}
            {isFetching && <LectureShimmer />}
          </Box>
        </Box>
      </Box>
    </FormProvider>
  );
}
