import React from 'react';

import PropTypes from 'prop-types';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';
import history from '~/services/history';

import { Container, ContainerButton } from './styles';

export default function Grid({
  buttonTitle,
  title,
  children,
  targetRoute,
  lastChild,
  marginBottom
}) {
  return (
    <Container lastChild={lastChild} style={{ marginBottom }}>
      <Text variant="sideBarTitle" color="white" style={{ paddingBottom: 12 }}>
        {title}
      </Text>
      {children}
      {buttonTitle && (
        <ContainerButton>
          <ButtonSquare
            noCapitalize
            padding="4px 0px"
            onClick={() => history.push(targetRoute)}
          >
            <Text variant="text" color="white">
              {buttonTitle}
            </Text>
          </ButtonSquare>
        </ContainerButton>
      )}
    </Container>
  );
}

Grid.propTypes = {
  buttonTitle: PropTypes.string,
  title: PropTypes.string,
  targetRoute: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape()),
  ]),
  marginBottom: PropTypes.string,
  lastChild: PropTypes.bool,
};

Grid.defaultProps = {
  buttonTitle: '',
  title: '',
  targetRoute: '#',
  children: null,
  marginBottom: 0,
  lastChild: false
};
