import { formatStringToDate } from '~/utils/dates';

const isNotUndefined = (bool) => {
  return !(bool === undefined);
};

const setAttrs = (data) => {
  const startDate = formatStringToDate(data.real.course_start_date);
  const endDate = formatStringToDate(data.real.course_end_date);

  return {
    toggle: {
      name: isNotUndefined(data.real.name),
      initials: isNotUndefined(data.real.initials),
      path: isNotUndefined(data.real.path),
      average: isNotUndefined(data.real.average),
      workload: isNotUndefined(data.real.workload),
      keywords: isNotUndefined(data.real.tags),
      description: isNotUndefined(data.real.description),
      certificate: isNotUndefined(data.real.generate_certificate),
      pagseguro: isNotUndefined(data.real.monetized_through_pagseguro),
      email_reminder: isNotUndefined(data.real.send_completion_reminder_mail),
      enrollment_link_attributes: isNotUndefined(data.subscription?.link),
      enrollment_limit_attributes: isNotUndefined(
        data.enrollment_limit?.user_limit
      )
    },
    name: data.name,
    initials: data.initials,
    path: data.path,
    description: data.description,
    average: data.average,
    workload: data.workload,
    keywords: data.tags,
    parent: {
      id: data.parent.id
    },
    is_course_period: data.real.is_course_period,
    certificate: {
      generate_certificate: data.real.generate_certificate,
      responsible_for_signing: data.real.responsible_for_signing,
      signature: data.real.signature,
      certificate_logo_client: data.real.certificate_logo_client,
      signature_owner_organization: data.real.signature_owner_organization,
      signature_owner_position: data.real.signature_owner_position,
      minimum_rate_for_completion: data.real.minimum_rate_for_completion,
      course_end_date: endDate,
      course_start_date: startDate
    },
    pagseguro: {
      monetized_through_pagseguro: data.monetized_through_pagseguro,
      pagseguro_amount: data.pagseguro_amount
    },
    email_reminder: {
      send_completion_reminder_mail: data.send_completion_reminder_mail,
      send_reminder_mail_when_completion_is_below:
        data.send_reminder_mail_when_completion_is_below
    },
    enrollment_link_attributes: {
      generate_link: data.subscription ? true : false,
      link: data.subscription?.link,
      key: data.subscription?.key
    },
    enrollment_limit_attributes: {
      user_limit_enabled: data.enrollment_limit,
      id: data.enrollment_limit?.id,
      user_limit: data.enrollment_limit?.user_limit
    }
  };
};

const defaultAttrs = {
  toggle: {
    name: null,
    initials: null,
    path: null,
    description: null,
    workload: null,
    certificate: null,
    pagseguro: null,
    email_reminder: null,
    enrollment_link_attributes: null,
    enrollment_limit_attributes: null
  },
  name: null,
  initials: null,
  path: null,
  description: null,
  workload: null,
  keywords: [],
  parent: {
    id: null
  },
  is_course_period: null,
  certificate: {
    generate_certificate: null,
    responsible_for_signing: null,
    signature: null,
    certificate_logo_client: null,
    signature_owner_organization: null,
    signature_owner_position: null,
    course_end_date: null,
    course_start_date: null,
    minimum_rate_for_completion: null,
  },
  pagseguro: {
    monetized_through_pagseguro: null,
    pagseguro_amount: null
  },
  email_reminder: {
    send_completion_reminder_mail: null,
    send_reminder_mail_when_completion_is_below: null
  },
  enrollment_link_attributes: {
    generate_link: null,
    link: null,
    key: null
  },
  enrollment_limit_attributes: {
    user_limit_enabled: null,
    id: null,
    user_limit: null
  }
};

const handleFormFields = (fields) => {
  const { toggle } = fields;
  var mirroredCourse = {};
  for (let k in toggle) {
    if (toggle[k]) {
      mirroredCourse[k] = fields[k];
    } else {
      mirroredCourse[k] = null;
    }
  }

  mirroredCourse = handleNested('certificate', fields, mirroredCourse);
  mirroredCourse = handleNested('pagseguro', fields, mirroredCourse);
  mirroredCourse = handleNested('email_reminder', fields, mirroredCourse);

  return mirroredCourse;
};

const handleNested = (field, fields, mirroredCourse) => {
  let newMirroredCourse = { ...mirroredCourse };

  if (newMirroredCourse[field]) {
    newMirroredCourse = { ...newMirroredCourse, ...newMirroredCourse[field] };
  } else {
    for (let k in fields[field]) {
      newMirroredCourse[k] = null;
    }
  }

  delete newMirroredCourse[field];
  return newMirroredCourse;
};

const updateToggle = (originalToggle) => {
  const toggle = {
    ...originalToggle,
    generate_certificate: originalToggle.certificate,
    monetized_through_pagseguro: originalToggle.pagseguro,
    send_completion_reminder_mail: originalToggle.email_reminder
  };

  return toggle;
};

export { setAttrs, defaultAttrs, handleFormFields, updateToggle };
