import * as yup from 'yup';

export const editMirroredSubjectValidations = yup
  .object()
  .shape({
    subject_opening: yup.date(),
    subject_closure: yup.date().when('toggle.scheduleable', {
      is: true,
      then: yup.date().when('scheduleable', (scheduleable) => {
        if (scheduleable) {
          return yup.date().when('subject_opening', (subject_opening) => {
            if (subject_opening) {
              return yup
                .date()
                .min(
                  subject_opening,
                  'A data de fechamento deve ser após a de abertura.'
                );
            }
          });
        }
      })
    })
  })
  .required();
