import React from 'react';

import { EnvironmentIcon } from '~/components/atoms/Icons';
import Text from '~/components/atoms/Text';
import settings from '~settings';

import { useStyles } from './styles';
import { useTheme } from '@material-ui/core';
import { Paper, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Proptypes from 'prop-types';

const PaperSpaceInformation = ({ space, setShowSpaceInformation }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { name, thumbnails, description, initials, turn, tags } = space;
  const image =
    thumbnails && thumbnails.length >= 1 && thumbnails[0].href
      ? thumbnails[0].href
      : null;
  return (
    <Paper
      elevation={3}
      className={classes.root}
      classes={{ rounded: classes.paperRouded }}
    >
      <Box className={classes.closeIconContainer}>
        <CloseIcon
          style={{ fontSize: 20 }}
          className={classes.closeIcon}
          onClick={() => setShowSpaceInformation(false)}
        />
      </Box>
      <Box className={classes.name}>
        <Text color="gray" variant="largeText">
          {name || 'Sem nome'}
        </Text>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.image}>
          {image ? (
            <img
              src={`${settings.baseUrl}${image}`}
              alt={name}
              className={classes.imgTag}
            />
          ) : (
            <EnvironmentIcon width="8rem" height="8rem" color="white" />
          )}
        </Box>
        <Box className={classes.details}>
          <Box className={classes.description}>
            <Text color="gray" variant="mediumText">
              {description || 'Sem descrição'}
            </Text>
          </Box>
          <Box className={classes.detailsItem}>
            <Box className={classes.label}>
              <Text color="gray" variant="mediumText">
                Abreviação:
              </Text>
            </Box>
            <Box className={classes.itemContent}>
              <Text color="gray" variant="mediumText">
                {initials || 'Sem abreviação'}
              </Text>
            </Box>
          </Box>
          <Box className={classes.detailsItem}>
            <Box className={classes.label}>
              <Text color="gray" variant="mediumText">
                Turno:
              </Text>
            </Box>
            <Box className={classes.itemContent}>
              <Text color="gray" variant="mediumText">
                {turn || 'Sem turno'}
              </Text>
            </Box>
          </Box>
          <Box className={classes.detailsItem}>
            <Box className={classes.label}>
              <Text
                color="gray"
                variant="mediumText"
                style={{ marginTop: tags && tags.length > 0 ? 8 : 0 }}
              >
                Palavras-chave:
              </Text>
            </Box>
            <Box className={classes.keyWords}>
              {tags && tags.length > 0 ? (
                <>
                  {tags.map((tag, index) => (
                    <Box className={classes.keyWordsItem} key={index}>
                      <Text color="gray" variant="mediumText">
                        {tag.name}
                      </Text>
                    </Box>
                  ))}
                </>
              ) : (
                <Text color="gray" variant="mediumText">
                  Sem palavras-chave
                </Text>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

PaperSpaceInformation.propTypes = {
  space: Proptypes.shape(),
  setShowSpaceInformation: Proptypes.func
};

PaperSpaceInformation.defaultProps = {
  space: {},
  setShowSpaceInformation: null
};

export default PaperSpaceInformation;
