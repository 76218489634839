import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { AvatarBadge } from '~/components/atoms/AvatarBadge';
import { getLastMessageEmit, getLastMessageOn } from '~/socket/contact-events';

import * as S from './styles';
import { Typography, Box, useTheme } from '@material-ui/core';

export const ContactListMessageNavItem = ({
  url,
  conversation,
  index,
  profile
}) => {
  const theme = useTheme();
  const classes = S.useStyles({ theme });
  const [lastMessage, setLastMessage] = useState('');

  const {
    contact_id,
    name,
    contact_attributes: { thumbnails }
  } = conversation;

  useEffect(() => {
    if (profile) {
      getLastMessageEmit(profile?.id, contact_id);
      getLastMessageOn(profile?.id, contact_id, (message) => {
        setLastMessage(message);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NavLink
      to={`${url}/${contact_id}`}
      key={contact_id}
      className={classes.container}
      activeClassName={classes.active}
    >
      <Box className={classes.textWrapper}>
        <Box className={classes.avatarWrapper}>
          <AvatarBadge
            profile={profile}
            contact={{
              id: contact_id,
              first_name: name,
              thumbnails: thumbnails
            }}
          />
        </Box>

        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography className={classes.contactInfo}>{name}</Typography>
          <Typography className={classes.lastMessage}>{lastMessage}</Typography>
        </Box>
      </Box>
    </NavLink>
  );
};
