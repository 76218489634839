import React, { useEffect, useState } from 'react';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';

import { useTheme } from '@material-ui/core';

export default function GoogleMeet({ lecture }) {
  const theme = useTheme();
  const meetBaseUrl = 'https://meet.google.com/';
  const [roomURL, setRoomURL] = useState('');
  useEffect(() => {
    setRoomURL(meetBaseUrl + lecture.lectureable.external_resource);
  }, [lecture]);

  return (
    <div>
      <ButtonSquare
        width="200px"
        height="35px"
        variant="outlined"
        color={theme.palette.primary.main}
        border={`0.5px solid ${theme.palette.secondary.main}`}
        onClick={() => window.open(roomURL, '_blank')}
      >
        <Text variant={'largeText'} color="primary">
          Entrar na Videochamada
        </Text>
      </ButtonSquare>
    </div>
  );
}
