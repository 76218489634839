/* eslint-disable react/prop-types */
import React from 'react';
import { useFormContext } from 'react-hook-form';

import Field from '~/components/organisms/GenericFormWrapper/Field';
import { TextPrimary } from '~/utils/forms';

import { useStyles } from './styles';
import { Collapse, Switch } from '@material-ui/core';

export default function ToggleComponent({ name, label, children }) {
  const classes = useStyles();

  const { control, watch } = useFormContext();

  return (
    <>
      <div className={classes.switch}>
        <Field
          label={TextPrimary(`Personalizar ${label}`)}
          name={`toggle.${name}`}
          control={control}
          labelPlacement={'end'}
          Component={Switch}
          checked={watch(`toggle.${name}`)}
        />
      </div>
      <Collapse in={watch(`toggle.${name}`)}>{children}</Collapse>
    </>
  );
}
