import React from 'react';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';

import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const Sidebar = ({
  selectedContent,
  setSelectedContent,
  members,
  handleGetUsers
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.sidebar}>
      <Box className={classes.sidebarContent}>
        <Text
          color={selectedContent === 'content' ? 'primary' : 'gray'}
          variant="environmentSidebarText"
          style={{ marginBottom: 15 }}
          onClick={() => setSelectedContent('content')}
        >
          {i18n.t('Content')}
        </Text>
        <Text
          color={selectedContent === 'discussion' ? 'primary' : 'gray'}
          variant="environmentSidebarText"
          style={{ marginBottom: 15 }}
          onClick={() => setSelectedContent('discussion')}
        >
          {i18n.t('Discussion')}
        </Text>
        <Text
          color={selectedContent === 'members' ? 'primary' : 'gray'}
          variant="environmentSidebarText"
          onClick={() => {
            setSelectedContent('members');
            if (!members) handleGetUsers();
          }}
          style={{ marginBottom: 15 }}
        >
          {i18n.t('Members')}
        </Text>
      </Box>
    </Box>
  );
};

Sidebar.propTypes = {
  selectedContent: PropTypes.string,
  setSelectedContent: PropTypes.func,
  members: PropTypes.arrayOf(PropTypes.shape()),
  handleGetUsers: PropTypes.func
};

Sidebar.defaultProps = {
  selectedContent: false,
  setSelectedContent: null,
  members: [],
  handleGetUsers: null
};

export default Sidebar;
