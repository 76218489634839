/* eslint-disable react/prop-types */
import React from 'react';

import Text from '~/components/atoms/Text';

export const TextPrimary = (text, fontSize) => {
  const style = {
    margin: 0,
    padding: 0
  };

  if (fontSize) style.fontSize = fontSize;

  return (
    <Text color={'primary'} style={style}>
      {' '}
      {text}{' '}
    </Text>
  );
};

export const IconWrapper = ({ color, customColor, onClick, Icon, ...rest }) => {
  const style = { ...rest };

  if (customColor) style.color = customColor;

  return <Icon onClick={onClick} color={color} style={style} />;
};
