import React from 'react';

import { Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';

export const ButtonOutlined = styled(
  ({ pending, height, padding, textTransform, ...otherProps }) => {
    const theme = useTheme();
    return (
      <Button
        {...otherProps}
        variant="outlined"
        style={{
          height,
          borderRadius: height / 2,
          minWidth: 10,
          width: 120,
          padding,
          textTransform,
          backgroundColor: pending
            ? theme.palette.primary.main
            : theme.palette.secondary.main,
          borderColor: theme.palette.secondary.main,
        }}
      />
    );
  }
)``;
