import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Text from '~/components/atoms/Text';
import DashboardBreadcrumbs from '~/components/molecules/DashboardBreadcrumbs';
import PopUpMenu from '~/components/molecules/PopUpMenu';
import StructureCreationPopUp from '~/components/molecules/StructureCreationPopUp';
import ShareContentSpace from '~/components/organisms/ShareContents/ShareContentSpaces';
import AppbarPages from '~/components/templates/AppbarPages';
import Members from '~/components/templates/Members';
import i18n from '~/I18n';
import { findByID, getEnrollments, searchMembers } from '~/services/Space';
import { update as updateRole } from '~/services/user-space-association';
import settings from '~settings';

import Content from './Content';
import Discussion from './Discussion';
import PaperSpaceInformation from './PaperSpaceInformation';
import Sidebar from './Sidebar';
import { useStyles, ShimmerText } from './styles';
import { Box, Modal, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import ShareIcon from '@material-ui/icons/Share';
import { Skeleton } from '@material-ui/lab';

export default function Space() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const { id } = useParams();

  const [selectedContent, setSelectedContent] = useState('content');
  const [spaceDetails, setSpaceDetails] = useState(null);
  const [showSpaceInformation, setShowSpaceInformation] = useState(false);
  const [environmentDetails, setEnvironmentDetails] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const LectureShimmer = () => {
    return Array(4)
      .fill(null)
      .map((shimmer, index) => (
        <Skeleton key={index} className={classes.shimmerItem} variant="rect" />
      ));
  };

  useEffect(() => {
    findByID(id)
      .then((space) => {
        setSpaceDetails(space);
        setEnvironmentDetails(space.environment);
      })
      .catch(console.log);
  }, [id]);

  const image =
    spaceDetails &&
    spaceDetails.thumbnails &&
    spaceDetails.thumbnails.length > 0 &&
    spaceDetails.thumbnails[0].href
      ? spaceDetails.thumbnails[0].href
      : null;

  const RenderSelectedContent = () => {
    if (selectedContent === 'content' && spaceDetails) {
      return <Content space={spaceDetails} lectureShimmer={LectureShimmer} />;
    }

    if (selectedContent === 'discussion' && spaceDetails) {
      return <Discussion spaceID={id} canPublish={spaceDetails.can_publish} />;
    }

    return (
      spaceDetails && (
        <Members
          structureID={id}
          getEnrollments={getEnrollments}
          updateRole={updateRole}
          searchMembers={searchMembers}
        />
      )
    );
  };

  const linkList = [
    {
      Icon: AddIcon,
      component: (
        <StructureCreationPopUp
          text={i18n.t('CreateSubject.title')}
          structure={{
            name: 'subject',
            parentStructure: {
              name: 'Space',
              id: id
            }
          }}
          structureName={i18n.t('StructuresName.Subjects.SingularLower')}
          redirectLink={`/dashboard/environments/${spaceDetails?.environment.id}/courses/${spaceDetails?.course_id}/spaces/${id}/subjects/new`}
        />
      ),
      style: {
        minWidth: 150,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        columnGap: 10,
        justifyContent: 'flex-start'
      }
    },
    {
      link: `/dashboard/environments/${spaceDetails?.environment.id}/courses/${spaceDetails?.course_id}/spaces/${id}/edit`,
      Icon: EditIcon,
      text: i18n.t('EditSpace.title'),
      disabled: false
    },
    {
      action: (e) => {
        e.preventDefault();
        setOpen(true);
      },
      Icon: ShareIcon,
      text: `Compartilhar ${i18n.t('StructuresName.Space.SingularLower')}`,
      disabled: false
    }
  ];

  useEffect(() => {
    if (spaceDetails) {
      document.title = `${theme.title} | ${spaceDetails.name}`;
    } else {
      document.title = theme.title;
    }
  }, [spaceDetails]);

  return (
    <AppbarPages>
      <Box className={classes.root}>
        <Box className={classes.content}>
          {spaceDetails ? (
            <>
              <Box className={classes.sidebar}>
                <Box
                  style={{
                    marginTop: 32
                  }}
                >
                  <img src={image && `${settings.baseUrl}${image}`} />
                </Box>
                <Sidebar
                  selectedContent={selectedContent}
                  setSelectedContent={setSelectedContent}
                />
              </Box>
              <Box className={classes.infos}>
                <Box className={classes.infosHeader}>
                  <DashboardBreadcrumbs
                    links={[
                      {
                        name: i18n.t('MyEnvironments'),
                        path: '/dashboard/environments'
                      },
                      {
                        name: `${spaceDetails?.environment.name}`,
                        path: `/dashboard/environments/${spaceDetails?.environment.id}`
                      },
                      {
                        name:
                          spaceDetails && spaceDetails.course.name
                            ? spaceDetails.course.name
                            : '...',
                        path: `/dashboard/environments/${spaceDetails?.environment.id}/courses/${spaceDetails?.course_id}`
                      },
                      {
                        name: `${spaceDetails?.name}`,
                        path: '#',
                        last: true
                      }
                    ]}
                  />

                  <Text
                    color="primary"
                    variant="xl"
                    style={{ letterSpacing: 1, fontWeight: 500 }}
                  >
                    {spaceDetails?.name}
                  </Text>

                  <Box className={classes.ambientInformationText}>
                    <InfoIcon
                      style={{ fontSize: 20 }}
                      className={classes.infoIcon}
                    />
                    <Text
                      color="accent"
                      variant="largeTextHover"
                      onClick={() => setShowSpaceInformation(true)}
                    >
                      {i18n.t('InfoSpace')}
                    </Text>
                  </Box>
                  {showSpaceInformation && (
                    <PaperSpaceInformation
                      space={spaceDetails}
                      setShowSpaceInformation={setShowSpaceInformation}
                    />
                  )}
                </Box>

                {spaceDetails?.can_manage && (
                  <PopUpMenu
                    text={i18n.t('ManageDiscipline')}
                    showText
                    linkList={linkList}
                  />
                )}

                <Box className={classes.infosContent}>
                  <Box className={classes.tabBar}>
                    <Sidebar
                      selectedContent={selectedContent}
                      setSelectedContent={setSelectedContent}
                    />
                  </Box>
                  <>{RenderSelectedContent()}</>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box className={classes.sidebar} />
              <Box className={classes.infos}>
                <Box className={classes.infosHeader}>
                  <ShimmerText height={25} width="40%" />
                  <ShimmerText height={50} width="60%" />
                  <ShimmerText height={20} width="40%" />
                  {selectedContent === 'content' && (
                    <Box mt={1}>
                      {' '}
                      <LectureShimmer />
                    </Box>
                  )}
                </Box>
              </Box>
            </>
          )}
        </Box>
        <Modal
          open={open && id}
          onClose={handleClose}
          className={classes.modal}
        >
          <div>
            <ShareContentSpace spaceID={id} />
          </div>
        </Modal>
      </Box>
    </AppbarPages>
  );
}
