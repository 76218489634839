import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Text from '~/components/atoms/Text';
import Field from '~/components/organisms/GenericFormWrapper/Field';
import i18n from '~/I18n';
import { IconWrapper, TextPrimary } from '~/utils/forms';

import BlockOptions from './BlockOptions';
import { useStyles } from './styles';
import {
  Box,
  Collapse,
  Divider,
  Grid,
  Hidden,
  Switch
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

export default function AdvancedOptions() {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const { control, watch } = useFormContext();
  const textOptions = show
    ? 'Ocultar opções avançadas'
    : 'Mostrar opções avançadas';

  const handleClick = () => {
    setShow((show) => !show);
  };

  const renderIcon = (fontsize) => {
    return show ? (
      <IconWrapper
        customColor="#c6c6c7"
        fontSize={fontsize}
        Icon={ExpandLess}
      />
    ) : (
      <IconWrapper
        customColor="#c6c6c7"
        fontSize={fontsize}
        Icon={ExpandMore}
      />
    );
  };

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <Divider />
      </div>

      <div>{TextPrimary('Opções Avançadas', '14px')}</div>

      <Box className={classes.box} px={2} py={1}>
        <div className={`${classes.field} ${classes.click}`}>
          <Grid container alignItems="center" onClick={handleClick}>
            <Hidden xsDown>
              <Grid item> {renderIcon('25px')} </Grid>

              <Grid item>
                {' '}
                <Text className={classes.hideText}>{textOptions}</Text>{' '}
              </Grid>
            </Hidden>

            <Hidden smUp>
              <Grid item container justifyContent="center">
                {' '}
                {renderIcon('50px')}{' '}
              </Grid>
            </Hidden>
          </Grid>
        </div>

        <Collapse in={show}>
          <div className={classes.field}>
            <BlockOptions />
          </div>
          <div>
            <Grid className={classes.switch} item>
              <Field
                label={TextPrimary('Comentários privados')}
                labelPlacement={'end'}
                control={control}
                checked={watch('private_mural')}
                name="private_mural"
                size="small"
                Component={Switch}
              />
            </Grid>
            <Grid container direction="column">
              {watch('lectureable.type') != 'exercise' && (
                <Grid className={classes.switch} item>
                  <Field
                    label={TextPrimary(
                      `Atribuir frequência em ${i18n.t(
                        'StructuresName.Lectures.SingularLower'
                      )} automaticamente?`
                    )}
                    labelPlacement={'end'}
                    control={control}
                    name="markable"
                    checked={watch('markable')}
                    size="small"
                    Component={Switch}
                  />
                </Grid>
              )}
              <Grid className={classes.switch} item>
                <Field
                  label={TextPrimary('Anexo obrigatório')}
                  labelPlacement={'end'}
                  checked={watch('mandatory_status_file')}
                  control={control}
                  name="mandatory_status_file"
                  size="small"
                  Component={Switch}
                />
              </Grid>
              <Grid className={classes.switch} item>
                <Field
                  label={TextPrimary('Habilitar mural')}
                  labelPlacement={'end'}
                  control={control}
                  name="mural_enabled"
                  checked={watch('mural_enabled')}
                  size="small"
                  Component={Switch}
                />
              </Grid>
              <Grid className={classes.switch} item>
                <Field
                  label={TextPrimary('Permitir download de anexos')}
                  labelPlacement={'end'}
                  checked={watch('downloadable_status')}
                  control={control}
                  name="downloadable_status"
                  checked={watch('downloadable_status')}
                  size="small"
                  Component={Switch}
                />
              </Grid>
              <Grid className={classes.switch} item>
                <Field
                  label={TextPrimary('Enviar notificação')}
                  labelPlacement={'end'}
                  control={control}
                  checked={watch('notification')}
                  name="notification"
                  checked={watch('notification')}
                  size="small"
                  Component={Switch}
                />
              </Grid>
            </Grid>
          </div>
        </Collapse>
      </Box>
    </div>
  );
}
