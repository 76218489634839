import {
  hasCustomAccessTo,
  hasManageAccessTo,
  hasReadAccessTo
} from './accessControl';

const MIRRORED_ENVIRONMENT_URL = 'mirrored_environments/';

export const hasManageAccessToMirroredEnvironment = async (
  mirroredEnvironmentID
) =>
  await hasManageAccessTo(
    `${MIRRORED_ENVIRONMENT_URL}${mirroredEnvironmentID}`
  );
export const hasReadAccessToMirroredEnvironment = async (
  mirroredEnvironmentID
) =>
  await hasReadAccessTo(`${MIRRORED_ENVIRONMENT_URL}${mirroredEnvironmentID}`);
export const hasUpdateAccessToMirroredEnvironment = async (
  mirroredEnvironmentID
) =>
  await hasCustomAccessTo(
    `${MIRRORED_ENVIRONMENT_URL}${mirroredEnvironmentID}`,
    'update'
  );
