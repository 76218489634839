import React from 'react';

import { getInformationListReport } from '~/services/reports/environments';

import DownloadReportButton from '../DownloadReportButton';
import { useStyles } from './styles';
import PropTypes from 'prop-types';

export default function InformationListReport({id}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DownloadReportButton
        getReport={getInformationListReport}
        baseName="lista_de_informacao_estruturas_ambiente_"
        id={id}
      />
    </div>
  );
}

  InformationListReport.propTypes = {
    id: PropTypes.string
  };
  
  InformationListReport.defaultProps = {
    id: ''
  };