import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'hidden',
    paddingRight: 20,
    '@media (max-width: 600px)': {
      padding: '0 15px',
      alignItems: 'center'
    }
  },
  rootModal: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'hidden',
    '@media (max-width: 600px)': {
      alignItems: 'center'
    }
  },
  title: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '60px 0 30px 0px',
    '@media (max-width: 600px)': {
      padding: '20px 0px'
    }
  },
  titleModal: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0px',
    '@media (max-width: 600px)': {
      padding: '10px 0px'
    }
  },
  backIcon: {
    fontSize: 30,
    position: 'relative',
    right: 200
  },
  subtitle: {
    padding: '10px 0px 5px 0px'
  },
  content: {
    overflowY: 'auto',
    flex: 1,
    borderRadius: '10px',
    backgroundColor: '#F6F6F6',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: '30px 60px',
    marginBottom: '20px',
    '@media (max-width: 830px)': {
      width: '100%',
      padding: '30px 30px'
    }
  },
  contentModal: {
    overflowY: 'auto',
    flex: 1,
    borderRadius: '10px',
    backgroundColor: '#F6F6F6',
    padding: '0px',
    marginBottom: '20px',
    '@media (max-width: 830px)': {
      width: '100%'
    }
  },
  link: {
    color: (props) => props.theme.palette.primary.main
  },
  numberedList: {
    marginLeft: 20,
    '& li': {
      listStyle: 'decimal',
      fontSize: 14,
      color: (props) => props.theme.palette.textActiveElement.main,
      paddingRight: 5
    }
  },
  bulletList: {
    marginLeft: 20,
    '& li': {
      listStyle: 'disc',
      color: (props) => props.theme.palette.textActiveElement.main
    }
  }
}));

export const Strong = styled.strong`
  color: rgba(0, 0, 0, 0.9);
  font-weight: bold;
`;
