import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import CropIcon from '@material-ui/icons/Crop';
import DeleteIcon from '@material-ui/icons/Delete';
import AvatarEditor from '../../AvatarEditor';
import Text from '~/components/atoms/Text';
import { useStyles } from './styles';

export default function PreviewBar({
  selectedFile,
  setSelectedFile,
  fileUrl,
  handleEdit,
  handleDelete,
  formatFileSize,
  editImage,
  setEditImage
}) {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" className={classes.root}>
      {editImage && (
        <AvatarEditor
          size={100}
          image={fileUrl}
          setSelectedFile={setSelectedFile}
          selectedFile={selectedFile}
          editImage={true}
          setEditImage={setEditImage}
        />
      )}

      <Grid item xs={8} container alignItems="center">
        <img
          src={fileUrl}
          alt={selectedFile.name}
          className={classes.imagePreview}
        />
        <div>
          <Text className={classes.imageName} variant="mediumText">
            {selectedFile.name}
          </Text>
          <Text className={classes.imageSize} variant="mediumText">
            {formatFileSize(selectedFile.size)}
          </Text>
        </div>
      </Grid>
      <Grid item xs={4} container justifyContent="flex-end">
        <IconButton className={classes.iconButton} onClick={handleEdit}>
          <CropIcon className={classes.cropIcon} />
        </IconButton>
        <IconButton className={classes.iconButton} onClick={handleDelete}>
          <DeleteIcon className={classes.deleteIcon} />
        </IconButton>
      </Grid>
    </Grid>
  );
}
