import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';
import LeftDrawer from '~/components/Badges/Drawer/LeftDrawer';
import AppBar from '~/components/molecules/AppBar';
import DashboardBreadcrumbs from '~/components/molecules/DashboardBreadcrumbs';
import LectureDependencyAlert from '~/components/molecules/LectureDependencyAlert';
import LectureExpiredAlert from '~/components/molecules/LectureExpiredAlert';
import Accordion from '~/components/templates/Accordion';
import i18n from '~/I18n';
import api from '~/services/api';
import { Creators as LecturesActions } from '~/store/ducks/lectures';
import { Creators as UserActions } from '~/store/ducks/user';
import { IconWrapper } from '~/utils/forms';

import AudioLecture from './AudioLecture';
import DocumentLecture from './DocumentLecture';
import DownloadDocumentButton from './DownloadDocumentButton';
import ExerciseLecture from './ExerciseLecture';
import { markAsDone } from './fetch';
import FormLecture from './FormLecture';
import LectureDirectionButton from './LectureDirectionButton';
import Mural from './Mural';
import NextLectures from './NextLectures';
import PageLecture from './PageLecture';
import { NavigationButtonsShimmer, useStyles } from './styles';
import VideoLecture from './VideoLecture';
import {
  Box,
  ThemeProvider,
  Grid,
  Hidden,
  Tooltip,
  useTheme
} from '@material-ui/core';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import { Skeleton } from '@material-ui/lab';

export default function Lecture() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const history = useHistory();
  const { profile } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { id } = useParams();

  const [open, setOpen] = useState(false);
  const [lecture, setLecture] = useState(null);
  const [breadcrumbsObj, setBreadcrumbsObj] = useState([]);
  const [finalized, setFinalized] = useState(false);
  const [lectureDependencyError, setLectureDependencyError] = useState(false);
  const [expiredLectureError, setExpiredLectureError] = useState(false);

  const { getProfileRequest } = UserActions;
  const { handleCurrentLectureSet } = LecturesActions;

  function getProfile() {
    dispatch(getProfileRequest());
  }

  useEffect(() => {
    if (!profile) getProfile();
    setLecture(null);
    setLectureDependencyError(false);
    setExpiredLectureError(false);
    async function hanleGetLecture() {
      try {
        const response = await api.get(`/api/lectures/${id}`);
        dispatch(handleCurrentLectureSet(id));
        setLecture(response.data);
      } catch (e) {
        if (e.response && e.response.status === 404) {
          history.push('/page-not-found');
        } else if (e.response && e.response.status === 401) {
          e.response.data.lecture_dependency && setLectureDependencyError(true);
          e.response.data.expired && setExpiredLectureError(true);
          setLecture(e.response.data);
        }
      }
    }

    hanleGetLecture();

    return () => {
      setOpen(false);
      setLecture(null);
    };
  }, [id]);

  useEffect(() => {
    if (lecture) {
      setFinalized(lecture.user_completion);
      if (
        lecture.type !== 'Exercise' &&
        lecture.type !== 'Form' &&
        lecture.markable &&
        !lecture.user_completion &&
        !lecture.can_manage
      ) {
        markAsDone(lecture.id)
          .then(setFinalized(true))
          .catch(console.log);
      }
    }
  }, [lecture]);

  function toggleLeftDrawer() {
    setOpen(!open);
  }

  function BreadcrumbsShimmer() {
    return (
      <Box className={classes.infos}>
        <Skeleton height={20} width="60%" />
        <Skeleton height={60} width="80%" />
      </Box>
    );
  }

  function renderLecture() {
    if (!lecture) return <Skeleton variant="rect" width="100%" height="40vh" />;
    if (expiredLectureError)
      return <LectureExpiredAlert expirationDate={lecture.expiration_date} />;
    if (lectureDependencyError)
      return <LectureDependencyAlert lastLecture={lecture.last_lecture} />;
    if (
      lecture.type === 'Seminar' &&
      lecture.lectureable.resource_type === 'audio'
    )
      return <AudioLecture lecture={lecture} />;
    if (lecture.type === 'Seminar') return <VideoLecture lecture={lecture} />;
    if (lecture.type === 'Page') return <PageLecture lecture={lecture} />;
    if (lecture.type === 'Exercise')
      return <ExerciseLecture lecture={lecture} />;
    if (lecture.type === 'Form') return <FormLecture lecture={lecture} />;
    if (lecture.type === 'Document')
      return <DocumentLecture lecture={lecture} />;

    return false;
  }

  useEffect(() => {
    if (lecture) {
      document.title = `${theme.title} | ${i18n.t(
        'StructuresName.Lectures.SingularUpper'
      )} "${lecture.name}"`;

      setBreadcrumbsObj([
        { name: i18n.t('MyEnvironments'), path: '/dashboard/environments' },
        {
          name: lecture.environment_name || '...',
          path: `/dashboard/environments/${lecture.environment_id}`
        },
        {
          name: lecture.course_name,
          path: `/dashboard/environments/${lecture.environment_id}/courses/${lecture.course_id}`
        },
        {
          name: lecture.space_name,
          path: `/dashboard/spaces/${lecture.space_id}`,
          state: {
            environmentName: lecture.environment_name || '...',
            environmentPath: `/dashboard/environments/${lecture.environment_id}`
          }
        },
        { name: `${lecture.name}`, path: '#' }
      ]);
    } else {
      document.title = theme.title;
    }
  }, [lecture]);

  const DoneOption = () => {
    if (lecture.can_manage) return <></>;
    if (finalized) {
      return (
        <Grid style={{ display: 'flex' }} container alignItems="center">
          <Grid item style={{ marginRight: '3px' }}>
            <IconWrapper
              fontSize={25}
              customColor="green"
              Icon={DoneRoundedIcon}
            />
          </Grid>
          <Grid item>
            <Text style={{ color: 'green' }} variant={'mediumText'}>
              {' '}
              Finalizada{' '}
            </Text>
          </Grid>
        </Grid>
      );
    } else if (lecture?.type !== 'Exercise' && lecture?.type !== 'Form') {
      return (
        <ButtonSquare
          width="125px"
          height="35px"
          variant="outlined"
          color={theme.palette.secondary.main}
          border={`0.5px solid ${theme.palette.secondary.main}`}
          onClick={() =>
            markAsDone(lecture?.id)
              .then(() => setFinalized(true))
              .catch(console.log)
          }
        >
          <Text variant={'mediumText'} color="secondary">
            Finalizar {`${i18n.t('StructuresName.Lectures.SingularLower')}`}
          </Text>
        </ButtonSquare>
      );
    } else {
      return <></>;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.root}>
        <AppBar profile={profile} setOpen={toggleLeftDrawer} />
        <LeftDrawer open={open} setOpen={toggleLeftDrawer} />
        <Box className={classes.content}>
          <Hidden smDown>
            {lecture && <NextLectures lecture={lecture} isMobile={false} />}
          </Hidden>
          <Box className={classes.mainArea}>
            {lecture && profile ? (
              <Box className={classes.infos}>
                <DashboardBreadcrumbs links={breadcrumbsObj} />
                <Box className={classes.pageTitle}>
                  <Tooltip
                    title={lecture.name.length > 65 ? lecture.name : ''}
                    arrow
                    placement="bottom"
                    classes={{ tooltip: classes.customTooltip }}
                  >
                    <Box>
                      <Text
                        noWrap
                        color="primary"
                        variant="xl"
                        style={{ letterSpacing: 1, fontWeight: 500 }}
                      >
                        {lecture.name}
                      </Text>
                    </Box>
                  </Tooltip>
                  {lecture.can_read && <DoneOption />}
                  {lecture ? (
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '15px 0 5px 0'
                      }}
                    >
                      <LectureDirectionButton lecture={lecture} />
                      {lecture.type === 'Document' && lecture.can_download && (
                        <DownloadDocumentButton lecture={lecture} />
                      )}
                    </Box>
                  ) : (
                    <NavigationButtonsShimmer />
                  )}
                </Box>
              </Box>
            ) : (
              <BreadcrumbsShimmer />
            )}
            <Box className={classes.lectureDataContainer}>
              {renderLecture()}
              <Hidden mdUp>
                <Box className={classes.mobileMuralArea}>
                  {lecture?.mural_enabled && (
                    <Accordion
                      title="Mural"
                      lecture
                      keepBorderBottom
                      borderBottomColor={theme.palette.primary.main}
                    >
                      <Mural lecture={lecture} />
                    </Accordion>
                  )}
                </Box>
                {lecture && (
                  <>
                    {/* <Accordion
                    title="Materiais de apoio"
                    lecture
                    keepBorderBottom
                    borderBottomColor={theme.palette.primary.main}
                  >
                    <SupportMaterials />
                  </Accordion> */}
                    <Accordion
                      keepBorderBottom
                      borderBottomColor={theme.palette.primary.main}
                      title="Mídias"
                      lecture
                    >
                      <NextLectures lecture={lecture} isMobile={true} />
                    </Accordion>
                  </>
                )}
              </Hidden>
            </Box>
          </Box>
          <Box className={classes.webMuralArea}>
            {lecture?.mural_enabled && <Mural lecture={lecture} />}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
