import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AvatarProfile from '~/components/atoms/Avatar';
import Text from '~/components/atoms/Text';
import history from '~/services/history';
import { Creators as UserActions } from '~/store/ducks/user';

import { ShimmerText, useStyles } from './styles';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function HeaderSideNav ({ headerprofile, profile }) {
  const dispatch = useDispatch();
  const classes = useStyles({ pageprofile: !!headerprofile });
  const [user, setUser] = useState(null);
  const { remember } = useSelector((state) => state.auth);
  const { getProfileRequest } = UserActions;

  function removePersist () {
    if (!remember) {
      localStorage.removeItem('persist:redu');
    }
  }

  useEffect(() => {
    dispatch(getProfileRequest());
    window.addEventListener('beforeunload', () => {
      removePersist();
    });
  }, [dispatch, getProfileRequest]);

  useEffect(() => {
    setUser(profile);
  }, [profile]);

  return (
    <Box
      color={headerprofile ? 'white' : null}
      className={`${classes.root} ${classes.top}`}
    >
      <Box
        onClick={() => history.push({ pathname: `/profile/${user?.id}` })}
        style={{ cursor: 'pointer' }}
      >
        <Box className={classes.avatarArea}>
          <AvatarProfile profile={user} size={110} />
        </Box>

        <Box className={classes.avatarAreaTablet}>
          <AvatarProfile profile={user} size={48} />
        </Box>

        <Box className={classes.userNames}>
          {!user ? (
            <Box className={classes.root}>
              <ShimmerText />
              <ShimmerText />
            </Box>
          ) : (
            <Box className={`${classes.root} ${classes.textContainer}`}>
              <Text variant="lg" color={headerprofile ? 'primary' : 'white'}>
                {`${user.first_name} ${user.last_name}`}
              </Text>
              <Text variant="username" color={headerprofile ? 'gray' : 'white'}>
                {`@${user.login}`}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

HeaderSideNav.propTypes = {
  headerprofile: PropTypes.bool,
  profile: PropTypes.shape()
};

HeaderSideNav.defaultProps = {
  headerprofile: null,
  profile: null
};
