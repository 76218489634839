import React, { useEffect, useState } from 'react';

import Text from '~/components/atoms/Text';
import { IconWrapper } from '~/utils/forms';

import { downloadReport } from './fetch';
import { useStyles, StyledTableCell, StyledTableRow } from './styles';
import { Button, Grid, useTheme } from '@material-ui/core';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileDownload from 'js-file-download';

export default function SubjectLecturesReport({ subjects }) {
  const theme = useTheme();
  const classes = useStyles();

  const [rows, setRows] = useState([]);

  const processDownloadReport = (subject) => {
    downloadReport(subject.id)
      .then((file) =>
        FileDownload(
          file,
          `${subject.name}_relatorio_de_conclusao_de_mídias.xls`
        )
      )
      .catch(console.log);
  };

  const renderActions = (subject) => {
    return (
      <Button
        style={{ backgroundColor: theme.palette.primary.main, color: 'white' }}
        onClick={() => processDownloadReport(subject)}
      >
        <Grid container justifyContent="center" spacing={1} alignItems="center">
          <Grid item>
            <Text
              style={{ margin: 0, padding: 0, textTransform: 'none' }}
              variant="mediumText"
              color="white"
            >
              {' '}
              Baixar{' '}
            </Text>
          </Grid>
          <Grid item>
            <IconWrapper color="white" fontSize={22} Icon={GetAppIcon} />
          </Grid>
        </Grid>
      </Button>
    );
  };

  useEffect(() => {
    var newRows = [];

    for (var item of subjects) {
      newRows.push({
        key: item.id,
        name: item.name,
        actions: renderActions(item)
      });
    }

    setRows(newRows);
  }, [subjects]);

  return (
    <TableContainer component={Paper}>
      <Table
        stickyHeader
        className={classes.table}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell>Chave</StyledTableCell>
            <StyledTableCell>Nome</StyledTableCell>
            <StyledTableCell align="center">Ações</StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.key}>
              <StyledTableCell component="th" scope="row">
                {row.key}
              </StyledTableCell>
              <StyledTableCell>{row.name}</StyledTableCell>
              <StyledTableCell align="center">{row.actions}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
