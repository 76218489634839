import React from 'react';
import { useSelector } from 'react-redux';

import Avatar from '~/components/atoms/Avatar';
import Text from '~/components/atoms/Text';
import LecturesSummary from '~/components/organisms/LecturesSummary';
import TabBar from '~/components/TabBar';
import Welcome from '~/components/Welcome';

import { Container, useStyles } from './styles';
import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export default function Main() {
  const classes = useStyles();
  const { profile } = useSelector((state) => state.user);
  const theme = useTheme();
  return (
    <Container
      id="scrollableOverviewDiv"
      scollbarThumbCOlor={theme.palette.secondary.main}
    >
      {profile && (
        <Box className={classes.avatarContainer}>
          <Avatar profile={profile} size={100} />
          <Text variant="lg" color="primary">
            {`${profile.first_name} ${profile.last_name}`}
          </Text>
          <Text variant="username" color="gray">
            {`@${profile.login}`}
          </Text>
        </Box>
      )}
      <Welcome />
      <LecturesSummary />
      <TabBar target="scrollableOverviewDiv" />
    </Container>
  );
}
