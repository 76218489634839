import React, { useEffect, useState } from 'react';

import { Box, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';

import Text from '~/components/atoms/Text';
import {
  TextLecture,
  ExerciseIcon,
  VideoLecture,
  ApresentationLecture
} from '~/components/Icons/Lecture';
import api from '~/services/api';
import history from '~/services/history';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';

import { useStyles } from './styles';

export default function LectureItem({ item, profileId }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [progress, setProgress] = useState({});

  useEffect(() => {
    const getProgress = async () => {
      try {
        const response = await api.get(`/api/users/${profileId}/progress`, {
          params: { lectures_ids: [item.id] }
        });
        setProgress(response.data[0]);
      } catch {}
    };
    if (item && item.id && !progress) getProgress();
  }, [item]);

  function renderIcon() {
    let Icon;
    const { type } = item;

    if (type === 'Seminar') Icon = VideoLecture;
    else if (type === 'Document') Icon = ApresentationLecture;
    else if (type === 'Exercise') Icon = ExerciseIcon;
    else if (type === 'Page') Icon = TextLecture;
    else if (type === 'Form')
      return (
        <AssignmentRoundedIcon style={{ fontSize: '60px', color: 'white' }} />
      );
    else return null;

    return <Icon width={70} height={70} color={'white'} />;
  }

  function goToLecture() {
    if (!item) return;
    const { id, environment_id, environment_name, space_id, space_name } = item;

    history.push({
      pathname: `/dashboard/lecture/${id}`,
      state: {
        environmentPath: `/dashboard/environments/${environment_id}`,
        environmentName: environment_name,
        spacePath: `/dashboard/spaces/${space_id}`,
        spaceName: space_name,
        spaceId: space_id,
        progressId: progress.id
      }
    });
  }

  function timeToString(time) {
    const timeTypeOf = typeof time === 'number';
    return timeTypeOf && (time < 10 ? `0${time}` : `${time}`);
  }

  function getFormattedDuration() {
    const { duration } = item;
    const hours = timeToString(parseInt(duration / 3600));
    const minutes = timeToString(parseInt((duration % 3600) / 60));
    const seconds = timeToString((duration % 3600) % 60);

    let format = '';
    if (hours > 0) format += hours + ':';
    format += minutes + ':' + seconds;
    return format;
  }

  return (
    <Box
      className={item ? classes.root : classes.shimmerRoot}
      onClick={goToLecture}
    >
      {item ? (
        <>
          <Box className={`${classes.iconContainer} ${classes.iconBackground}`}>
            {renderIcon()}
            {item.duration && (
              <Box className={classes.bottomRow}>
                <Box className={classes.duration}>
                  <Text variant="text" color="white">
                    {getFormattedDuration()}
                  </Text>
                </Box>
              </Box>
            )}
          </Box>
          <Box className={classes.lectureTitle}>
            <Text variant="lectureTitle" color="gray" lineHeight={1.2}>
              {item.name}
            </Text>
          </Box>
        </>
      ) : (
        <>
          <Skeleton variant="rect" className={classes.iconContainer} />
          <Skeleton
            variant="rect"
            className={classes.lectureTitle}
            height={15}
          />
        </>
      )}
      {/* <Box className={classes.lectureDescription}>
        <Text variant="text" color="gray">{description}</Text>
      </Box> */}
    </Box>
  );
}

LectureItem.propTypes = {
  item: PropTypes.shape(),
  profileId: PropTypes.number
};

LectureItem.defaultProps = {
  item: null,
  profileId: undefined
};
