import React from 'react';
import { clarity } from 'react-microsoft-clarity';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import settings from '~settings';
import themes from '~themes';

import FloatButtons from './components/molecules/FloatButtons';
import VLibras from './components/VLibras/index';
import { CustomSnackbarProvider } from './contexts/customSnackBar';
import Routes from './routes';
import history from './services/history';
import { store, persistor } from './store';
import GlobalStyle from './styles/global';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { PersistGate } from 'redux-persist/integration/react';
import './I18n';

export default function App() {
  document.title = themes.title;
  clarity.init(settings.clarityKey);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <Router history={history}>
          <GlobalStyle />
          <ThemeProvider theme={themes}>
            <CustomSnackbarProvider>
              <SnackbarProvider
                maxSnack={5}
                style={{ fontSize: 14, marginBottom: 2 }}
                autoHideDuration={7000}
              >
                <VLibras />
                <Routes />
                <FloatButtons />
              </SnackbarProvider>
            </CustomSnackbarProvider>
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
}
