import React, { useMemo } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';

import ButtonHorizontalIcon from '~/components/atoms/ButtonHorizontalIcon';

import * as S from './styles';
import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonIcon from '@material-ui/icons/Person';
import PropTypes from 'prop-types';

const ContextualBar = ({
  structureID,
  selectedMembersList,
  setSelectedMembersList,
  members,
  totalMembers,
  loadingStructureMembersDetails,
  roleOfSelectedMembers,
  openRoleChangeModal,
  openDeleteConfirmationModal
}) => {
  const theme = useTheme();
  const classes = S.useStyles({ theme });
  let history = useHistory();
  let { url } = useRouteMatch();
  let location = useLocation();
  const isUpperMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isDownMediumScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const isEditMember =
    window.location.hostname === 'avamecinterativo.mec.gov.br' ||
    window.location.hostname === 'beta.ava.viitra.in' ||
    window.location.hostname === 'localhost';

  const handleSelectedAllMembers = async () => {
    setSelectedMembersList(
      selectedMembersList.length === 0 ? [...members] : []
    );
  };

  const isAllSelected = useMemo(
    () => selectedMembersList.length === members.length,
    [selectedMembersList, members]
  );
  const isIndeterminate = useMemo(
    () =>
      selectedMembersList.length > 0 &&
      selectedMembersList.length < members.length,
    [selectedMembersList, members]
  );

  const editMemberNavigate = () => {
    if (selectedMembersList.length === 1 && isEditMember) {
      history.push({
        pathname: `${url}/members/${selectedMembersList[0]?.user?.id}?structureID=${structureID}&memberID=${selectedMembersList[0]?.user?.id}`,
        state: { background: location }
      });
    }
  };

  return (
    <>
      <Box className={classes.container}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Checkbox
            size="medium"
            className={classes.contextualBarCheckbox}
            color="default"
            checked={isAllSelected}
            indeterminate={isIndeterminate}
            onClick={handleSelectedAllMembers}
          />
          <S.Labels marginBottom={-2}>Selecionar todos</S.Labels>
        </Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {loadingStructureMembersDetails ? (
            <S.Loading />
          ) : (
            <>
              <S.SelctedMembers>{`${selectedMembersList.length} - ${members.length} de ${totalMembers}`}</S.SelctedMembers>
              {isUpperMediumScreen && <S.Labels>Membros</S.Labels>}
            </>
          )}
        </Box>
        {isUpperMediumScreen && (
          <Box style={{ display: 'flex', gap: '32px' }}>
            <ButtonHorizontalIcon
              disabled={selectedMembersList.length === 0}
              disableRipple
              onClick={openDeleteConfirmationModal}
              Icon={DeleteIcon}
              title="Remover"
            />
            <ButtonHorizontalIcon
              disabled={
                selectedMembersList.length === 0 || !roleOfSelectedMembers
              }
              disableRipple
              onClick={openRoleChangeModal}
              Icon={PersonIcon}
              title="Alterar Papel"
            />
            {isEditMember &&
              !(url.includes('spaces') || url.includes('courses')) && (
                <ButtonHorizontalIcon
                  disabled={
                    selectedMembersList.length > 1 ||
                    selectedMembersList.length === 0
                  }
                  disableRipple
                  onClick={editMemberNavigate}
                  Icon={PersonIcon}
                  title="Editar membro"
                />
              )}
          </Box>
        )}
      </Box>
      {isDownMediumScreen && (
        <Box className={classes.contextualButtonsOnSmallScreen}>
          <ButtonHorizontalIcon
            disabled={selectedMembersList.length === 0}
            disableRipple
            onClick={openDeleteConfirmationModal}
            Icon={DeleteIcon}
            title="Remover"
          />
          <ButtonHorizontalIcon
            disabled={
              selectedMembersList.length === 0 || !roleOfSelectedMembers
            }
            disableRipple
            onClick={openRoleChangeModal}
            Icon={PersonIcon}
            title="Alterar Papel"
          />
          {isEditMember &&
            !(url.includes('spaces') || url.includes('courses')) && (
              <ButtonHorizontalIcon
                disabled={
                  selectedMembersList.length > 1 ||
                  selectedMembersList.length === 0
                }
                disableRipple
                onClick={editMemberNavigate}
                Icon={PersonIcon}
                title="Editar membro"
              />
            )}
        </Box>
      )}
    </>
  );
};

ContextualBar.propTypes = {
  selectedMembersList: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string,
      user: PropTypes.shape({
        id: PropTypes.number
      })
    })
  ),
  setSelectedMembersList: PropTypes.func,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string
    })
  ),
  structureID: PropTypes.number,
  totalMembers: PropTypes.number,
  loadingStructureMembersDetails: PropTypes.bool,
  roleOfSelectedMembers: PropTypes.oneOfType([PropTypes.string, null]),
  openRoleChangeModal: PropTypes.func,
  openDeleteConfirmationModal: PropTypes.func
};

export default ContextualBar;
