import React from 'react';
import { useFormContext } from 'react-hook-form';

import Text from '~/components/atoms/Text';
import Input from '~/components/atoms/TextInput';
import ToggleComponent from '~/components/molecules/ToggleComponent';
import { TextPrimary } from '~/utils/forms';

import Field from '../../GenericFormWrapper/Field';
import { Collapse, Switch } from '@material-ui/core';

export default function EnrollmentLimit() {
  const { watch } = useFormContext();
  return (
    <div>
      <ToggleComponent
        name="enrollment_limit_attributes"
        label="Limite de Inscrições"
      >
        <Field
          name="enrollment_limit_attributes.user_limit_enabled"
          label={TextPrimary('Habilitar limite de inscrições')}
          labelPlacement={'end'}
          checked={watch('enrollment_limit_attributes.user_limit_enabled')}
          Component={Switch}
        />
        <Collapse in={watch('enrollment_limit_attributes.user_limit_enabled')}>
          <div>
            <Field
              name="enrollment_limit_attributes.user_limit"
              label={<Text color={'gray'}>Limite de Inscrições</Text>}
              Component={Input}
              placeholder="Ex: 35"
              type="number"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
            />
          </div>
        </Collapse>
      </ToggleComponent>
    </div>
  );
}