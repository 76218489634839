import { makeStyles, Typography, CircularProgress } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#F2F2F7',
    padding: '4px 16px 4px 16px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '32px',
    height: 'max-content',
    width: '99%',
    [theme.breakpoints.down('sm')]: {
      padding: '4px 8px 4px 8px'
    }
  },
  contextualBarCheckbox: {
    color: '#676D82',
    transform: 'scale(1.4)',
    height: '16px',
    width: '16px',
    marginRight: '8px'
  },
  contextualBarButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '14px',
    color: '#676D82',
    cursor: 'pointer',
    '&:disabled': {
      color: '#B4BDD3',
      cursor: 'auto'
    }
  },
  contextualButtonsOnSmallScreen: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '16px',
    padding: '0px 4px'
  }
}));

export const SelctedMembers = styled(Typography)`
  font-size: 14px;
  color: #676d82;
  margin-right: 4px;
`;

export const Labels = styled(Typography)`
  ${({ marginBottom = 0 }) => css`
    font-size: 14px;
    color: #676d82;
    margin-bottom: ${marginBottom};
  `}
`;

export const Loading = styled(CircularProgress)`
  height: 16px !important;
  width: 16px !important;
`;
