const isNotUndefined = (bool) => {
  return !(bool === undefined);
};

const setAttrs = (data) => {
  return {
    toggle: {
      description: isNotUndefined(data.real.description),
      initials: isNotUndefined(data.real.initials),
      minimum_rate_for_completion: isNotUndefined(
        data.real.minimum_rate_for_completion
      ),
      name: isNotUndefined(data.real.name),
      path: isNotUndefined(data.real.path),
      city: isNotUndefined(data.real.city),
      state: isNotUndefined(data.real.state),
      regional_group: isNotUndefined(data.real.regional_group),
      zoom:
        isNotUndefined(data.real.zoom_api_key) &&
        isNotUndefined(data.real.zoom_api_secret),
      email_reminder: isNotUndefined(data.real.send_completion_reminder_mail),
      enrollment_link_attributes: isNotUndefined(data.subscription?.link)
    },
    description: data.description,
    initials: data.initials,
    minimum_rate_for_completion: data.minimum_rate_for_completion,
    name: data.name,
    path: data.path,
    city: data.city,
    state: data.state,
    regional_group: data.regional_group,
    parent: {
      id: data.parent.id
    },
    enrollment_link_attributes: {
      generate_link: data.subscription ? true : false,
      link: data.subscription?.link,
      key: data.subscription?.key
    },
    zoom: {
      zoom_api_key: data.zoom_api_key,
      zoom_api_secret: data.zoom_api_secret
    },
    email_reminder: {
      send_completion_reminder_mail: data.send_completion_reminder_mail,
      send_reminder_mail_when_completion_is_below:
        data.send_reminder_mail_when_completion_is_below
    }
  };
};

const defaultAttrs = {
  toggle: {
    avatarFileName: null,
    description: null,
    initials: null,
    minimum_rate_for_completion: 0,
    name: null,
    path: null,
    city: null,
    state: null,
    regional_group: null,
    zoom: null,
    email_reminder: null,
    enrollment_link_attributes: null
  },
  avatarFileName: null,
  description: null,
  initials: null,
  minimum_rate_for_completion: 0,
  // Não pode ser null pois em cada mudança do nome se gera um path
  name: '',
  path: '',
  city: null,
  state: null,
  regional_group: null,
  parent: {
    id: null
  },
  enrollment_link_attributes: {
    generate_link: null,
    link: null,
    key: null
  },
  zoom: {
    zoom_api_key: null,
    zoom_api_secret: null
  },
  email_reminder: {
    send_completion_reminder_mail: null,
    send_reminder_mail_when_completion_is_below: null
  }
};

const handleFormFields = (fields) => {
  const { toggle } = fields;

  var mirroredEnvironment = {};

  for (let k in toggle) {
    if (toggle[k]) {
      mirroredEnvironment[k] = fields[k];
    } else {
      mirroredEnvironment[k] = null;
    }
  }

  mirroredEnvironment = handleNested('zoom', fields, mirroredEnvironment);
  mirroredEnvironment = handleNested(
    'email_reminder',
    fields,
    mirroredEnvironment
  );

  return mirroredEnvironment;
};

const handleNested = (field, fields, mirroredCourse) => {
  let newMirroredCourse = { ...mirroredCourse };

  if (newMirroredCourse[field]) {
    newMirroredCourse = { ...newMirroredCourse, ...newMirroredCourse[field] };
  } else {
    for (let k in fields[field]) {
      newMirroredCourse[k] = null;
    }
  }

  delete newMirroredCourse[field];
  return newMirroredCourse;
};

export { defaultAttrs, setAttrs, handleFormFields };
