import React, { useEffect, useState } from 'react';

import { useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import api from '~/services/api';

import EnvironmentItem from './EnvironmentItem';
import { useStyles } from './styles';

export default function Environments({ userId, loggedInId }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const shimmer = Array(2).fill(null);
  const [loading, setLoading] = useState(true);
  const [environments, setEnvironments] = useState([]);

  function getEnvironments() {
    api
      .get(`api/users/${userId}/environments`)
      .then((response) => {
        setLoading(false);
        setEnvironments(response.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (userId) getEnvironments();

    return () => (setLoading(true), setEnvironments([]));
  }, [userId]);

  function renderEnvironments() {
    if (!environments || !environments.length) {
      return (
        <>
          <div>
            <Text
              variant="largeText"
              color="gray"
              className={classes.noEnvironmentsLarge}
            >
              {i18n.t('NoDescription')}
            </Text>
          </div>
          <div>
            <Text
              className={classes.noEnvironmentsSmall}
              variant="text"
              color="gray"
            >
              {i18n.t('NoDescription')}
            </Text>
          </div>
        </>
      );
    }
    return (
      <ul className={classes.environmentsList}>
        {environments.map(
          (environment, idx) =>
            environment && (
              <EnvironmentItem
                key={idx}
                environment={environment}
                profileId={userId}
                loggedInId={loggedInId}
              />
            )
        )}
      </ul>
    );
  }

  return (
    <div className={classes.container} container md={7}>
      <div style={{ marginBottom: '2rem' }}>
        <Text variant="header" color="primary">
          {i18n.t('Environments')}
        </Text>
      </div>
      {!loading ? (
        renderEnvironments()
      ) : (
        <div className={classes.outerShimmer}>
          {shimmer.map((s, index) => (
            <div key={index} className={classes.shimmerContainer}>
              <Skeleton
                variant="rect"
                width="25rem"
                height="15rem"
                className={classes.lgShimmer}
              />
              <Skeleton
                variant="rect"
                width="30rem"
                height="17rem"
                className={classes.smShimmer}
              />
              <div className={classes.shimmerText}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height="20px"
                  style={{ marginBottom: '1rem' }}
                />
                <Skeleton variant="rect" width="100%" height="15px" />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

Environments.propTypes = {
  userId: PropTypes.number,
  loggedInId: PropTypes.number
};

Environments.defaultProps = {
  userId: 0,
  loggedInId: 0
};
