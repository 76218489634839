import api from '~/services/api';

export const updateMirroredSpace = async (mirroredSpaceID, body) => {
  const { data } = await api.put(
    `/api/mirrored_spaces/${mirroredSpaceID}`,
    body
  );

  return data;
};

export const getMirroredSpace = async (mirroredSpaceID) => {
  const { data } = await api.get(`api/mirrored_spaces/${mirroredSpaceID}`);

  return data;
};

export const deleteMirroredSpace = async (mirroredSpaceID) => {
  const { data } = await api.delete(`api/mirrored_spaces/${mirroredSpaceID}`);

  return data;
};
