import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    '& ::-webkit-scrollbar': {
      width: '5px'
    },

    '& ::-webkit-scrollbar-track': {
      background: 'none'
    },

    '& ::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.main,
      bordeRadius: '2px'
    }
  },
  outerContainer: {
    flexGrow: 1,
    paddingTop: '20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto'
  },
  content: {
    display: 'flex',
    overflow: 'auto',
    flexGrow: 1,
    flexDirection: 'column',
    width: 950,
    '@media (max-width: 1023px)': {
      width: 550
    },
    '@media (max-width: 750px)': {
      width: '95%'
    }
  }
}));
