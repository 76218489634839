import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme, Box, TextField, Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

import helpIconFilled from '~/assets/icons/help-icon-filled.png';
import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';
import TextArea from '~/components/atoms/TextArea';
import FormattedText from '~/components/atoms/FormattedText';
import MuralItem from '~/components/molecules/MuralItem';
import i18n from '~/I18n';
import api from '~/services/api';
import history from '~/services/history';

import BreadcrumbMuralItem from '../../BreadcrumbMuralItem';
import {
  useStyles,
  TimeDistance,
  BreadcrumbsCustom,
  CustomAvatar
} from '../styles';

function Help({ item, dashboard, lecture }) {
  const [answers, setAnswers] = useState(null);
  const [hideAnswers, setHideAnswers] = useState(true);
  const [answerText, setAnswerText] = useState('');
  const [reply, setReply] = useState(false);
  const { links, created_at: createdAt, text, breadcrumbs } = item;
  const { first_name, last_name, id: userId } = item.user || {
    first_name: 'Usuário deletado',
    last_name: '',
    id: null
  };
  const userProfile = item.user || {};
  const styleProps = { reply, dashboard, lecture };
  const theme = useTheme();
  const classes = useStyles(styleProps, theme);
  const loggedInUser = useSelector((state) => state.user);

  async function getAnswers() {
    try {
      const response = await api.get(`api/statuses/${item.id}/answers`);
      setAnswers(response.data);
    } catch (error) { }
  }

  async function postAnswer() {
    try {
      await api.post(`api/statuses/${item.id}/answers`, {
        status: { text: answerText }
      });
      setAnswerText('');
      setReply(false);
      getAnswers();
    } catch (error) { }
  }

  function handleClickAnswerButton() {
    if (answerText.trim().length) {
      postAnswer();
    }
  }

  useEffect(() => {
    if (item.answers_count && item.answers_count >= 1) {
      getAnswers();
    }
  }, [item]);

  function goToLecture() {
    if (item && item.breadcrumbs) {
      const { lecture_id } = item.breadcrumbs.lecture || {};
      const { space_id, space_name } = item.breadcrumbs.space || {};
      const { environment_id, environment_name } =
        item.breadcrumbs.environment || {};

      history.push({
        pathname: `/dashboard/lecture/${lecture_id}`,
        state: {
          environmentPath: `/dashboard/environments/${environment_id}`,
          environmentName: environment_name,
          spacePath: `/dashboard/spaces/${space_id}`,
          spaceName: space_name,
          spaceId: space_id
        }
      });
    }
  }

  return (
    <Box className={classes.outerContainer}>
      {!lecture && <BreadcrumbMuralItem breadcrumbs={breadcrumbs} />}
      <Box className={classes.itemContainer}>
        <Box className={classes.itemContainerAvatar}>
          <CustomAvatar profile={userProfile} lecture={lecture} />
          <img
            lecture={lecture}
            className={classes.imageHelp}
            src={helpIconFilled}
            alt="pessoa com mão levantada"
          />
        </Box>
        <Box className={classes.infoContainer}>
          <Box className={classes.actionContainer}>
            <Box className={classes.action}>
              <Link to={userId ? `/profile/${userId}` : '#'}>
                <Text color="accent" variant="actionText">
                  {`${first_name} ${last_name}`}
                </Text>
              </Link>
              {!lecture && (
                <>
                  <Text color="gray" variant="actionText">
                    {i18n.t('helpLecture')}
                  </Text>
                  <Box
                    onClick={goToLecture}
                    style={{ display: 'inline', cursor: 'pointer' }}
                  >
                    <Text color="accent" variant="actionText">
                      {links[links.length - 2].name}
                    </Text>
                  </Box>
                </>
              )}
            </Box>
            <TimeDistance date={createdAt} />
          </Box>
          <Box className={classes.textContainer}>
            <Text color="gray" variant="actionText">
              <FormattedText text={text}/>
            </Text>
          </Box>

          <ButtonSquare
            onClick={() => {
              setReply(true);
            }}
            padding="4px 0"
          >
            <Text color="primary" variant="textBold">
              {i18n.t('Reply')}
            </Text>
          </ButtonSquare>

          {reply && (
            <Box className={classes.replyContainer}>
              <CustomAvatar profile={loggedInUser.profile} lecture={lecture} />
              <Box className={classes.replyContent}>
                <Box className={classes.textFieldContainer}>
                  <TextField
                    id="standard-basic"
                    placeholder={i18n.t('AddReply')}
                    fullWidth
                    className={classes.answerTextField}
                    onChange={(e) => setAnswerText(e.target.value)}
                  />
                </Box>
                <Box className={classes.textAreaContainer}>
                  <TextArea
                    placeholder={i18n.t('AddReply')}
                    width="100%"
                    height="50px"
                    resize="none"
                    onChange={(e) => setAnswerText(e.target.value)}
                  />
                </Box>
                <Box className={classes.replyButtons}>
                  <ButtonSquare
                    width="100px"
                    height="50px"
                    variant="outlined"
                    color={theme.palette.secondary.main}
                    border={`0.5px solid ${theme.palette.secondary.main}`}
                    onClick={() => {
                      setReply(false);
                    }}
                  >
                    <Text variant="sideBarTitle" color="secondary">
                      {i18n.t('Cancel')}
                    </Text>
                  </ButtonSquare>
                  <ButtonSquare
                    width="100px"
                    height="50px"
                    marginLeft="15px"
                    backgroundColor={theme.palette.secondary.main}
                    onClick={() => handleClickAnswerButton()}
                  >
                    <Text variant="sideBarTitle" color="white">
                      {i18n.t('Reply')}
                    </Text>
                  </ButtonSquare>
                </Box>
                <Box className={classes.smReplyButtons}>
                  <Button
                    className={classes.smButtonCancel}
                    classes={{ root: classes.smButton }}
                    onClick={() => {
                      setReply(false);
                    }}
                  >
                    <CloseIcon
                      style={{
                        fill: theme.palette.secondary.main,
                        fontSize: 16
                      }}
                    />
                  </Button>
                  <Button
                    className={classes.smButtonCheck}
                    classes={{ root: classes.smButton }}
                    onClick={() => {
                      handleClickAnswerButton();
                    }}
                  >
                    <CheckIcon style={{ fill: 'white', fontSize: 16 }} />
                  </Button>
                </Box>
              </Box>
            </Box>
          )}

          {answers ? (
            <ButtonSquare
              onClick={() => {
                if (hideAnswers) setHideAnswers(false);
                else setHideAnswers(true);
              }}
              className={classes.hideAnswers}
              padding="4px 0"
            >
              {!hideAnswers ? (
                <FontAwesomeIcon
                  icon={faCaretUp}
                  color={theme.palette.primary.main}
                  style={{
                    fontSize: 22,
                    marginBottom: 2,
                    marginRight: 10
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCaretDown}
                  color={theme.palette.primary.main}
                  style={{
                    fontSize: 22,
                    marginBottom: 2,
                    marginRight: 10
                  }}
                />
              )}
              <Text color="primary" variant="textBold">
                {hideAnswers ? i18n.t('ShowReplies') : i18n.t('HideReplies')}
              </Text>
            </ButtonSquare>
          ) : (
            false
          )}

          {answers && !hideAnswers
            ? answers.map((answer) => (
              <MuralItem key={answer.id} item={answer} lecture={lecture} />
            ))
            : false}
        </Box>
      </Box>
    </Box>
  );
}

Help.propTypes = {
  item: PropTypes.shape(),
  dashboard: PropTypes.bool,
  lecture: PropTypes.bool
};

Help.defaultProps = {
  item: {},
  dashboard: undefined,
  lecture: undefined
};

export default Help;
