/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import history from '~/services/history';
import { getLectures } from '~/services/Subject';

import LectureItem from './LectureItem';
import { useStyles } from './styles';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Hidden,
  ListItem,
  useTheme
} from '@material-ui/core';
import { List } from '@material-ui/core';
import TrueAccordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Skeleton } from '@material-ui/lab';

export default function NextLectures({ lecture }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [lectures, setLectures] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMoreLectures, setHasMoreLectures] = useState(true);
  const [expandedAccordion, setExpandedAccordion] = useState(true);
  const shimmer = Array(3).fill(1);

  const fetchNextLectures = () => {
    if (isFetching || !hasMoreLectures) return;

    setIsFetching(true);

    getLectures(lecture.subject_id, page)
      .then((lecs) => {
        setHasMoreLectures(lecs.length >= 10);
        setLectures((prev) => [...prev, ...lecs]);
        setPage((prev) => prev + 1);
        setIsFetching(false);
      })
      .catch((err) => {
        setIsFetching(false);
        console.log(err);
      });
  };

  const goToLecture = (lectureID) => {
    history.push(`/dashboard/lecture/${lectureID}`);
  };

  const RenderLectures = () => {
    return (
      <List className={classes.listStyle}>
        {lectures.map((item, index) => (
          <ListItem
            key={index}
            className={`${classes.lectureContainer} ${
              index === 2 ? classes.hide : ''
            } ${item.id == lecture.id ? classes.active : ''} ${
              !item.can_read ? classes.inactive : ''
            }`}
            style={{
              width: '100%',
              padding: 5
            }}
            onClick={() => goToLecture(item.id)}
          >
            <LectureItem
              lecture={item}
              spaceName={item.space_name}
              spaceId={item.space_id}
            />
          </ListItem>
        ))}
        {isFetching && <Loading />}
      </List>
    );
  };

  const Loading = () => {
    return (
      <>
        {shimmer.map((_, index) => (
          <Skeleton
            key={index}
            variant="rect"
            className={`${classes.shimmerItem} ${
              index === 2 ? classes.hide : ''
            }`}
          />
        ))}
      </>
    );
  };

  const emptyLectures = () => {
    return (
      <Box className={classes.emptyLectures}>
        <Text variant="largeText" color="gray">
          {i18n.t('noNextClasses')}
        </Text>
      </Box>
    );
  };

  const handleScroll = (e) => {
    if (isFetching || !hasMoreLectures) return;

    const bottom =
      e.target.scrollHeight <= e.target.scrollTop + e.target.offsetHeight + 50;

    if (bottom) {
      fetchNextLectures();
    }
  };

  useEffect(() => {
    fetchNextLectures();
  }, []);

  return (
    <>
      <Box className={classes.helperArea} onScroll={handleScroll}>
        <TrueAccordion
          className={classes.accordion}
          expanded={expandedAccordion}
        >
          <Hidden smDown>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={() => setExpandedAccordion((prev) => !prev)}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Text variant="largeText" color="primary">
                {i18n.t('Classes')}
              </Text>
            </AccordionSummary>
          </Hidden>
          <AccordionDetails>
            <div style={{ width: '100%' }}>
              {!isFetching && lectures.length === 0 ? (
                emptyLectures()
              ) : (
                <RenderLectures />
              )}
            </div>
          </AccordionDetails>
        </TrueAccordion>
      </Box>
    </>
  );
}
