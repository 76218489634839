import React from 'react';

import { useInfiniteScroll } from '~/hooks/useInfiniteScroll';
import { getSubjects } from '~/services/environment';

import Row from './Row';
import RowText from './Row/RowText';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function LecturesReport({ id }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { elements: subjects, anchorRef } = useInfiniteScroll({
    fetchFunction: getSubjects,
    id
  });

  function TitleRow() {
    return (
      <div className={`${classes.row} ${classes.titleRow}`}>
        <RowText style={{ color: 'white' }}>ID</RowText>
        <RowText style={{ color: 'white' }}>Nome</RowText>
        <RowText style={{ color: 'white' }}>Ações</RowText>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <TitleRow />
      {subjects.map((subject) => (
        <Row key={subject.id} subject={subject} />
      ))}
      <div style={{ height: '8px' }} ref={anchorRef}></div>
    </div>
  );
}

LecturesReport.propTypes = {
  id: PropTypes.string
};

LecturesReport.defaultProps = {
  id: ''
};
