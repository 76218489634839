import React from 'react';
import { Link } from 'react-router-dom';

import { NextLectureIcon, PreviousLectureIcon } from '~/components/atoms/Icons';
import i18n from '~/I18n';

import { useStyles } from './styles';
import { useTheme } from '@material-ui/core';

function LectureDirectionButton({ lecture }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const { navigation } = lecture;

  const nextText = navigation.last ? (
    <p>Próximo {`${i18n.t('StructuresName.Subjects.SingularLower')}`}</p>
  ) : (
    <p>Próxima {`${i18n.t('StructuresName.Lectures.SingularLower')}`}</p>
  );
  const previousText = navigation.first ? (
    <p>{`${i18n.t('StructuresName.Subjects.SingularUpper')}`} anterior</p>
  ) : (
    <p>{`${i18n.t('StructuresName.Lectures.SingularUpper')}`} anterior</p>
  );

  return (
    <div className={classes.directionButton}>
      {navigation.previous && (
        <Link
          className={classes.button}
          to={`/dashboard/lecture/${navigation.previous}`}
        >
          <PreviousLectureIcon className={classes.icon} />
          {previousText}
        </Link>
      )}
      {navigation.next && (
        <Link
          className={`${classes.button} ${classes.rightButton}`}
          to={`/dashboard/lecture/${navigation.next}`}
        >
          {nextText}
          <NextLectureIcon className={classes.icon} />
        </Link>
      )}
    </div>
  );
}

export default LectureDirectionButton;
