import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Text from '~/components/atoms/Text';
import Input from '~/components/atoms/TextInput';
import { DatePickerInputForm } from '~/components/molecules/DatePickerInputForm';
import Image from '~/components/molecules/Image';
import ImageCertificateLogoClient from '~/components/molecules/ImageCertificateLogo';
import ToggleComponent from '~/components/molecules/ToggleComponent';
import i18n from '~/I18n';
import { TextPrimary } from '~/utils/forms';
import settings from '~settings';

import Field from '../../GenericFormWrapper/Field';
import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';
import { Collapse, Grid, Switch } from '@material-ui/core';

export default function CertificateInfo () {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { watch, getValues } = useFormContext();

  const [signatureUrl, setSignatureUrl] = useState(null);
  const [certificateLogoClientUrl, setCertificateLogoUrl] = useState(null);

  const isCoursePeriod = getValues('is_course_period');

  const signaturePath = getValues('certificate.signature');
  const certificateLogoClientPath = getValues(
    'certificate.certificate_logo_client'
  );

  const existSignature =
    signaturePath !== '/assets/missing_courses_original.png';

  const existCertificateLogoClient =
    certificateLogoClientPath !== '/assets/missing_courses_original.png';

  const [
    certificateLogoClientLoadError,
    setCertificateLogoClientLoadError
  ] = useState(false);

  const [signatureLoadError, setSignatureLoadError] = useState(false);

  useEffect(() => {
    if (signatureUrl == null && signaturePath) {
      setSignatureUrl(`${settings?.baseUrl}${signaturePath}`);
    }
    if (certificateLogoClientUrl == null && certificateLogoClientPath) {
      setCertificateLogoUrl(`${settings?.baseUrl}${certificateLogoClientPath}`);
    }
  }, [signaturePath, certificateLogoClientPath]);

  return (
    <div>
      <ToggleComponent
        name="certificate"
        label="Ativação de geração de certificado"
      >
        <Field
          label={TextPrimary('Habilitar geração de certificado')}
          name="certificate.generate_certificate"
          labelPlacement={'end'}
          checked={watch('certificate.generate_certificate')}
          Component={Switch}
        />
        <Collapse in={watch('certificate.generate_certificate')}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <div>
                <Field
                  label={<Text color={'gray'}>{'Nome do responsável'}</Text>}
                  name="certificate.responsible_for_signing"
                  placeholder="Insira o nome do responsável pela assinatura"
                  Component={Input}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div>
                <Field
                  name="certificate.signature_owner_position"
                  label={<Text color={'gray'}>{'Cargo do responsável'}</Text>}
                  placeholder="Insira o cargo do responsável pela assinatura"
                  Component={Input}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div>
                <Field
                  name="certificate.signature_owner_organization"
                  label={<Text color={'gray'}>{'Nome da organização'}</Text>}
                  placeholder="Insira o nome da organização"
                  Component={Input}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div>
                <Field
                  name="certificate.minimum_rate_for_completion"
                  label={
                    <Text color={'gray'}>
                      {'Percentual mínimo para certificado'}
                    </Text>
                  }
                  placeholder={`Insira a porcentagem mínima de ${i18n.t(
                    'StructuresName.Lectures.PluralLower'
                  )} assistidas para a geração do certificado`}
                  Component={Input}
                />
              </div>
            </Grid>
            <Grid container spacing={1} className={classes.gridContainer}>
              <Field
                label={TextPrimary('Habilitar/desabilitar período do curso')}
                name="is_course_period"
                labelPlacement={'end'}
                checked={watch('is_course_period')}
                Component={Switch}
              />
              <Grid item xs={12} sm={6}>
                {isCoursePeriod && (
                  <DatePickerInputForm
                    placeholder="Ex: 30/04/2020"
                    name="certificate.course_start_date"
                    dataType="início"
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {isCoursePeriod && (
                  <DatePickerInputForm
                    placeholder="Ex: 30/04/2021"
                    name="certificate.course_end_date"
                    dataType="término"
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.wrapperCertificateLogoComponent}>
                <Box className={classes.wrapperCertificateLogoInput}>
                  <Field
                    name="certificate.signature"
                    label={
                      <Text color={'gray'}>{'Assinatura do responsável'}</Text>
                    }
                    id="signature-image-input"
                    Component={Image}
                  />
                </Box>
                {existSignature && !signatureLoadError && (
                  <Box className={classes.wapperCertificateLogo}>
                    <Text color="gray">{'Imagem atual de assinatura'}</Text>
                    <img
                      src={signatureUrl}
                      className={classes.image}
                      onError={() => {
                        setSignatureLoadError(true);
                      }}
                      onLoad={() => {
                        setSignatureLoadError(false);
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.wrapperCertificateLogoComponent}>
                <Box className={classes.wrapperCertificateLogoInput}>
                  <Field
                    name="certificate.certificate_logo_client"
                    label={
                      <Text color="gray">{'Logo para o certificado'}</Text>
                    }
                    id="certificate-logo-client-image-input"
                    Component={() => <ImageCertificateLogoClient />}
                  />
                </Box>
                {existCertificateLogoClient && !certificateLogoClientLoadError && (
                  <Box className={classes.wapperCertificateLogo}>
                    <Text color="gray">{'Imagem atual'}</Text>
                    <img
                      src={certificateLogoClientUrl}
                      className={classes.imageCertificateLogo}
                      onError={() => {
                        setCertificateLogoClientLoadError(true);
                      }}
                      onLoad={() => {
                        setCertificateLogoClientLoadError(false);
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Collapse>
      </ToggleComponent>
    </div>
  );
}
