import api from '~/services/api';

export const updateMirroredEnvironment = async (
  mirroredEnvironmentID,
  body
) => {
  return await api.put(
    `/api/mirrored_environments/${mirroredEnvironmentID}`,
    body
  );
};

export const getMirroredEnvironment = async (mirroredEnvironmentID) => {
  const { data } = await api.get(
    `api/mirrored_environments/${mirroredEnvironmentID}`
  );
  
  return data;
};

export const deleteMirroredEnvironment = async (mirroredEnvironmentID) => {
  const { data } = await api.delete(`api/mirrored_environments/${mirroredEnvironmentID}`);

  return data;
};
