import React from 'react';

import { CustomAvatar, Container, ShimmerCircle } from './styles';
import PropTypes from 'prop-types';

export default function AvatarProfile({
  disabled,
  size,
  profile,
  ...otherProps
}) {
  return (
    <Container disabled={disabled} size={size + 4} profile={profile}>
      {profile ? (
        <CustomAvatar profile={profile} size={size} {...otherProps} />
      ) : (
        <ShimmerCircle size={size} />
      )}
    </Container>
  );
}

AvatarProfile.propTypes = {
  size: PropTypes.number,
  profile: PropTypes.shape(),
  disabled: PropTypes.bool
};

AvatarProfile.defaultProps = {
  size: 90,
  profile: null,
  disabled: false
};
