import React from 'react';
//import svg, { defs, G, path } from 'react-native-svg';

export const VideoLecture = ({ width, height, color }) => (
    <svg viewBox="0 0 41.407 53" width={width} height={height} fill={color}>
      <defs />
      <path
        className="b"
        d="M4.175,52h0L-1,52V-1H4.175V1.649H9.352V-1h20.7V1.649H35.23V-1h5.177V51.994L35.23,52V49.348H30.052V52H9.352V49.348H4.175V52ZM30.052,38.748v5.3H35.23v-5.3Zm-25.878,0v5.3H9.352v-5.3ZM9.352,28.616h20.7V33.45H35.23V28.616h2.217V25.5H1.957v3.117H4.175V33.45H9.352V28.616Zm20.7-11.066v5.3H35.23v-5.3Zm-25.878,0v5.3H9.352v-5.3Zm25.878-10.6v5.3H35.23v-5.3Zm-25.878,0v5.3H9.352v-5.3Z"
        transform="translate(1 1)"
      />
    </svg>
  );

  export const ExerciseIcon = ({ width, height, color }) => (
    <svg viewBox="0 0 30 30" fill={color} width={width} height={height}>
      <defs />
      <g transform="translate(-354.5 481.758)">
        <g transform="translate(354.5 -481.46)">
          <g transform="translate(2.479 3.998)">
            <path
              className="b"
              d="M17.5,69.238h1.722V54.5L17.5,55.809Z"
              transform="translate(-17.5 -50.972)"
            />
            <path
              className="b"
              d="M143.036,30.815l-1.846-1.846a.28.28,0,0,0-.4,0l-3.445,3.446L134.369,35.4a.3.3,0,0,0-.073.125l-.676,2.534a.267.267,0,0,0-.01.073.136.136,0,0,0,0,.023.263.263,0,0,0,.007.045l.008.025a.181.181,0,0,0,.017.037c0,.008.01.015.014.023a.178.178,0,0,0,.025.032.128.128,0,0,0,.019.019.277.277,0,0,0,.032.025.118.118,0,0,0,.022.014.349.349,0,0,0,.04.018l.022.008a.279.279,0,0,0,.058.008h.011a.276.276,0,0,0,.074-.01l2.521-.689a.275.275,0,0,0,.124-.072l1.326-1.326.551-.551,1.722-1.722,2.829-2.829A.279.279,0,0,0,143.036,30.815Z"
              transform="translate(-117.617 -28.888)"
            />
            <path
              className="b"
              d="M169.423,81.719l-.423.423v9.446l1.722-1.309V80.42l-.748.748Z"
              transform="translate(-148.132 -73.322)"
            />
            <path
              className="b"
              d="M54.9,61.869h-.125l-.066-.012a1.784,1.784,0,0,1-.23-.043c-.07-.018-.127-.04-.158-.051a1.716,1.716,0,0,1-.216-.1,1.269,1.269,0,0,1-.131-.081,1.7,1.7,0,0,1-.187-.147,1.235,1.235,0,0,1-.112-.113,1.706,1.706,0,0,1-.145-.185c-.019-.029-.05-.076-.083-.136a1.615,1.615,0,0,1-.1-.22c-.012-.034-.032-.088-.05-.156a1.47,1.47,0,0,1-.043-.244,1.579,1.579,0,0,1-.01-.172,1.693,1.693,0,0,1,.055-.426l.676-2.534a1.643,1.643,0,0,1,.43-.744L56.41,54.5H52.537a2.614,2.614,0,0,0-2.61,2.479H49.92v.138A2.618,2.618,0,0,0,47.3,54.5H42V69.238h5.3a2.614,2.614,0,0,0,2.61-2.479h.007v-.138a2.618,2.618,0,0,0,2.617,2.617h5.3V61.127l-2.507.685A1.668,1.668,0,0,1,54.9,61.869Z"
              transform="translate(-38.625 -50.972)"
            />
          </g>
        </g>
      </g>
    </svg>
  );

  export const TextLecture = ({ width, height, color }) => (
    <svg viewBox="0 0 53 53" width={width} height={height} fill={color}>
      <defs />
      <g transform="translate(-1)">
        <path
          className="b"
          d="M54.583,6H10.417A4.417,4.417,0,0,0,6,10.417v4.417a4.417,4.417,0,1,0,8.833,0h13.25V50.167a4.417,4.417,0,0,0,0,8.833h8.833a4.417,4.417,0,0,0,0-8.833V14.833h13.25a4.417,4.417,0,0,0,8.833,0V10.417A4.417,4.417,0,0,0,54.583,6Z"
          transform="translate(-5 -6)"
        />
      </g>
    </svg>
  );


export const ApresentationLecture = ({ width, height, color }) => (
    <svg viewBox="0 0 59.759 53" width={width} height={height} fill={color}>
      <defs />
      <path
        className="b"
        d="M6.973,10a2.066,2.066,0,0,0-2.016,1.987,2.066,2.066,0,0,0,2.016,1.987H8.3v31.8H6.973a1.988,1.988,0,1,0,0,3.975H32.81v3.5L22.521,59.292a1.988,1.988,0,1,0,2.029,3.416L34.8,56.684l10.248,6.025a1.988,1.988,0,1,0,2.029-3.416L36.785,53.247v-3.5H62.623a1.99,1.99,0,0,0,.207-3.975,2,2,0,0,0-.207,0H61.3v-31.8h1.325a2.068,2.068,0,0,0,2.091-1.884A2.068,2.068,0,0,0,62.83,10a1.988,1.988,0,0,0-.207,0Zm5.3,3.975h45.05v31.8H12.273Zm33.1,2.609a2.066,2.066,0,0,0-1.967,2.029V41.136a1.988,1.988,0,1,0,3.975,0V18.611a2.068,2.068,0,0,0-2.008-2.029ZM38.09,23.207a2.066,2.066,0,0,0-1.967,2.029v15.9a1.988,1.988,0,1,0,3.975,0v-15.9a2.068,2.068,0,0,0-2.008-2.029ZM30.8,29.832a2.066,2.066,0,0,0-1.967,2.029v9.275a1.988,1.988,0,1,0,3.975,0V31.861A2.068,2.068,0,0,0,30.8,29.832Zm-7.287,5.3a2.066,2.066,0,0,0-1.967,2.029v3.975a1.988,1.988,0,1,0,3.975,0V37.161a2.068,2.068,0,0,0-2.008-2.029Z"
        transform="translate(-4.957 -9.996)"
      />
    </svg>
  );