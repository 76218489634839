import { createGlobalStyle } from 'styled-components';

const globalStyles = createGlobalStyle`
    *
    {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    *:focus{
        outline: 0;
    }
    html,body,#root{
        height: 100%;
        min-width: 360px !important;
        font-size: inherit;
    }
    html
    {
        font-size: 62.5%;
        /* 1rem = 10px */
    }
    body{
        -webkit-font-smoothing: antialised;
        background: #fff;
        text-rendering: optimizeLegebility;
        overflow-x: hidden;
        overflow-y: hidden;
        max-width: 100vw;
        max-height: 100vh;
    }
    input
    {
        border: 0;
        padding: 0 10px 0 10px;
    }
    body,input,button
    {
        font-family: 'Open Sans', sans-serif;
    }
    a
    {
        text-decoration: none
    }
    li
    {
    }
    button
    {
        cursor: pointer;
        background: none;
        border: 0;
    }
    @media (max-width : 600px ) {
        html
        {
            font-size: 22.5%;
        }
    }
    @media (max-width : 960px ) {
        html
        {
            font-size: 32.5%;
        }
    }

    @media (max-width : 1280px ) {
        html
        {
            font-size: 42.5%;
        }
    }
    @media (max-width : 1360px ) {
        html
        {
            font-size: 52.5%;
        }
    }

    ::-webkit-scrollbar {
        width: 5px;
    }


    ::-webkit-scrollbar-track {
        background: none;
    }


    ::-webkit-scrollbar-thumb {
        background: #fff;
        border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    /* DATE PICKER STYLES */
    .MuiPickersCalendarHeader-daysHeader span {
        font-size: 12px !important;
    }

    .MuiPickersCalendarHeader-switchHeader p {
        font-size: 14px !important;
    }

    .MuiPickersCalendar-transitionContainer p {
        font-size: 12px !important;
    }
`;

export default globalStyles;
