import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  buttonContent: {
    display: 'flex',
    alignItems: 'center'
  },

  buttonText: {
    color: 'white',
    fontSize: '16px',
    marginRight: '8px'
  },

  buttonIcon: {
    color: 'white',
    fontSize: '24px',
    marginLeft: '8px'
  }
}));
