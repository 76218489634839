import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '0 5px 10px 0'
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  header: {
    marginBottom: '20px'
  },
  field: {
    marginBottom: '15px'
  },
  listItem: {
    width: '100%',
    maxWidth: 600
  }
}));
