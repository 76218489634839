import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  field: {
    marginBottom: '32px'
  },
  hideText: {
    padding: 0,
    margin:0,
    fontSize: '14px',
    color: '#767677'
  },
  click: {
    cursor: 'pointer',
    marginBottom: '18px'
  },
  box: {
    backgroundColor: '#f6f6fa',
    borderRadius: '5px'
  },
  switch: {
    marginLeft: '-8px'
  }
});
