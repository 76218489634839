import React from 'react';

import { useStyles } from './styles';
import { Typography, useTheme } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import PropTypes from 'prop-types';

const ButtonHorizontalIcon = ({
  Icon,
  title,
  disableRipple,
  onClick,
  disabled
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <ButtonBase
      className={classes.contextualBarButton}
      disabled={disabled}
      disableRipple={disableRipple}
      onClick={onClick}
    >
      <Icon style={{ fontSize: '16px' }} />
      <Typography style={{ fontSize: '14px', fontWeight: 500 }}>
        {title}
      </Typography>
    </ButtonBase>
  );
};

ButtonHorizontalIcon.propTypes = {
  selectedMembersList: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string
    })
  ),
  setSelectedMembersList: PropTypes.func,
  Icon: PropTypes.element,
  openDeleteConfirmationModal: PropTypes.func,
  title: PropTypes.string,
  disableRipple: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default ButtonHorizontalIcon;
