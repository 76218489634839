import React from 'react';

import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(({marginTop, ...otherProps }) => (
  <Box
    {...otherProps}
    display="flex"
    component="div"
    flexDirection="row"
    alignItems="center"
    marginTop={marginTop ? '10px' : 0}
  />
))``;

export const ContainerInfo = styled(({ ...otherProps }) => (
  <Box
    {...otherProps}
    component="div"
    style={{
      paddingLeft: 10,
      width: '100%',
      marginRight: '5px',
      maxHeight: '43px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}
  />
))``;
