/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { useParams, useHistory } from 'react-router-dom';

import DeleteConfirmModal from '~/components/molecules/DeleteConfirmModal';
import EditMirroredCourseForm from '~/components/organisms/EditMirroredCourseForm';
import GenericFormWrapper from '~/components/organisms/GenericFormWrapper';
import MembersManagement from '~/components/organisms/MembersManagement';
import StepperForm from '~/components/templates/StepperForm';
import i18n from '~/I18n';
import courseServices from '~/services/course';
import userCourseAssociationServices from '~/services/user-course-association';
import { handleErrors, handleSuccess } from '~/utils/auxiliaryFunctions';
import { formatDateToDateZeroHour } from '~/utils/dates';

import {
  getMirroredCourse,
  updateMirroredCourse,
  deleteLimit,
  deleteMirroredCourse
} from './fetch';
import {
  defaultAttrs,
  handleFormFields,
  setAttrs,
  updateToggle
} from './setAttrs';
import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

export default function EditMirroredCourse() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { mirroredCourseID } = useParams();
  const history = useHistory();

  const [, setSubmissionStatus] = useState('');
  const [reportURLs, setReportURLs] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [parentEnvironmentID, setParentEnvironmentID] = useState(null);
  const [parentCourseID, setParentCourseID] = useState(null);
  const [, setEnvironmentID] = useState(null);
  const [defaultValues, setDefaultValues] = useState(defaultAttrs);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const openDeleteModal = () => setShowDeleteModal(true);
  const closeDeleteModal = () => setShowDeleteModal(false);

  const successMessage = `O ${i18n.t(
    'StructuresName.Course.SingularUpper'
  )} foi atualizado!`;
  const callbackSuccessPath = `/dashboard/environments/${parentEnvironmentID}/courses/${parentCourseID}`;

  const breadcrumbs = [
    { name: i18n.t('MyEnvironments'), path: '/dashboard/environments' }
  ];

  const navOptions = [i18n.t('Information'), i18n.t('Members')];

  const handleExclude = () => {
    openDeleteModal();
  };

  const handleDeleteConfirm = () => {
    deleteMirroredCourse(mirroredCourseID)
      .then(() => {
        enqueueSnackbar(
          `${i18n.t(
            'StructuresName.Course.SingularUpper'
          )} excluído com sucesso!`,
          { variant: 'success' }
        );

        history.push(`/dashboard/environments/${parentEnvironmentID}`);
      })
      .catch(() => {
        enqueueSnackbar(
          `Erro ao excluir ${i18n.t('StructuresName.Course.SingularLower')}.`,
          { variant: 'error' }
        );
      })
      .finally(() => {
        closeDeleteModal();
      });
  };

  const deleteMessage = () => {
    const message = (
      <>
        O {i18n.t('StructuresName.Course.SingularLower')}{' '}
        <span style={{ fontWeight: 'bold' }}>{defaultValues.name}</span>, que é
        um espelho do curso "{defaultValues.name}", será permanentemente
        excluído, incluindo todos os dados e arquivos. Todos os membros irão
        perder o acesso. O curso original não será afetado.
      </>
    );
    return message;
  };

  function renderContent(props) {
    const { selectedTab } = props;

    if (selectedTab === 0) {
      return (
        <GenericFormWrapper
          Form={EditMirroredCourseForm}
          defaultValues={defaultValues}
          handleExclude={handleExclude}
          excludeTitle="Excluir"
          {...props}
        />
      );
    } else if (selectedTab === 1) {
      return (
        <MembersManagement
          structureID={defaultValues.parent.id}
          structureServices={courseServices}
          userStructureAssociationServices={userCourseAssociationServices}
          reportURLs={reportURLs}
        />
      );
    }

    return false;
  }

  const configMirroredCourse = (data) => {
    setReportURLs(data.report_urls);
    setDefaultValues(setAttrs(data));

    setParentCourseID(data.parent.id);
    setParentEnvironmentID(data.parent.environment.id);

    setEnvironmentID(data.environment_id);
    setIsLoading(false);
  };

  const fetchFailure = () => {
    setIsLoading(false);
    window.alert(
      `Não foi possível carregar o ${i18n.t(
        'StructuresName.Course.SingularUpper'
      )}!`
    );
    handleCancel();
  };

  useEffect(() => {
    if (mirroredCourseID) setIsLoading(true);
    getMirroredCourse(mirroredCourseID)
      .then(configMirroredCourse)
      .catch(fetchFailure);
  }, [mirroredCourseID]);

  function handleCancel() {
    history.push(
      `/dashboard/environments/${parentEnvironmentID}/courses/${parentCourseID}`
    );
  }

  const finalizeSuccess = () => {
    setSubmissionStatus('success');
    handleSuccess(
      successMessage,
      enqueueSnackbar,
      callbackSuccessPath,
      history
    );
  };

  const finalizeFailure = (error) => {
    handleErrors(error, enqueueSnackbar);
    setSubmissionStatus('error');
  };

  function submitForm(fields) {
    if (
      !fields.enrollment_limit_attributes?.user_limit_enabled &&
      defaultValues.enrollment_limit_attributes
    ) {
      deleteLimit(defaultValues.enrollment_limit_attributes.id);
    }

    delete fields.enrollment_link_attributes?.generate_link;
    delete fields.enrollment_link_attributes?.link;
    delete fields.enrollment_limit_attributes?.user_limit_enabled;

    const startDate = formatDateToDateZeroHour(
      fields.certificate.course_start_date
    );
    const endDate = formatDateToDateZeroHour(
      fields.certificate.course_end_date
    );

    var mirroredCourse = handleFormFields(fields);

    mirroredCourse['course_start_date'] = startDate;
    mirroredCourse['course_end_date'] = endDate;

    mirroredCourse['is_course_period'] = fields.is_course_period;
    mirroredCourse['signature'] = fields.signature;
    mirroredCourse['certificate_logo_client'] = fields.certificate_logo_client;

    const toggle = updateToggle(fields.toggle);

    const body = { mirrored_course: { ...mirroredCourse } };

    const formData = new FormData();

    for (const key in body['mirrored_course']) {
      if (
        body['mirrored_course'][key] != null ||
        (body['mirrored_course'][key] === null && key in toggle)
      ) {
        if (key === 'enrollment_link_attributes') {
          continue;
        }
        formData.append(
          `mirrored_course[${key}]`,
          body['mirrored_course'][key]
        );
      }
    }
    if (body['mirrored_course']['enrollment_link_attributes']) {
      const keyLink =
        body['mirrored_course']['enrollment_link_attributes']['key'] || '';
      const validateKeyLinkValue = keyLink === 'undefined' ? '' : keyLink;
      formData.append(
        `mirrored_course[enrollment_link_attributes][key]`,
        validateKeyLinkValue
      );
    }

    updateMirroredCourse(mirroredCourseID, formData)
      .then(finalizeSuccess)
      .catch(finalizeFailure);
  }

  return isLoading ? (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: '100%' }}
    >
      <Grid item>
        <ReactLoading type="cylon" color={theme.palette.primary.main} />
      </Grid>
    </Grid>
  ) : (
    <>
      <DeleteConfirmModal
        show={showDeleteModal}
        handleClose={closeDeleteModal}
        handleConfirm={handleDeleteConfirm}
        maxWidth={440}
        loading={false}
        showButtons={true}
        descriptionMessage={deleteMessage()}
        title={i18n.t('DeleteMirroredCourse.title')}
      />
      <StepperForm
        unclickable={false}
        navOptions={navOptions}
        breadcrumbs={breadcrumbs}
        onSubmit={submitForm}
        formTitle={i18n.t('EditCourse')}
        submitButtonTitle={i18n.t('Save')}
        handleCancel={handleCancel}
        renderContent={renderContent}
      />
    </>
  );
}
