import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Text from '~/components/atoms/Text';
import LectureItem from '~/components/molecules/DashboardLectureItem';
import ClassItem from '~/components/templates/ClassItem';
import i18n from '~/I18n';
import { getSummaryLectures } from '~/services/Lecture';

import { useStyles } from './styles';
import { Box, Grid } from '@material-ui/core';

export default function LecturesSummary() {
  const { profile } = useSelector((state) => state.user);
  const [summaryLectures, setSummaryLectures] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  // minWidth of root depends on amount of classes
  let desktopMinWidth = 0;
  let mobileMinWidth = 0;

  const classes = useStyles({ desktopMinWidth, mobileMinWidth });

  useEffect(() => {
    setIsFetching(true);
    getSummaryLectures()
      .then((lectures) => {
        setIsFetching(false);
        setSummaryLectures(lectures);
      })
      .catch(() => {
        setIsFetching(false);
      });
  }, []);

  // if loading display shimmers
  if (isFetching) {
    const shimmers = Array(4).fill(null);
    const classItemTitle = [i18n.t('lastClass'), i18n.t('nextClasses'), '', ''];

    return (
      <div className={`${classes.outerBox} next-lectures-tour`}>
        <div className={classes.scroller}>
          <div className={classes.root}>
            {shimmers.map((shimmer, index) => (
              <Box className={index == 3 ? classes.hide : ''} key={index}>
                <ClassItem title={classItemTitle[index]}>
                  <LectureItem item={null} />
                </ClassItem>
              </Box>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`${classes.outerBox} next-lectures-tour`}>
      <div className={classes.scroller}>
        <div className={classes.root}>
          <Grid container spacing={1} justifyContent="center">
            {summaryLectures[0] && (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                justifyContent="center"
                style={{ flexBasis: '0' }}
              >
                <ClassItem title={i18n.t('lastClass')}>
                  <LectureItem
                    item={summaryLectures[0]}
                    profileId={profile.id}
                  />
                </ClassItem>
              </Grid>
            )}
            {summaryLectures.slice(1, 4).map((item, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={index}
                  justifyContent="center"
                  style={{ flexBasis: '0' }}
                >
                  <ClassItem
                    key={index}
                    title={index === 0 ? i18n.t('nextClasses') : ''}
                  >
                    <LectureItem item={item} profileId={profile.id} />
                  </ClassItem>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
      {summaryLectures.length === 0 && !isFetching && (
        <div className={classes.root}>
          <ClassItem title={i18n.t('lastClass')}>
            <Text style={{ marginTop: 25 }} variant="text" color="gray">
              {i18n.t('EmptyLectureSumaryList')}
            </Text>
          </ClassItem>
        </div>
      )}
    </div>
  );
}
