import settings from '~settings';

export const welcomeMessages = () => {
  const client = settings.name;

  switch (settings.client) {
    case 'munera':
      return {
        title: 'Bem-vindo(a) à Editora Munera!',
        subtitle: `conhecimento transformando o futuro`
      };
    default:
      return {
        title: 'Aprender nunca foi tão fácil.',
        subtitle: `Olá! A plataforma ${client} dá as boas-vindas a você, que agora faz parte da
    nossa rede de construção do conhecimento.`
      };
  }
};
