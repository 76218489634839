import api from '~/services/api';

const baseURL = '/api/environments';

const getSimplifiedSubjectsReport = async (ID) => {
  const { data } = await api.get(
    `${baseURL}/${ID}/simplified_subjects_report`,
    { responseType: 'blob' }
  );
  return data;
};

const getPerformanceReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/create_report`, {
    responseType: 'blob'
  });
  return data;
};

const getUsersReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/environment_users_report`, {
    responseType: 'blob'
  });
  return data;
};

const getConclusionReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/conclusion_report`, {
    responseType: 'blob'
  });
  return data;
};

const getInformationListReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/information_list_report`, {
    responseType: 'blob'
  });
  return data;
};

const getUsersRolesReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/users_roles_report`, {
    responseType: 'blob'
  });
  return data;
};

export {
  getSimplifiedSubjectsReport,
  getPerformanceReport,
  getUsersReport,
  getConclusionReport,
  getInformationListReport,
  getUsersRolesReport
};
