import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import MuralTemplate from '~/components/templates/Mural';
import api from '~/services/api';

export default function Following({ target }) {
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(false);

  const { profile } = useSelector((state) => state.user);

  function getFollowing() {
    if (profile) {
      setLoading(true);
      api
        .get(`/api/users/${profile.id}/following`, {
          params: {
            page: page
          }
        })
        .then((response) => {
          if (response.data.length < 10) {
            setHasMore(false);
          }
          if (response.data.length > 0) {
            setStatuses([...statuses, ...response.data]);
            setPage(page + 1);
          }
          setLoading(false);
        })
        .catch(() => {
          setHasMore(false);
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    getFollowing();
  }, [profile]);

  return (
    <MuralTemplate
      profile={profile}
      loading={loading}
      statuses={statuses}
      getStatuses={getFollowing}
      isDashboard
      hasMore={hasMore}
      target={target}
      setStatuses={setStatuses}
    />
  );
}

Following.propTypes = {
  target: PropTypes.string
};

Following.defaultProps = {
  target: ''
};
