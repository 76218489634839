import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useTheme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';

import AvatarProfile from '~/components/atoms/Avatar';
import ButtonOutlined from '~/components/atoms/ButtonOutlined';
import Text from '~/components/atoms/Text';
import { sideBarAvatarSize } from '~/constants/styles';
import i18n from '~/I18n';
import api from '~/services/api';

import { Container, ContainerInfo } from './styles';

export default function FriendshipSuggestion({ item, marginTop }) {
  const [pending, setPending] = useState(false);
  const { profile } = useSelector((state) => state.user);
  const theme = useTheme();

  function requestConnection() {
    api
      .post(`/api/users/${profile.id}/connections`, {
        connection: {
          contact_id: item.user.id
        }
      })
      .then((response) => { })
      .catch((error) => {
        setPending(false);
      });
  }

  function deleteConnection() {
    api
      .delete(`/api/connections/${item.user.id}`, {
        connection: item.user.id
      })
      .then(() => { })
      .catch(() => {
        setPending(false);
      });
  }

  function onHandleConect() {
    if (pending) {
      setPending(false);
      deleteConnection();
    } else {
      setPending(true);
      requestConnection();
    }
  }

  return (
    <Container marginTop={marginTop}>
      <AvatarProfile size={sideBarAvatarSize} profile={item} disabled />
      {item ? (
        <>
          <ContainerInfo>
            <Text variant="text" color="white">
              {`${item.user.first_name} ${item.user.last_name}`}
            </Text>
            
          </ContainerInfo>

          <ButtonOutlined
            height={26}
            onClick={onHandleConect}
            pending={pending}
            padding="0px 20px 5px 20px"
            textTransform="capitalize"
          >
            <Text color={pending ? 'secondary' : 'white'}>
              {pending
                ? i18n.t('Friendship.pending')
                : i18n.t('Friendship.connect')}
            </Text>
          </ButtonOutlined>
        </>
      ) : (
        <Skeleton
          variant="rect"
          width="100%"
          height={16}
          animation="pulse"
          style={{
            backgroundColor: theme.palette.shimmer.main,
            marginLeft: '1.5em'
          }}
        />
      )}
    </Container>
  );
}

FriendshipSuggestion.propTypes = {
  item: PropTypes.shape(),
  marginTop: PropTypes.bool
};

FriendshipSuggestion.defaultProps = {
  item: null,
  marginTop: false
};
