import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import DateTimePickerInput from '~/components/atoms/DateTimePickerInput';
import ToggleComponent from '~/components/molecules/ToggleComponent';
import Field from '~/components/organisms/GenericFormWrapper/Field';
import { TextPrimary } from '~/utils/forms';
import i18n from '~/I18n';

import { useStyles } from './styles';
import { Collapse, Grid, Switch } from '@material-ui/core';

function Scheduleable() {
  const classes = useStyles();
  const { control, watch, setValue } = useFormContext();

  useEffect(() => {
    setValue('toggle.subject_closure', watch('subject_closure'));
    setValue('toggle.subject_opening', watch('subject_opening'));
  }, [watch('subject_closure'), watch('subject_opening')]);

  return (
    <ToggleComponent
      name="scheduleable"
      label={`Abertura e fechamento do ${i18n.t('StructuresName.Subjects.SingularLower')}`}
    >
      <Grid container>
        <Grid item xs={12} className={classes.switch}>
          <Field
            label={TextPrimary(`Programar abertura e fechamento do ${i18n.t('StructuresName.Subjects.SingularLower')}`)}
            labelPlacement={'end'}
            name="scheduleable"
            control={control}
            checked={watch('scheduleable')}
            Component={Switch}
            size="small"
          />
        </Grid>

        <Collapse in={watch('scheduleable')}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={{ xs: 12, sm: null }}>
                <Field
                  label={TextPrimary('Início')}
                  name="subject_opening"
                  control={control}
                  Component={DateTimePickerInput}
                />
              </Grid>

              <Grid item xs={{ xs: 12, sm: null }}>
                {/* Acrescenta uma hora ao Time atual */}
                <Field
                  label={TextPrimary('Fim')}
                  name="subject_closure"
                  control={control}
                  defaultValue={new Date(Date.now() + 3600000)}
                  Component={DateTimePickerInput}
                />
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </ToggleComponent>
  );
}

export default Scheduleable;
