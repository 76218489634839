import React        from 'react';
import GetAppIcon   from '@material-ui/icons/GetApp';
import fileDownload from 'js-file-download';
import Text         from '~/components/atoms/Text';

import { Button, useTheme } from '@material-ui/core';
import { IconWrapper }      from '~/utils/forms';
import { downloadReport }   from './fetch';

export default function FormConclusionReport({ space }){

  const theme = useTheme()

  const processDownloadReport = () => {
    downloadReport(space.id).then( file => fileDownload(file, `relatorio_de_conclusão_dos_questionários_${space.name}.xls`) ).catch(console.log)
  }

  return (
    <Button style={{ border: `1px solid ${theme.palette.primary.main}`, width: 160, height: 60 }} onClick={processDownloadReport}>
      <Text style={{ textTransform: 'none' }} color="primary" variant="subtitle"> Baixar </Text>
      <IconWrapper color="primary" fontSize={30} Icon={GetAppIcon}/>
    </Button>
  )
}