import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import Input from '~/components/atoms/TextInput';
import ToggleComponent from '~/components/molecules/ToggleComponent';
import { TextPrimary } from '~/utils/forms';
import i18n from '~/I18n';

import Field from '../../GenericFormWrapper/Field';
// Campos por ora desativados
// import Keywords from '../Keywords';
// import Image from './Image';
import { useStyles } from './styles';
import { Grid } from '@material-ui/core';

export default function MainInfo() {
  const classes = useStyles();
  const { watch, setValue } = useFormContext();

  const parametrizeString = (inputString) => {
    // Remove special characters, replace accents, and remove spaces
    const normalizedString = inputString
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Remove accents
      .replace(/[^a-zA-Z0-9]+/g, '-') // Replace other non-alphanumeric characters and spaces with "-"
      .replace(/-+/g, '-') // Remove multiple consecutive "-"
      .replace(/^-|-$/g, ''); // Remove "-" at the beginning and end

    // Replace 'ç' with 'c'
    const parametrizedString = normalizedString.replace(/ç/g, 'c');

    return parametrizedString.toLowerCase(); // Convert to lowercase and return the final string
  };

  useEffect(() => {
    setValue('path', parametrizeString(watch('name')));
  }, [watch('name')]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={8}>
          <div className={classes.field}>
            <ToggleComponent name="name" label="Nome">
              <Field
                label={TextPrimary('Nome *')}
                name="name"
                Component={Input}
                placeholder={`Digite o nome do ${i18n.t(
                  'StructuresName.Environment.SingularLower'
                )}`}
              />
            </ToggleComponent>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.field}>
            <ToggleComponent name="initials" label="Abreviação">
              <Field
                label={TextPrimary('Abreviação *')}
                name="initials"
                Component={Input}
                placeholder={`Digite uma sigla para o ${i18n.t(
                  'StructuresName.Environment.SingularLower'
                )}`}
              />
            </ToggleComponent>
          </div>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={8} className={classes.field}>
          <ToggleComponent name="path" label="Endereço">
            <Field
              label={TextPrimary('Endereço *')}
              name="path"
              Component={Input}
              placeholder="Insira um endereço web"
            />
          </ToggleComponent>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={8} className={classes.field}>
          <ToggleComponent
            name="minimum_rate_for_completion"
            label="Percentual para certificado"
          >
            <Field
              label={TextPrimary('Percentual para certificado')}
              name="minimum_rate_for_completion"
              Component={Input}
              placeholder="Insira um percentual mínimo para geração do certificado"
              type="number"
            />
          </ToggleComponent>
        </Grid>
      </Grid>

      {/* Por enquanto não faz sentido editar esse campo para ambientes espelhados */}

      {/* <Grid container>
        <Grid item xs={12} lg={6} className={classes.field}>
          <Keywords />
        </Grid>
      </Grid> */}

      <Grid container>
        <Grid item xs={12} lg={9} className={classes.field}>
          <ToggleComponent name="description" label="Descrição">
            <Field
              label={TextPrimary('Descrição')}
              name="description"
              Component={Input}
              placeholder={`Informações do ${i18n.t(
                'StructuresName.Environment.SingularUpper'
              )}...`}
              multiline
            />
          </ToggleComponent>
        </Grid>
      </Grid>

      {/* Desativado até tratar esse atributo espelhado a nível de backend */}
      {/* <Grid container>
        <Grid item xs={12} lg={9} className={classes.field}>
          <Image />
        </Grid>
      </Grid> */}
    </>
  );
}
