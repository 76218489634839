import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '2rem',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
  },
  content: {
    color: theme.palette.textActiveElement.main,
    marginTop: '1.5rem',
    fontSize: '1.3rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
}))