import api from '~/services/api';

export const updateMirroredCourse = async (mirroredCourseID, formData) => {
  const { data } = await api.put(
    `/api/mirrored_courses/${mirroredCourseID}`,
    formData
  );

  return data;
};

export const getMirroredCourse = async (mirroredCourseID) => {
  const { data } = await api.get(`api/mirrored_courses/${mirroredCourseID}`);
  return data;
};

export const deleteLimit = async (limitID) => {
  const { data } = await api.delete(`api/enrollment_limits/${limitID}`);

  return data;
};

export const deleteMirroredCourse = async (mirroredCourseID) => {
  const { data } = await api.delete(`api/mirrored_courses/${mirroredCourseID}`);

  return data;
};
