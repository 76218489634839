import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Avatar from '~/components/atoms/Avatar';
import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import api from '~/services/api';
import settings from '~settings';

import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

export default function ChatContactInfo ({
  open,
  handleClose,
  contact,
  isModal
}) {
  const theme = useTheme();
  const classes = useStyles({ theme, isModal });

  const { profile } = useSelector((state) => state.user);
  const { description } = profile;
  const [commonCourses, setCommonCourses] = useState([]);

  useEffect(() => {
    api
      .get(
        `/api/users/${profile.id}/contacts/${contact.contact_id}/common_courses`
      )
      .then((response) => {
        setCommonCourses(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Box
      className={`${isModal ? classes.container : classes.drawer} ${!open ? classes.closed : ''
        }`}
    >
      <Box className={classes.closeButtonContainer}>
        <button type="button" onClick={handleClose}>
          {isModal ? (
            <ArrowBackIcon className={classes.icon} />
          ) : (
            <CloseIcon className={classes.icon} />
          )}
        </button>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.infoHeaderBox}>
          <Avatar
            size={120}
            profile={{
              id: contact?.contact_id,
              first_name: contact?.name,
              thumbnails: contact?.contact_attributes?.thumbnails
            }}
            disabled
          />
          <Box className={classes.nameInfo}>
            <Text variant="welcomeText" color="primary">
              {contact?.name}
            </Text>
            <Text variant="text" color="gray">
              {`@${contact?.login}`}
            </Text>
          </Box>
        </Box>
        <Box className={classes.descriptionContainer}>
          <Text variant="text" color="black">
            {/* {description} */}
          </Text>
        </Box>
        <Box className={classes.coursesContent}>
          <Text variant="welcomeText" color="primary">
            {i18n.t('CommonCourses')}
          </Text>
          <Box>
            {commonCourses.map((course, index) => (
              <Box key={index} className={classes.courseItem}>
                <Box className={classes.courseThumb}>
                  <img
                    className={classes.courseThumb}
                    src={
                      course.environment &&
                        course.environment.thumbnails &&
                        course.environment.thumbnails.length > 2
                        ? `${settings.baseUrl}${course.environment.thumbnails[2].href}`
                        : ''
                    }
                  />
                </Box>
                <Box className={classes.courseInfo}>
                  <Text variant="largeText" color="primary">
                    {course.name}
                  </Text>
                  <Text lineHeight={1.4} variant="text" color="gray">
                    {course.description}
                  </Text>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

ChatContactInfo.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  contact: PropTypes.shape(),
  isModal: PropTypes.bool
};

ChatContactInfo.defaultProps = {
  open: 'false',
  handleClose: null,
  contact: {},
  isModal: false
};
