import React from 'react';

import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import Text from '~/components/atoms/Text';

import { useStyles } from './styles';

export default function ClassItem({ title, children }) {
  const { root, titleBox } = useStyles();
  return (
    <Box className={root}>
      <Box className={titleBox}>
        {title && (
          <Text
            variant="classItemTitle"
            color="primary"
            style={{
              whiteSpace: 'nowrap'
            }}
          >
            {title}
          </Text>
        )}
      </Box>
      {children}
    </Box>
  );
}

ClassItem.propTypes = {
  title: PropTypes.string,
  children: PropTypes.shape()
};

ClassItem.defaultProps = {
  title: '',
  children: null
};
