import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import useStyles from './styles';
import {
  Button,
  Popover,
  List,
  ListItem,
  ListItemText,
  Radio,
  FormControlLabel,
  FormGroup
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DoneIcon from '@material-ui/icons/Done';

const orderOptions = [
  { label: 'Padrão', value: '' },
  { label: 'Ordem Alfabética', value: 'name' },
  { label: 'Data de Modificação', value: 'updated_at' },
  { label: 'Data de Criação', value: 'created_at' }
];

const directionOptions = [
  { label: 'Crescente', value: 'asc' },
  { label: 'Decrescente', value: 'desc' }
];

function OrderByPopover() {
  const { setValue, watch } = useFormContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOrderChange = (event, value) => {
    setValue('ord', value);
  };

  const handleDirectionChange = (event, value) => {
    if (watch('ord') !== '') {
      setValue('direction', value);
    }
  };

  useEffect(() => {
    if (watch('ord') === '') {
      setValue('direction', '');
    }
  }, [watch('ord')]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const renderRadioButton = (option, type) => {
    const isSelected =
      watch('ord') === option.value ||
      (watch('direction') === option.value && watch('direction') !== '');

    return (
      <FormControlLabel
        key={option.label}
        control={
          <Radio
            checked={isSelected}
            onChange={(e) =>
              type === 'order'
                ? handleOrderChange(e, option.value)
                : handleDirectionChange(e, option.value)
            }
            style={{ display: 'none' }}
          />
        }
        label={
          <div
            className={`${classes.controlLabelContainer} ${
              isSelected ? classes.selectedBackground : ''
            }`}
          >
            {option.label}
            {isSelected && <DoneIcon color="primary" />}
          </div>
        }
        className={classes.customLabel}
        style={{
          width: '100%',
          margin: 0,
          padding: 0,
          justifyContent: 'space-between'
        }}
      />
    );
  };

  return (
    <div>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        className={classes.button}
      >
        {watch('ord') === ''
          ? 'Ordenar por'
          : orderOptions.find((option) => option.value === watch('ord')).label}
        <ArrowDropDownIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <List component="nav" aria-label="Ordenar opções">
          <ListItem className={classes.listItem}>
            <ListItemText
              primary="Ordenar por"
              className={classes.listSectionTitle}
            />
            <div className={classes.separator} />
            <FormGroup className={classes.group}>
              {orderOptions.map((option) => renderRadioButton(option, 'order'))}
            </FormGroup>
          </ListItem>
          <ListItem className={classes.listItem} disabled={watch('ord') === ''}>
            <ListItemText
              primary="Direção"
              className={classes.listSectionTitle}
            />
            <div className={classes.separator} />
            <FormGroup className={classes.group}>
              {directionOptions.map((option) =>
                renderRadioButton(option, 'direction')
              )}
            </FormGroup>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
}

export default OrderByPopover;
