import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import DateTimePickerInput from '~/components/atoms/DateTimePickerInput';
import Field from '~/components/organisms/GenericFormWrapper/Field';
import i18n from '~/I18n';
import { TextPrimary } from '~/utils/forms';

import { useStyles } from './styles';
import {
  Box,
  Collapse,
  Grid,
  Switch,
  Typography,
  useTheme
} from '@material-ui/core';

function BlockOptions() {
  const theme = useTheme();
  const classes = useStyles();
  const { control, watch, setValue } = useFormContext();
  const [show, setshow] = useState(
    Boolean(watch('id') && watch('expiration_date'))
  );

  const handleChange = () => {
    setshow((prev) => {
      if (!prev) {
        setValue('expiration_date', new Date());
      } else {
        setValue('expiration_date', null);
      }

      return !prev;
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} className={classes.switch}>
        <Box display={'flex'} alignItems={'center'}>
          <Switch checked={show} onChange={handleChange} size="small" />
          <Typography
            style={{
              fontSize: 14,
              color: theme.palette.primary.main,
              textWrap: 'nowrap'
            }}
          >
            {`Configurar bloqueio de ${i18n.t(
              'StructuresName.Lectures.SingularUpper'
            )}`}
          </Typography>
        </Box>
      </Grid>

      <Collapse in={show}>
        <Grid item xs={12}>
          <Field
            label={TextPrimary('Bloquear a partir de')}
            name="expiration_date"
            control={control}
            Component={DateTimePickerInput}
          />
        </Grid>
      </Collapse>
    </Grid>
  );
}

export default BlockOptions;
