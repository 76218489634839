import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Text from '~/components/atoms/Text';
import CircularProgressWithLabel from '~/components/molecules/CircularProgressWithLabel';
import AudioMiniCard from '~/components/molecules/LectureTypesMiniCard/AudioMiniCard';
import CloneMiniCard from '~/components/molecules/LectureTypesMiniCard/CloneMiniCard';
import ExerciseMiniCard from '~/components/molecules/LectureTypesMiniCard/ExerciseMiniCard';
import ImageMiniCard from '~/components/molecules/LectureTypesMiniCard/ImageMiniCard';
import MultimediaMiniCard from '~/components/molecules/LectureTypesMiniCard/MultimediaMiniCard';
import PresentationMiniCard from '~/components/molecules/LectureTypesMiniCard/PresentationMiniCard';
import QuestionaryMiniCard from '~/components/molecules/LectureTypesMiniCard/QuestionaryMiniCard';
import VideoCallMiniCard from '~/components/molecules/LectureTypesMiniCard/VideoCallMiniCard';
import VideoMiniCard from '~/components/molecules/LectureTypesMiniCard/VideoMiniCard';
import YoutubeMiniCard from '~/components/molecules/LectureTypesMiniCard/YoutubeMiniCard';
import Field from '~/components/organisms/GenericFormWrapper/Field';
import i18n from '~/I18n';
import { IconWrapper, TextPrimary } from '~/utils/forms';

import Content from './Content';
import Audio from './Forms/Audio';
import Clone from './Forms/Clone';
import Exercise from './Forms/Exercise';
import Image from './Forms/Image';
import Multimedia from './Forms/Multimedia';
import Presentation from './Forms/Presentation';
import Questionary from './Forms/Questionary';
import Video from './Forms/Video';
import VideoCall from './Forms/VideoCall';
import Youtube from './Forms/Youtube';
import * as S from './styles';
import TabPanel from './TabPanel';
import { Box, Switch } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import HeadsetIcon from '@material-ui/icons/Headset';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import InfoIcon from '@material-ui/icons/Info';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import TheatersRoundedIcon from '@material-ui/icons/TheatersRounded';
import TitleRoundedIcon from '@material-ui/icons/TitleRounded';
import VideoCallRoundedIcon from '@material-ui/icons/VideoCallRounded';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { PropTypes } from 'prop-types';
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function Lectureable({
  videoUploadProgressBarShow,
  progressVideoUpload
}) {
  const allLectureableTypes = [
    {
      icon: TitleRoundedIcon,
      form: Multimedia,
      miniCard: MultimediaMiniCard,
      type: 'multimedia'
    },
    {
      icon: SlideshowIcon,
      form: Presentation,
      miniCard: PresentationMiniCard,
      type: 'presentation'
    },
    {
      icon: ImageOutlinedIcon,
      form: Image,
      miniCard: ImageMiniCard,
      type: 'image'
    },
    {
      icon: TheatersRoundedIcon,
      form: Video,
      miniCard: VideoMiniCard,
      type: 'video'
    },
    {
      icon: VideoCallRoundedIcon,
      form: VideoCall,
      miniCard: VideoCallMiniCard,
      type: 'videocall'
    },
    {
      icon: YouTubeIcon,
      form: Youtube,
      miniCard: YoutubeMiniCard,
      type: 'youtube'
    },
    // {
    //   icon: LiveTvRoundedIcon,
    //   form: LiveStream,
    //   miniCard: LiveStreamMiniCard,
    //   type: 'youtube_livestream'
    // },
    {
      icon: FormatListNumberedIcon,
      form: Exercise,
      miniCard: ExerciseMiniCard,
      type: 'exercise'
    },
    {
      icon: AssignmentRoundedIcon,
      form: Questionary,
      miniCard: QuestionaryMiniCard,
      type: 'form'
    },
    {
      icon: HeadsetIcon,
      form: Audio,
      miniCard: AudioMiniCard,
      type: 'audio'
    },
    { icon: FileCopyIcon, form: Clone, miniCard: CloneMiniCard, type: 'clone' }
  ];

  const styledTab = S.StyledTab();
  const [panel, setPanel] = useState(0);
  const { watch, setValue } = useFormContext();
  const edit = !!watch('id');
  const { control } = useFormContext();
  const [downloadable, setDownloadable] = useState(
    watch('lectureable.downloadable')
  );

  const handleSwitchChange = (event) => {
    setDownloadable(event.target.checked);
  };

  useEffect(() => {
    setValue('lectureable.downloadable', downloadable);
  }, [downloadable]);

  useEffect(() => {
    if (edit) return;
    switch (panel) {
      case 7:
      case 8:
        setValue('mural_enabled', false);
        break;
      default:
        setValue('mural_enabled', true);
        break;
    }
  }, [panel]);

  let lectureableTypes = [];
  if (edit) {
    const lectureableType = watch('lectureable.type');
    lectureableTypes = allLectureableTypes.filter(
      (item) => item.type === lectureableType
    );
  } else {
    lectureableTypes = allLectureableTypes;
  }

  const renderIcons = () => {
    return lectureableTypes.map((item, index) => (
      <Tab
        key={index}
        icon={<IconWrapper color={'gray'} fontSize={28} Icon={item.icon} />}
        classes={styledTab}
        {...a11yProps(index)}
      />
    ));
  };

  const renderNotAllowedFeedbackMessage = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#0056B4',
          maxWidth: '100%',
          borderRadius: 4,
          padding: 8,
          paddingLeft: 16,
          paddingRight: 16,
          marginBottom: 32
        }}
      >
        <InfoIcon
          style={{ color: 'white', marginRight: 8, fontSize: 'large' }}
        />
        <Text variant="lg" color="white">
          Somente é permitido realizar alterações até que o primeiro aluno tenha
          acessado.
        </Text>
      </div>
    );
  };

  const renderNotEditableMessage = (item) => {
    const MiniCard = item.miniCard;
    return (
      <>
        <MiniCard
          size="medium"
          minWidth="150px"
          maxWidth="200px"
          marginBottom="5px"
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#0056B4',
            maxWidth: '100%',
            borderRadius: 4,
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            marginBottom: 32
          }}
        >
          <InfoIcon
            style={{ color: 'white', marginRight: 8, fontSize: 'large' }}
          />
          <Text variant="lg" color="white">
            {`Não é possível editar o conteúdo de ${i18n.t(
              'StructuresName.Lectures.PluralLower'
            )} deste tipo`}
          </Text>
        </div>
        {item.type === 'presentation' && (
          <Box style={{ marginTop: '20px' }}>
            <Field
              label={TextPrimary('Permitir download da mídia')}
              labelPlacement={'end'}
              control={control}
              name="lectureable.presentation.downloadable"
              size="small"
              checked={downloadable}
              onChange={handleSwitchChange}
              Component={Switch}
            />
          </Box>
        )}
      </>
    );
  };

  const renderEditable = (item) => {
    const edit = !!watch('id');
    return (
      <>
        {edit ? (
          ['multimedia', 'exercise', 'form'].includes(item.type) ? (
            watch('can_edit_content') === false ? (
              renderNotAllowedFeedbackMessage()
            ) : (
              <Content MiniCard={item.miniCard} Form={item.form} />
            )
          ) : (
            renderNotEditableMessage(item)
          )
        ) : (
          <Content MiniCard={item.miniCard} Form={item.form} />
        )}
      </>
    );
  };

  const renderPanels = () => {
    return lectureableTypes.map((item, index) => (
      <TabPanel value={panel} index={index} key={index}>
        {' '}
        {renderEditable(item)}{' '}
      </TabPanel>
    ));
  };

  const handleChange = (event, newValue) => {
    setValue('lectureable.type', lectureableTypes[newValue].type);
    setPanel(newValue);
  };

  return (
    <div>
      <div style={{ marginBottom: '3px' }}>
        {lectureableTypes.length > 0
          ? TextPrimary(
              `Tipo de ${i18n.t('StructuresName.Lectures.SingularUpper')}`
            )
          : null}
      </div>
      <Tabs
        value={panel}
        variant="scrollable"
        scrollButtons="off"
        onChange={handleChange}
        style={{ marginBottom: '10px' }}
        aria-label="simple tabs example"
      >
        {renderIcons()}
      </Tabs>
      {renderPanels()}
      <S.WrapperCircularProgress>
        {videoUploadProgressBarShow && (
          <CircularProgressWithLabel value={progressVideoUpload} />
        )}
      </S.WrapperCircularProgress>
    </div>
  );
}

Lectureable.propTypes = {
  videoUploadProgressBarShow: PropTypes.bool,
  progressVideoUpload: PropTypes.number
};

Lectureable.defaultProps = {
  videoUploadProgressBarShow: false,
  progressVideoUpload: 0
};
