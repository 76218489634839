import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  titleAndForm: {
    width: '100%',
    padding: '15px',
    borderBottom: '1px solid rgba(112,112,112,0.2)',
    '@media (max-width: 800px)': {
      padding: '0px 50px 10px 50px'
    },
    '@media (max-width: 450px)': {
      padding: '0px 0px 10px 0px'
    }
  },
  title: {
    '@media (max-width: 800px)': {
      display: 'none'
    }
  },
  statusList: {
    width: '100%',
    height: 300,
    flex: 1,
    padding: '15px 34px 34px 26px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      height: '5px'
    },
    '&::-webkit-scrollbar-track': {
      background: 'none'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.main,
      borderRadius: '2px'
    },
    '@media (max-width: 1400px)': {
      padding: '15px 10px 10px 0px'
    }
  },
  shimmersContainer: {
    width: '100%',
    '@media (max-width: 1400px)': {
      paddingLeft: 20
    }
  },
  emptyMural: {
    paddingLeft: 8,
    '@media (max-width: 1400px)': {
      paddingLeft: 34
    },
    '@media (max-width: 800px)': {
      paddingLeft: 0
    }
  },
  List: {},

  getMoreButtom: {
    display: 'flex',
    position: 'relative',
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    zIndex: 1000,
    padding: '30px 0'
  },
  getMoreButtom__button: {
    cursor: 'pointer',
    transitionDuration: '0.5s',
    background: theme.palette.secondary.main,
    '&:hover': {
      background: theme.palette.tertiary.main
    },
    boxShadow: '100px 100px 121px -32px rgba(0, 0, 0, 0.12)',
    width: 180,
    height: 30,
    padding: '5px 10px',
    color: 'white',
    fontSize: 14,
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12,
    columnGap: 5
  },
  hasNewStatus: {
    display: 'flex',
    position: 'sticky',
    top: 0,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    zIndex: 1000
  },
  hasNewStatus__button: {
    cursor: 'pointer',
    transitionDuration: '0.5s',
    background: theme.palette.secondary.main,
    '&:hover': {
      background: theme.palette.tertiary.main
    },
    boxShadow: '100px 100px 121px -32px rgba(0, 0, 0, 0.12)',
    width: 'auto',
    padding: '5px 10px',
    color: 'white',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12,
    columnGap: 5
  },
  comment__view: {
    position: 'relative',
    height: 'auto',
    overflow: 'auto',
    padding:'10px 15px',
    zIndex: 0,
    width: '100%'
  },
  customIcon: {
    fontSize: 18
  },
  customIcon__rotate: {
    fontSize: 18,
    transform: 'rotate(180deg)'
  },
  muralItem: {
    borderBottom: '1px solid #CCCCCC4D',
    paddingBottom: 15,
  }
}));
