import React, { useState, useEffect } from 'react';

import Text from '~/components/atoms/Text';
import CreateReplica from '~/components/Modals/CreateReplica';
import PopUpMenuRefactored from '~/components/molecules/PopUpMenuRefactored';
import PopUpMenuItem from '~/components/molecules/PopUpMenuRefactored/PopUpMenuItem';
import history from '~/services/history';

import { Box, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';

export default React.forwardRef(function StructureCreationPopUp({
  text,
  structureName,
  redirectLink,
  structure,
  forwardedRef
}, ref) {
  const theme = useTheme();
  const [openReplicationModal, setOpenReplicationModal] = useState();

  const handleClick = (event) => {
    event.stopPropagation();
  };

  const anchorOrigin = {
    vertical: 'top',
    horizontal: 'right'
  };

  const transformOrigin = {
    vertical: 'top',
    horizontal: 'left'
  };

  const render = ({ onClick }) => (
    <Box
      display="flex"
      alignItems="center"
      style={{
        cursor: 'pointer',
        width: '100%'
      }}
      onClick={onClick}
    >
      <Text
        variant="text"
        color="primary"
        style={{ display: 'flex', alignItems: 'center', marginRight: '5px' }}
      >
        {text}
      </Text>
      <ArrowRightRoundedIcon
        style={{ fontSize: 25, color: theme.palette.primary.main }}
      />
    </Box>
  );

  return (
    <>
      <Box onClick={handleClick}>
        <PopUpMenuRefactored
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          forwardRef={forwardedRef}
          Component={render}
        >
          <PopUpMenuItem
            text={`Criar ${structureName}`}
            icon={AddIcon}
            action={() => history.push(redirectLink)}
          />
          {/* <PopUpMenuItem
            text={`Replicar ${structureName}`}
            icon={AddBoxRoundedIcon}
            action={() => setOpenReplicationModal(true)}
          /> */}
        </PopUpMenuRefactored>
      </Box>
      <CreateReplica
        open={openReplicationModal}
        setOpenReplicationModal={setOpenReplicationModal}
        structureName={structureName}
        structure={structure}
      />
    </>
  );
});
