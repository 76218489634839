/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { useHistory, useParams } from 'react-router-dom';

import DeleteConfirmModal from '~/components/molecules/DeleteConfirmModal';
import EditMirroredEnvironmentForm from '~/components/organisms/EditMirroredEnvironmentForm';
import EnvironmentReports from '~/components/organisms/EnvironmentReports';
import GenericFormWrapper from '~/components/organisms/GenericFormWrapper';
import MembersManagement from '~/components/organisms/MembersManagement';
import StepperForm from '~/components/templates/StepperForm';
import i18n from '~/I18n';
import environmentServices from '~/services/environment';
import userEnvironmentAssociationServices from '~/services/user-environment-association';
import { handleSuccess } from '~/utils/auxiliaryFunctions';

import {
  getMirroredEnvironment,
  updateMirroredEnvironment,
  deleteMirroredEnvironment
} from './fetch';
import { defaultAttrs, handleFormFields, setAttrs } from './setAttrs';
import { Grid, useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';

export default function EditMirroredEnvironment() {
  const theme = useTheme();

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [, setSubmissionStatus] = useState('');
  const [reportURLs, setReportURLs] = useState('');
  const { mirroredEnvironmentID } = useParams();
  const [defaultValues, setDefaultValues] = useState(defaultAttrs);
  const [parentEnvironmentID, setParentEnvironmentID] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const openDeleteModal = () => setShowDeleteModal(true);
  const closeDeleteModal = () => setShowDeleteModal(false);

  const navOptions = [
    i18n.t('Information'),
    i18n.t('Reports'),
    i18n.t('Members')
  ];
  const successMessage = `O ${i18n.t(
    'StructuresName.Environment.SingularLower'
  )} foi atualizado!`;
  const callbackSuccessPath = `/dashboard/environments`;

  const breadcrumbs = [
    { name: i18n.t('MyEnvironments'), path: '/dashboard/environments' }
  ];

  const handleExclude = () => {
    openDeleteModal();
  };

  const deleteMessage = () => {
    const message = (
      <>
        O {i18n.t('StructuresName.Environment.SingularLower')}{' '}
        <span style={{ fontWeight: 'bold' }}>{defaultValues.name}</span>, que é
        um espelho do {i18n.t('StructuresName.Environment.SingularLower')} "
        {defaultValues.name}", será permanentemente excluído, incluindo todos os
        dados e arquivos. Todos os membros irão perder o acesso. O{' '}
        {i18n.t('StructuresName.Environment.SingularLower')} original não será
        afetado.
      </>
    );
    return message;
  };

  function renderContent(props) {
    const { selectedTab } = props;
    if (selectedTab === 0) {
      return (
        <GenericFormWrapper
          Form={EditMirroredEnvironmentForm}
          defaultValues={defaultValues}
          handleExclude={handleExclude}
          excludeTitle="Excluir"
          {...props}
        />
      );
    } else if (selectedTab === 1) {
      return <EnvironmentReports id={parentEnvironmentID} />;
    } else if (selectedTab === 2) {
      return (
        <MembersManagement
          structureID={defaultValues.parent.id}
          structureServices={environmentServices}
          userStructureAssociationServices={userEnvironmentAssociationServices}
          reportURLs={reportURLs}
        />
      );
    }

    return false;
  }

  const handleDeleteConfirm = () => {
    deleteMirroredEnvironment(mirroredEnvironmentID)
      .then(() => {
        enqueueSnackbar(`Ambiente excluído com sucesso!`, {
          variant: 'success'
        });

        history.push(callbackSuccessPath);
      })
      .catch(() => {
        enqueueSnackbar(`Erro ao excluir ambiente.`, { variant: 'error' });
      })
      .finally(() => {
        closeDeleteModal();
      });
  };

  const configMirroredEnvironment = (data) => {
    setParentEnvironmentID(data.parent.id);
    setReportURLs(data.report_urls);
    setDefaultValues(setAttrs(data));
    setIsLoading(false);
  };

  const fetchFailure = () => {
    window.alert(
      `Não foi possível carregar o ${i18n.t(
        'StructuresName.Environment.SingularUpper'
      )}!`
    );
    handleCancel();
    setIsLoading(false);
  };

  useEffect(() => {
    if (mirroredEnvironmentID)
      getMirroredEnvironment(mirroredEnvironmentID)
        .then(configMirroredEnvironment)
        .catch(fetchFailure);
  }, [mirroredEnvironmentID]);

  function handleCancel() {
    history.push(`/dashboard/environments/${parentEnvironmentID}`);
  }

  const finalizeSuccess = () => {
    setSubmissionStatus('success');
    handleSuccess(
      successMessage,
      enqueueSnackbar,
      callbackSuccessPath,
      history
    );
  };

  const finalizeFailure = () => {
    alert(
      `Não foi possível atualizar o ${i18n.t(
        'StructuresName.Environment.SingularLower'
      )}!`
    );
    setSubmissionStatus('error');
  };

  function submitForm(fields) {
    delete fields.enrollment_link_attributes?.generate_link;
    delete fields.enrollment_link_attributes?.link;
    var mirroredEnvironment = handleFormFields(fields);
    const body = { mirrored_environment: { ...mirroredEnvironment } };
    updateMirroredEnvironment(mirroredEnvironmentID, body)
      .then(finalizeSuccess)
      .catch(finalizeFailure);
  }

  return isLoading ? (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: '100%' }}
    >
      <Grid item>
        <ReactLoading type="cylon" color={theme.palette.primary.main} />
      </Grid>
    </Grid>
  ) : (
    <>
      <DeleteConfirmModal
        show={showDeleteModal}
        handleClose={closeDeleteModal}
        handleConfirm={handleDeleteConfirm}
        maxWidth={440}
        loading={false}
        showButtons={true}
        descriptionMessage={deleteMessage()}
        title={i18n.t('DeleteMirroredEnvironment.title')}
      />
      <StepperForm
        unclickable={false}
        navOptions={navOptions}
        breadcrumbs={breadcrumbs}
        onSubmit={submitForm}
        formTitle={i18n.t('EditEnvironment')}
        submitButtonTitle={i18n.t('Save')}
        handleCancel={handleCancel}
        renderContent={renderContent}
      />
    </>
  );
}
