import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import styled from 'styled-components';


export const Container = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  heigth: auto;
  padding: 0 10px;
  margin-bottom: 30px;
  > span {
    margin-top: 1.5rem;
    font-size: 1.5rem;
    color: #fff;
    display: flex;
    justify-content: flex-end;
  }
`;

export const Title = styled.h1`
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const BadgesList = styled(GridList).attrs({
  cols: 4,
  cellHeight: 'auto',
})``;

export const BadgesItem = styled(GridListTile).attrs({
  cols: 1,
})`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 2px;
  box-sizing: border-box;
  border-radius: 100%;
  box-shadow: '0px 3px 6px #00000029';
  background: #ffffff33;
  width: 60px !important;
  height: 60px !important;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: auto;
    img {
      max-width: 100%;
    }
  }
`;
