import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import { getAll } from '~/services/environment';
import settings from '~settings';

import ButtonSquare from '../atoms/ButtonSquare';
import Shimmer from './Loading/shimmer';
import { Container, EnvironmentList } from './styles';
import { useTheme, Box } from '@material-ui/core';

export default function Environments() {
  const theme = useTheme();
  const history = useHistory();
  const targetRoute = '/dashboard/environments';

  const [isFetching, setIsFetching] = useState(false);
  const [environments, setEnvironments] = useState([]);
  useEffect(() => {
    setIsFetching(true);
    getAll(1, { ord: '', direction: '' })
      .then((fetchedEnvironments) => {
        setEnvironments(fetchedEnvironments);
        setIsFetching(false);
      })
      .catch(() => {
        setIsFetching(false);
      });
  }, []);

  return (
    <Container style={{ height: 300 }}>
      <Link to={targetRoute}>
        <Text variant="text" color="white">
          {i18n.t('MyEnvironments')}
        </Text>
      </Link>
      {isFetching && <Shimmer />}
      <EnvironmentList theme={theme}>
        {environments.map((environment) => (
          <Link to={`${targetRoute}/${environment.id}`} key={environment.id}>
            <div>
              <img
                src={
                  environment &&
                  environment.thumbnails &&
                  environment.thumbnails.length > 1
                    ? `${settings.baseUrl}${environment.thumbnails[1].href}`
                    : ``
                }
                alt={environment.name}
              />
            </div>
            <aside>
              <Text variant="text" color="white">
                {environment.name}
              </Text>
            </aside>
          </Link>
        ))}

        {!isFetching && !environments.length && (
          <Text variant="text" color="white">
            {i18n.t('NoSubscription')}
          </Text>
        )}
      </EnvironmentList>
      <Box display="flex" justifyContent="flex-end">
        <ButtonSquare
          noCapitalize
          padding="4px 0px"
          onClick={() => history.push(targetRoute)}
          style={{ display: 'flex', justifyContent: 'flex-end !important' }}
        >
          <Text variant="text" color="white">
            {i18n.t('ShowAll', { context: 'male' })}
          </Text>
        </ButtonSquare>
      </Box>
    </Container>
  );
}
