/* eslint-disable react/prop-types */
import React from 'react';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default function Selection({
  objects,
  handleOnChange,
  defaultValue,
  selectedValue
}) {
  const useStyles = makeStyles((theme) => ({
    select: {
      width: '150px',
      height: '35px',
      '& .MuiSelect-icon': {
        position: 'auto',
        right: '5px',
        top: '10px',
      },
    },
  }));

  const classes = useStyles();
  const primaryText = (text) => {
    return (
      <Typography color="primary" style={{ fontSize: '16px' }}>
        {text}
      </Typography>
    );
  };

  return (
    <FormControl variant="outlined">
      <InputLabel
        style={{ marginTop: '-14px' }}
        id="demo-simple-select-outlined-label"
      >
        {primaryText(defaultValue)}
      </InputLabel>
      {/* // Select component to render dropdown */}
      <Select
        labelId="demo-simple-select-outlined-label"
        className={classes.select}
        variant="outlined"
        // Attach the handleOnChange event to handle selection change
        onChange={(event) => handleOnChange(event.target.value)}
        value={selectedValue} // Set the selected value
        label={primaryText(defaultValue)}
      >
        {/* Default empty option */}
        <MenuItem value={''}>{primaryText(defaultValue)}</MenuItem>
        {/* Loop through objects and create options */}
        {objects.map((option, index) => (
          <MenuItem key={index} value={option}>
            {primaryText(option)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
