import api from '~/services/api';

export const getMirroredSubject = async (mirroredSubjectID) => {
  const { data } = await api.get(`/api/mirrored_subjects/${mirroredSubjectID}`);

  return data;
};

export const updateMirroredSubject = async (mirroredSubjectID, body) => {
  return await api.put(`/api/mirrored_subjects/${mirroredSubjectID}`, body);
};

export const deleteMirroredSubject = async (mirroredSubjectID) => {
  return await api.delete(`/api/mirrored_subjects/${mirroredSubjectID}`);
};
