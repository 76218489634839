import api from '~/services/api';

export const createMirroredCourse = async (environmentID, body) => {
  return await api.post(
    `/api/environments/${environmentID}/mirrored_courses`,
    body
  );
};

export const createClonedCourse = async (environmentID, originalID) => {
  return await api.post(`/api/cloning/courses/`, {
    original_id: originalID,
    environment_id: environmentID
  });
};

export const getMirrorOptions = async (params = {}, page = 1) => {
  const { environmentID } = params;
  let url = `/api/mirroring/courses/mirror_options?page=${page}`;
  if (environmentID) {
    url += `&environment_id=${environmentID}`;
  }
  const { data } = await api.get(url);
  if (data.length == 0) return null;
  return data;
};

export const getCloneableOptions = async (params = {}, page = 1) => {
  const { environmentID } = params;
  let url = `/api/cloning/courses/cloning_options?page=${page}`;
  if (environmentID) {
    url += `&environment_id=${environmentID}`;
  }
  const { data } = await api.get(url);
  if (data.length == 0) return null;
  return data;
};