import TableRow  from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { withStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles( theme => ({
  table: {
    minWidth: 300,
  },
  downloadButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  }
}));

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 18
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);