import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  buttonName: {
    margin: 0,
    padding: 0,
    color: 'white',
    fontSize: '13px'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});
