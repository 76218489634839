import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    player: {
      height: '28vw !important',
      '@media (max-width: 1400px)': {
        height: '30vw !important'
      },
      '@media (max-width: 1023px)': {
        height: '35vw !important'
      },
      '@media (max-width: 800px)': {
        height: '56vw !important'
      }
    },
    plyr: {
      position: 'relative !important',
      width: '100% !important',
      height: '28vw !important',
      '@media (max-width: 1400px)': {
        height: '30vw !important'
      },
      '@media (max-width: 1023px)': {
        height: '35vw !important'
      },
      '@media (max-width: 800px)': {
        height: '56vw !important'
      }
    },
    streamInfo: {
      marginTop: 20
    },
    link: {
      color: props => props.theme.palette.accent.main,
      fontSize: 12
    },
    liveText: {
      marginBottom: 5
    }
});
