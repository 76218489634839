import React, { useEffect, useState } from 'react';

import Text from '~/components/atoms/Text';

import UsageTerms from '~/pages/PrivacyPolicyAndUsageTerms/UsageTerms';
import PrivacyPolicy from '~/pages/PrivacyPolicyAndUsageTerms/PrivacyPolicy';
import PrivacyTermsButtons from './PrivacyTermsConfirmButton';
import { useStyles } from './styles';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  useTheme
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { RadioButtonUnchecked, CheckCircle } from '@material-ui/icons';
import { BsFileEarmarkTextFill } from 'react-icons/bs';

export default function PrivacyTermsModal({ profileID }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const isAccepted = false;

  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleModal = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (isAccepted === false) handleModal();
  }, [isAccepted]);

  return (
    <Box>
      <Modal
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '24px'
            }}
          >
            <Box className={classes.content}>
              <h2 className={classes.titleTermsAndPrivacy}>
                <BsFileEarmarkTextFill style={{ fontSize: '32px' }} />
                Termos de Uso e Política de Privacidade
              </h2>
              <Box className={classes.ScrollDiv}>
                <Text>
                  <UsageTerms isModal />
                  <PrivacyPolicy isModal />
                </Text>
              </Box>
              <FormControlLabel
                className={classes.acceptTermsLabel}
                control={
                  <Checkbox
                    onChange={handleChange}
                    icon={<RadioButtonUnchecked />}
                    checkedIcon={<CheckCircle />}
                    name="checked"
                  />
                }
                label={
                  <Typography style={{ fontSize: 15, color: 'gray' }}>
                    Eu li e concordo com os Termos de Uso e a Política de
                    Privacidade, bem como autorizo o uso da minha imagem para
                    fins educacionais e de comunicação dentro da plataforma.
                  </Typography>
                }
              />
              <PrivacyTermsButtons
                checked={checked}
                open={open}
                setOpen={setOpen}
                profileID={profileID}
              />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}
