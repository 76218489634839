import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Element } from 'react-scroll';

import Avatar from '~/components/atoms/Avatar';
import FriendshipConnection from '~/components/atoms/FriendshipConnection';
import Text from '~/components/atoms/Text';
import LeftDrawer from '~/components/Badges/Drawer/LeftDrawer';
import Account from '~/components/molecules/Account';
import AppBarCustom from '~/components/molecules/AppBar';
import ComponentAccessControlWrapper from '~/components/molecules/ComponentAccessControlWrapper';
import EditProfileLink from '~/components/molecules/EditProfileLink';
import HeaderSideBar from '~/components/molecules/HeaderSideBar';
import BioCurriculum from '~/components/organisms/BioCurriculum';
import SideNav from '~/components/templates/SideNav';
import i18n from '~/I18n';
import { hasManageAccessToUser } from '~/services/AccessControl/userAccessControl';
import api from '~/services/api';
import { Creators as UserActions } from '~/store/ducks/user';

import Certificates from './Certificates';
import Conquests from './Conquests';
import ContactsProfile from './Contacts';
import Environments from './Environments';
import Mural from './Mural';
import { LinkItem, useStyles } from './styles';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, ListItem, List, Grid, useTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

export default function Profile() {
  const {
    root,
    content,
    rightContent,
    menuItem,
    menu,
    contentContainer,
    sidenavContainer,
    avatarContainer,
    buttonSendMessage,
    textSendMessage,
    MessageFollowButtons
  } = useStyles();
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const loggedInProfile = useSelector((state) => state.user.profile);
  const [profile, setProfile] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(false);
  const dispatch = useDispatch();
  const { getProfileRequest } = UserActions;
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const [profilePageContact, setProfilePageContact] = useState(false);

  useEffect(() => {
    dispatch(getProfileRequest());
  }, []);

  function goToContactsSection() {
    if (
      location &&
      location.state &&
      location.state.targetSection &&
      location.state.targetSection === 'contacts'
    ) {
      const contactLink = document.querySelectorAll('#profile_contacts');
      contactLink[0].click();
    }
  }
  function goToAchievementsSection() {
    if (
      location &&
      location.state &&
      location.state.targetSection &&
      location.state.targetSection === 'conquests'
    ) {
      const conquestLink = document.querySelectorAll('#profile_conquests');
      conquestLink[0].click();
    }
  }

  async function getUserProfile() {
    if (profile === null) {
      api
        .get(`/api/users/${id}`)
        .then((res) => {
          setProfile(res.data);
        })
        .catch((error) => {
          if (error.response.status === 401 || error.response.status === 404) {
            history.push('/page-not-found');
          }
        });
    }
  }

  function toggleDrawer() {
    setOpen(!open);
  }

  useEffect(() => {
    setLoggedInUser(loggedInProfile?.id == id);
    setOpen(false);
    if (profile === null) {
      if (loggedInProfile?.id == id) {
        setProfile(loggedInProfile);
      } else {
        getUserProfile();
      }
    }
  }, []);

  useEffect(() => {
    if (profile) {
      document.title = `${theme.title} | ${profile?.display_name}`;
    } else {
      document.title = theme.title;
    }
  }, [profile]);

  const showAcountSettings =
    profile?.id == loggedInProfile?.id || loggedInProfile?.admin;

  return (
    <ThemeProvider theme={theme}>
      <Box className={root}>
        <AppBarCustom profile={profile} setOpen={toggleDrawer} />
        <LeftDrawer open={open} setOpen={toggleDrawer} />
        <Box className={content}>
          <Box className={sidenavContainer}>
            <SideNav color="white">
              <HeaderSideBar
                headerprofile
                userId={id}
                profile={profile ? profile : null}
              />
              <EditProfileLink profile userId={id} />
              {!loggedInUser && (
                <Box>
                  <Box
                    className={buttonSendMessage}
                    onClick={() => {
                      setProfilePageContact(!profilePageContact);
                      history.push(`/messages/${id}`);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCommentDots}
                      color="#fff"
                      style={{ fontSize: 16 }}
                    />
                    <h1 className={textSendMessage}>{i18n.t('Message')}</h1>
                  </Box>
                  <FriendshipConnection currentProfile={profile} />
                </Box>
              )}
              <List className={menu}>
                {showAcountSettings && (
                  <ListItem className={menuItem}>
                    <LinkItem
                      containerId="scroll-container"
                      to="accountsettings"
                    >
                      Configurações da Conta
                    </LinkItem>
                  </ListItem>
                )}
                <ListItem className={menuItem}>
                  <LinkItem containerId="scroll-container" to="bioandcurriculo">
                    {i18n.t('BioCurriculum')}
                  </LinkItem>
                </ListItem>
                <ListItem className={menuItem}>
                  <LinkItem
                    containerId="scroll-container"
                    to="conquests"
                    id="profile_conquests"
                  >
                    {i18n.t('Achievements')}
                  </LinkItem>
                </ListItem>
                <ListItem className={menuItem}>
                  <LinkItem
                    containerId="scroll-container"
                    to="contacts"
                    id="profile_contacts"
                  >
                    {i18n.t('Contacts')}
                  </LinkItem>
                </ListItem>
                <ListItem className={menuItem}>
                  <LinkItem
                    containerId="scroll-container"
                    to="environmentsandcourses"
                  >
                    {i18n.t('Environments')}
                  </LinkItem>
                </ListItem>
                <ListItem className={menuItem}>
                  <LinkItem containerId="scroll-container" to="certificates">
                    {i18n.t('Certificates')}
                  </LinkItem>
                </ListItem>
                <ListItem className={menuItem}>
                  <LinkItem containerId="scroll-container" to="mural">
                    {i18n.t('Mural')}
                  </LinkItem>
                </ListItem>
              </List>
            </SideNav>
          </Box>
          <Box className={rightContent} id="scroll-container">
            <Grid lg={6} md={9} sm={12} className={contentContainer}>
              <Element>
                {profile && (
                  <Box className={avatarContainer}>
                    <Avatar profile={profile} size={100} disabled />
                    <Text variant="lg" color="primary">
                      {`${profile.first_name} ${profile.last_name}`}
                    </Text>
                    <Text variant="username" color="gray">
                      {`@${profile.login}`}
                    </Text>
                    <EditProfileLink profile userId={id} />
                  </Box>
                )}
              </Element>
              {!loggedInUser && loggedInProfile?.id !== profile?.id && (
                <Box className={MessageFollowButtons}>
                  <Box
                    className={buttonSendMessage}
                    onClick={() => {
                      setProfilePageContact(!profilePageContact);
                      history.push(`/messages/${id}`);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCommentDots}
                      color="#fff"
                      style={{ fontSize: 16 }}
                    />
                    <h1 className={textSendMessage}>{i18n.t('Message')}</h1>
                  </Box>
                  <FriendshipConnection />
                </Box>
              )}
              {(profile?.id == loggedInProfile?.id ||
                loggedInProfile?.admin) && (
                <Element name="accountsettings">
                  <Account loggedInUser={profile} />
                </Element>
              )}
              <Element name="bioandcurriculo">
                <BioCurriculum profile={profile} />
              </Element>
              <Element name="conquests">
                <Conquests
                  profile={profile || null}
                  goToAchievimentsSection={goToAchievementsSection}
                />
              </Element>
              <Element name="contacts">
                <ContactsProfile
                  otherUsers={loggedInUser}
                  userId={id}
                  loggedInUserId={loggedInProfile?.id}
                  goToContactsSection={goToContactsSection}
                />
              </Element>
              <Element name="environmentsandcourses">
                <Environments userId={id} loggedInId={loggedInProfile?.id} />
              </Element>
              <Element name="certificates">
                <ComponentAccessControlWrapper
                  accessFunction={hasManageAccessToUser}
                  Component={Certificates}
                  modelID={profile?.id}
                  profile={profile || null}
                />
              </Element>
              <Element name="mural">
                <Mural height={window.innerHeight} userId={id} />
              </Element>
            </Grid>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
