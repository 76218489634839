import React from 'react';

import { getUsersRolesReport } from '~/services/reports/environments';

import DownloadReportButton from '../DownloadReportButton';
import { useStyles } from './styles';
import PropTypes from 'prop-types';

export default function UsersRolesReport({ id }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DownloadReportButton
        getReport={getUsersRolesReport}
        baseName="lista_de_usuários_e_papeis_nos_cursos_e_disciplinas"
        id={id}
      />
    </div>
  );
}

UsersRolesReport.propTypes = {
  id: PropTypes.string
};

UsersRolesReport.defaultProps = {
  id: ''
};
