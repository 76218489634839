import React from 'react';
import { MdLogout } from 'react-icons/md';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';

import {
  Toolbar,
  Drawer,
  IconButton,
  Box,
  TextField,
  InputAdornment,
  Button,
  Typography,
  List
} from '@material-ui/core';
import { useTheme, makeStyles, withStyles } from '@material-ui/core/styles';
import { ExitToApp, Close, Search } from '@material-ui/icons';
import styled from 'styled-components';

const drawerWidth = 450;

export const useStyles = makeStyles((theme) => ({
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main
  },
  secondDrawer: {
    width: drawerWidth * 2,
    flexShrink: 0,
    zIndex: 150
  },
  secondDrawerPaper: {
    width: drawerWidth * 2,
    flexDirection: 'row',
    backgroundColor: 'white'
  },
  link: {
    marginTop: 50,
    marginBottom: 10,
    color: 'white',
    fontSize: 16,
    textTransform: 'capitalize',
    padding: '8px 16px 8px 5px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  english: {
    marginLeft: '7px  '
  },
  icon: {
    color: 'white',
    fontSize: '24px',
    marginRight: '8px'
  }
}));

export const LeftToolbar = styled(({ color, ...otherProps }) => (
  <Toolbar {...otherProps} />
))`
  display: flex;
  direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ContrastIcon = styled(({ color, ...otherProps }) => (
  <IconButton color="inherit" edge="start" {...otherProps}>
    <img
      src={require('src/assets/icons/contrastIcon.png')}
      alt="contrast"
      style={{ height: 22, width: 22 }}
    />
  </IconButton>
))``;

export const ExitIcon = styled(({ color, ...otherProps }) => (
  <IconButton
    color="inherit"
    edge="start"
    style={{ color: 'white', marginLeft: 3 }}
    {...otherProps}
  >
    <ExitToApp style={{ fontSize: 24 }} />
  </IconButton>
))``;

export const FixedDrawer = styled(({ color, ...otherProps }) => {
  const theme = useTheme();
  return (
    <Box
      {...otherProps}
      color="primary"
      style={{
        backgroundColor: color || theme.palette.primary.main,
        width: drawerWidth
      }}
    />
  );
})`
  height: 100vh;
  overflow-y: scroll;
`;

export const CssTextField = withStyles({
  root: {
    '& > *': {
      color: 'white',
      fontSize: '1.8rem',
      textOverflow: 'ellipsis',
      paddingBottom: '1rem',
      paddingLeft: '1rem'
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'white'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white'
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: 'white'
    }
  }
})(TextField);

export const InputSearch = styled(({ ...otherProps }) => (
  <CssTextField
    {...otherProps}
    style={{ marginTop: 32, height: 32, marginBottom: 32 }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <Search style={{ fontSize: 24, fontWeight: 'bold' }} />
        </InputAdornment>
      )
    }}
  />
))``;

export const SearchDrawer = styled(({ color, ...otherProps }) => {
  const classes = useStyles();
  return (
    <Drawer
      {...otherProps}
      className={classes.secondDrawer}
      classes={{ paper: classes.secondDrawerPaper }}
    />
  );
})``;

export const SearchDrawerMenu = styled(({ color, ...otherProps }) => (
  <Box
    {...otherProps}
    component="div"
    display="flex"
    flexDirection="column"
    style={{
      width: '50%',
      right: '100%',
      backgroundColor: color,
      paddingLeft: 35,
      paddingRight: 10,
      paddingBottom: 10,
      height: '100%',
      overflowY: 'scroll'
    }}
  />
))``;

export const ExitButton = styled(({ ...otherProps }) => (
  <Button
    {...otherProps}
    style={{
      marginTop: 'auto',
      color: 'white',
      fontSize: 16,
      textTransform: 'capitalize',
      minWidth: 40,
      width: i18n.language === 'en-US' ? 65 : 40,
      padding: 0
    }}
  >
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      <MdLogout size={24} style={{ marginRight: '8px' }} />
      {i18n.t('Quit')}
    </Box>
  </Button>
))``;

export const TitleHeaderContainerLeftDrawer = styled(
  ({ title, ...otherProps }) => {
    const theme = useTheme();
    return (
      <Typography
        {...otherProps}
        style={{
          fontSize: 16,
          color: theme.palette.primary.main
        }}
      >
        {title}
      </Typography>
    );
  }
)``;

export const ButtonClearList = styled(({ onClick }) => {
  return (
    <Text
      variant="text"
      color="primary"
      additionalStyle="underlineCursorPoinerHover"
      onClick={() => onClick()}
    >
      {i18n.t('Clean')}
    </Text>
  );
})``;

export const ContainerHeaderLeftDrawer = styled(({ ...otherProps }) => (
  <Box
    {...otherProps}
    component="div"
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    style={{ marginTop: 15, marginBottom: 15 }}
  />
))``;

export const MenuItem = styled(
  ({ color, onClick, icon, rightIcon, ...otherProps }) => {
    const { title } = otherProps;
    return (
      <Button
        onClick={onClick}
        style={{
          paddingLeft: 5,
          marginBottom: 10,
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {icon}
          <Text
            variant="sideBarTitle"
            color="white"
            style={{ textTransform: 'none' }}
          >
            {title}
          </Text>
          {rightIcon}
        </div>
      </Button>
    );
  }
)``;

export const SearchList = styled(List)`
  padding-top: 35px !important;
`;

export const CloseButton = styled(({ ...otherProps }) => (
  <IconButton
    {...otherProps}
    style={{
      width: 44,
      height: 44
      // position: 'absolute',
      // top: 0,
      // left: 0
    }}
  >
    <Close style={{ fontSize: 24, color: 'white' }} />
  </IconButton>
))``;

export const NothingFoundText = styled(() => {
  return (
    <Text variant="sideBarTitle" color="primary">
      {i18n.t('SearchNotFound')}
    </Text>
  );
})``;
