import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactLoading from 'react-loading';

import Text from '~/components/atoms/Text';
import PaginatableSelect from '~/components/molecules/PaginatableSelect';
import i18n from '~/I18n';
import {
  getMirrorOptions,
  getCloneableOptions
} from '~/services/replications/subjects';

import { useStyles } from './styles';
import { Collapse, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export default function ReplicatedSubjectForm({ isMirroring }) {
  const theme = useTheme();
  const classes = useStyles();

  const {
    setValue,
    formState: {
      errors: { subjectID }
    },
    watch
  } = useFormContext();

  const [isLoading, setIsLoading] = useState(false);
  const [environments, setEnvironments] = useState(null);
  const [courses, setCourses] = useState(null);
  const [spaces, setSpaces] = useState(null);
  const [subjects, setSubjects] = useState(null);

  const [selectedEnvironment, setSelectedEnvironment] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedSpace, setSelectedSpace] = useState('');
  const [selectedSubject, setSelectedSubject] = useState(null);
  const fetchStructures = isMirroring ? getMirrorOptions : getCloneableOptions;

  const handleSuccess = (values, setValues) => {
    setValues(values);
    setIsLoading(false);
  };

  const handleFailure = () => {
    setIsLoading(false);
  };

  const handleEnvironments = (event) => {
    setIsLoading(true);
    setValue('subjectID', '');
    setCourses(null);
    setSpaces(null);

    const { target } = event;
    fetchStructures({ environmentID: target.value })
      .then((courses) => {
        setSelectedEnvironment(target.value);
        handleSuccess(courses, setCourses);
      })
      .catch(handleFailure);
  };

  const handleCourses = (event) => {
    setIsLoading(true);
    setValue('subjectID', '');
    setSpaces(null);
    setSubjects(null);

    const { target } = event;
    fetchStructures({ courseID: target.value })
      .then((spaces) => {
        setSelectedCourse(target.value);
        handleSuccess(spaces, setSpaces);
      })
      .catch(handleFailure);
  };

  const handleSpaces = (event) => {
    setIsLoading(true);
    setValue('subjectID', '');
    setSubjects(null);

    const { target } = event;
    fetchStructures({ spaceID: target.value })
      .then((subjects) => {
        setSelectedSpace(target.value);
        handleSuccess(subjects, setSubjects);
      })
      .catch(handleFailure);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchStructures({})
      .then((environments) => handleSuccess(environments, setEnvironments))
      .catch(handleFailure);
  }, []);

  useEffect(() => {
    if (watch('subjectID'))
      setSelectedSubject(subjects.find((x) => x.id == watch('subjectID')));
    else setSelectedSubject(null);
  }, [watch('subjectID')]);

  return (
    <div className={classes.content}>
      {isLoading && !environments ? (
        <Grid container justifyContent="center">
          <ReactLoading type="bars" color={`${theme.palette.primary.main}`} />
        </Grid>
      ) : (
        <>
          {subjectID ? (
            <Text color="error">
              Você ainda não selecionou um{' '}
              {`${i18n.t('StructuresName.Subjects.SingularUpper')}`}.
            </Text>
          ) : null}

          {environments ? (
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <PaginatableSelect
                  value={selectedEnvironment}
                  onChange={handleEnvironments}
                  label={`Selecione um ${i18n.t(
                    'StructuresName.Environment.SingularUpper'
                  )}`}
                  paginatableFunction={fetchStructures}
                  params={{}}
                  objectList={environments}
                  setObjectList={setEnvironments}
                  labelAttribute={'name'}
                  valueAttribute={'id'}
                />
              </Grid>

              <Grid item xs={12}>
                <Collapse in={isLoading && environments && !courses}>
                  <ReactLoading
                    width={30}
                    height={30}
                    type="bars"
                    color={`${theme.palette.primary.main}`}
                  />
                </Collapse>

                <Collapse in={courses}>
                  <PaginatableSelect
                    value={selectedCourse}
                    onChange={handleCourses}
                    label={`Selecione um ${i18n.t(
                      'StructuresName.Course.SingularUpper'
                    )}`}
                    paginatableFunction={fetchStructures}
                    params={{ environmentID: selectedEnvironment }}
                    objectList={courses}
                    setObjectList={setCourses}
                    labelAttribute={'name'}
                    valueAttribute={'id'}
                  />
                </Collapse>
              </Grid>

              <Grid item xs={12}>
                <Collapse in={isLoading && courses && !spaces}>
                  <ReactLoading
                    width={30}
                    height={30}
                    type="bars"
                    color={`${theme.palette.primary.main}`}
                  />
                </Collapse>

                <Collapse in={courses && spaces}>
                  <PaginatableSelect
                    value={selectedSpace}
                    onChange={handleSpaces}
                    label={`Selecione um ${i18n.t(
                      'StructuresName.Space.SingularUpper'
                    )}`}
                    paginatableFunction={fetchStructures}
                    params={{ courseID: selectedCourse }}
                    objectList={spaces}
                    setObjectList={setSpaces}
                    labelAttribute={'name'}
                    valueAttribute={'id'}
                  />
                </Collapse>
              </Grid>

              <Grid item xs={12}>
                <Collapse in={isLoading && spaces && !subjects}>
                  <ReactLoading
                    width={30}
                    height={30}
                    type="bars"
                    color={`${theme.palette.primary.main}`}
                  />
                </Collapse>

                <Collapse in={spaces && subjects}>
                  <PaginatableSelect
                    value={watch('subjectID')}
                    onChange={(e) => setValue('subjectID', e.target.value)}
                    label={`Selecione um ${i18n.t(
                      'StructuresName.Subjects.SingularUpper'
                    )}`}
                    paginatableFunction={fetchStructures}
                    params={{ spaceID: selectedSpace }}
                    objectList={subjects}
                    setObjectList={setSubjects}
                    labelAttribute={'name'}
                    valueAttribute={'id'}
                  />
                </Collapse>
              </Grid>

              <Grid item xs={12}>
                <Collapse in={watch('subjectID')}>
                  <Text
                    color="black"
                    variant={'actionText'}
                  >{`${selectedSubject?.space?.course?.environment.name} >
                                                                  ${selectedSubject?.space?.course.name} >
                                                                  ${selectedSubject?.name}`}</Text>

                  <Text
                    color="primary"
                    // variant={'title'}
                    style={{ marginTop: '5px', fontSize: '1rem' }}
                  >
                    <span style={{ fontWeight: 'bold' }}>
                      {selectedSubject?.name}
                    </span>{' '}
                    selecionado
                  </Text>
                </Collapse>
              </Grid>
            </Grid>
          ) : (
            <Text color="primary">
              Infelizmente não encontramos nenhum{' '}
              {`${i18n.t('StructuresName.Subjects.SingularUpper')}`} em que você
              seja o proprietário.
            </Text>
          )}
        </>
      )}
    </div>
  );
}
