import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  directionButton: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 5,
    '@media (max-width: 767px)': {
      width: '100%',
      justifyContent: 'center'
    }
  },
  downloadButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    boxSizing: 'border-box',
    background: 'white',
    color: theme.palette.secondary.main,
    fontSize: 13,
    cursor: 'pointer',
    fontWeight: 'bold',
    width: 'auto',
    height: 32,

    border: `0.5px solid ${theme.palette.secondary.main}`,
    borderRadius: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      background: theme ? `#f5f5f5` : 'blue'
    },
    padding: '10px',
    paddingLeft: 5,

    '& p': {
      width: '100%',
      textAlign: 'center'
    }
  },
  rightButton: {
    paddingRight: 5,
    paddingLeft: 10,

    '& p': {
      width: '100%',
      textAlign: 'center'
    }
  },
  icon: {
    fontSize: 20
  }
}));
