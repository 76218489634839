/* eslint-disable react/prop-types */
import React from 'react';
import { useFormContext } from 'react-hook-form';

import InputMasked from '~/components/atoms/InputMasked';
import InputMaskMedia from '~/components/atoms/InputMasked/InputMaskMedia';
import InputMaskNumbers from '~/components/atoms/InputMasked/InputMaskNumbers';
import Input from '~/components/atoms/TextInput';
import i18n from '~/I18n';
import { TextPrimary } from '~/utils/forms';

import Field from '../../GenericFormWrapper/Field';
import Degrees from './Degrees';
import Keywords from './Keywords';
import PerfilImage from './PerfilImage';
import ThumbImage from './ThumbImage';
import { useStyles } from './styles';
import { Grid, useTheme } from '@material-ui/core';

export default function MainInfo() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { setValue } = useFormContext();

  const parametrizeString = (inputString) => {
    // Remove special characters, replace accents, and remove spaces
    const normalizedString = inputString
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Remove accents
      .replace(/[^a-zA-Z0-9]+/g, '-') // Replace other non-alphanumeric characters and spaces with "-"
      .replace(/-+/g, '-') // Remove multiple consecutive "-"
      .replace(/^-|-$/g, ''); // Remove "-" at the beginning and end

    // Replace 'ç' with 'c'
    const parametrizedString = normalizedString.replace(/ç/g, 'c');

    return parametrizedString.toLowerCase(); // Convert to lowercase and return the final string
  };

  function handlePathAutocomplete(event) {
    setValue('name', event.target.value);
    setValue('path', parametrizeString(event.target.value));
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} className={classes.field}>
          <div>
            <Field
              label={TextPrimary('Nome *')}
              name="name"
              Component={Input}
              onChange={handlePathAutocomplete}
              placeholder={`Insira o nome do ${i18n.t(
                'StructuresName.Course.SingularLower'
              )}`}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={4} className={classes.field}>
          <div>
            <Field
              label={TextPrimary('Abreviação')}
              name="initials"
              Component={Input}
              placeholder={`Insira uma sigla para o ${i18n.t(
                'StructuresName.Course.SingularLower'
              )}`}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={3} className={classes.field}>
          <div>
            <Field
              label={TextPrimary('Carga horária *')}
              name="workload"
              Component={(props) => (
                <InputMasked Mask={InputMaskNumbers} {...props} />
              )}
              variant={null}
              placeholder="Ex: 50"
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={3} className={classes.field}>
          <div>
            <Field
              label={TextPrimary('Média de Avaliação')}
              name="average"
              Component={(props) => (
                <InputMasked Mask={InputMaskMedia} {...props} />
              )}
              variant={null}
              placeholder="Ex: 9.5"
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.field}>
          <div>
            <Field
              label={TextPrimary('Endereço *')}
              name="path"
              Component={Input}
              placeholder="Insira um endereço web"
            />
          </div>
        </Grid>
      </Grid>

      <div className={classes.field}>
        <Field
          label={TextPrimary(
            `Programa do ${i18n.t('StructuresName.Course.SingularUpper')} *`
          )}
          name="description"
          Component={Input}
          placeholder={`Insira um resumo do ${i18n.t(
            'StructuresName.Course.SingularLower'
          )}`}
          multiline
        />
      </div>

      <div className={classes.field}>
        <PerfilImage />
      </div>

      <div className={classes.field}>
        <ThumbImage />
      </div>

      <div className={classes.field}>
        <Keywords />
      </div>

      <div className={classes.field}>
        <Degrees />
      </div>
    </div>
  );
}
